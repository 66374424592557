import { Unstable_Grid2 as Grid } from '@mui/material';
import React from 'react';
import { DealSector } from '../../enums/deal-sector.enum';
import { DealStrategy } from '../../enums/deal-strategy.enum';
import { DealType } from '../../enums/deal-type.enum';
import { KeyFieldStack } from '../../util/key-field-stack';
import { oxfordComma } from '../../../helpers/strings';

export const DealPublishedKeyFields = ({ values }) => {
  const isFund = values?.type === DealType.Fund;
  const isSale = values?.type === DealType.Sale;
  const latlng = values.latlng ? JSON.parse(values.latlng) : null;

  return isFund ? (
    <Grid xs={6} sx={{ pr: 8 }}>
      <KeyFieldStack
        label={`Sector${values.sectors?.length > 1 ? 's' : ''}`}
        value={values.sectors?.length ? values.sectors : null}
        valueDisplay={
          values.sectors
            ? oxfordComma(values.sectors.map((s) => DealSector[s]))
            : ''
        }
      />
    </Grid>
  ) : (
    <>
      <Grid xs={6} sx={{ pr: 8 }}>
        {!!values.acreage && (
          <KeyFieldStack
            label={'Acreage'}
            value={values.acreage}
            valueDisplay={values.acreage?.toLocaleString()}
          />
        )}
        {!!values.sqFt && (
          <KeyFieldStack
            label={isSale ? 'Sq Ft of Structures' : 'Square Footage'}
            value={values.sqFt}
            valueDisplay={values.sqFt?.toLocaleString()}
          />
        )}
        {!!values.units && (
          <KeyFieldStack
            label={isSale ? 'Number of Structures' : 'Number of Units'}
            value={values.units}
            valueDisplay={values.units?.toLocaleString()}
          />
        )}
        {!isSale && (
          <KeyFieldStack
            label={`Est. Hold Period`}
            value={values.holdTime}
            valueDisplay={`${values.holdTime} years`}
          />
        )}
      </Grid>
      <Grid xs={6}>
        <KeyFieldStack
          label={`Strategy`}
          value={values.strategy}
          valueDisplay={DealStrategy[values.strategy]}
        />
        <KeyFieldStack
          label={
            isSale ? `Zoning` : `Sector${values.sectors?.length > 1 ? 's' : ''}`
          }
          value={values.sectors?.length ? values.sectors : null}
          valueDisplay={
            values.sectors
              ? oxfordComma(values.sectors.map((s) => DealSector[s]))
              : ''
          }
        />
        {values.diligenceBy && (
          <KeyFieldStack
            label={`Diligence By`}
            value={values.diligenceBy}
            valueDisplay={values.diligenceBy}
          />
        )}
      </Grid>
    </>
  );
};
