import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DealStatus } from '../enums/deal-status.enum';
import { localDate } from '../../helpers/dates';
import { DialogActionBar } from './dialog-action-bar';

const DealEditFundingDialog = ({
  open,
  onClose,
  onSubmit,
  deal,
  funding,
  isLoading,
}) => {
  const isNew = !funding?.id;
  const now = new Date();

  const initialValues = {
    id: funding?.id,
    amount: isNew ? deal?.minInvestment : funding?.amount,
    closedAt: now,
    submit: null,
  };

  const isApproved = deal.status === DealStatus.Approved;

  const totalFunding =
    deal?.funding?.reduce((prev, curr) => (prev += curr.amount), 0) || 0;

  const fundingAvailable = deal?.totalRaise ? deal?.totalRaise : 0;

  const validationShape = {
    amount: Yup.number()
      .required('Amount is required')
      .positive('Amount is not valid')
      .integer('Amount is not valid')
      .min(
        deal?.minInvestment || 1,
        'Amount must be no less than $' +
          (deal?.minInvestment?.toLocaleString() || 1) +
          ' USD',
      )
      .max(
        fundingAvailable,
        'Amount must be at most $' +
          (fundingAvailable.toLocaleString() || 1) +
          ' USD',
      ),
    closedAt: Yup.date().required('Closing date is required'),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        onSubmit(values);
      } catch (err) {
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ [errObj.field]: errObj.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  useEffect(() => {
    if (open) {
      formik.setValues({
        id: funding?.id,
        amount: isNew ? deal?.minInvestment : funding?.amount,
        closedAt: isNew ? now : localDate(funding?.closedAt),
      });
    }
  }, [open]);

  const handleDateChange = (newDate) => {
    formik.setFieldValue('closedAt', newDate, true);
  };

  if (!funding) {
    return <></>;
  }

  const prevTotalFunding = funding.id ? totalFunding : 0;
  const pctFunded = formik.values.amount / deal.totalRaise;
  const prevPctFunded = prevTotalFunding / deal.totalRaise;

  let threshold = null;
  if (prevPctFunded < 1 && pctFunded >= 1) {
    threshold = '100%';
  } else if (prevPctFunded < 0.75 && pctFunded >= 0.75) {
    threshold = '75%';
  } else if (prevPctFunded < 0.5 && pctFunded >= 0.5) {
    threshold = '50%';
  } else if (prevPctFunded < 0.25 && pctFunded >= 0.25) {
    threshold = '25%';
  }

  const buttonText =
    isApproved && threshold ? 'Save & Send Notifications' : `Save`;

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>Update Deal Funding</DialogTitle>
      <DialogContent sx={{ height: 345 }}>
        <Stack spacing={2}>
          <Typography variant={'h5'}>{deal?.name}</Typography>
          <Typography variant={'body1'}>
            Enter the total amount of funding closed as of the selected date.
          </Typography>
          <DatePicker
            onChange={handleDateChange}
            value={formik.values.closedAt}
            maxDate={now}
            label="As of"
            renderInput={(props) => (
              <TextField
                {...props}
                error={!!(formik.touched.closedAt && formik.errors.closedAt)}
                helperText={formik.errors.closedAt}
                label="As of"
                name="closedAt"
                onBlur={formik.handleBlur}
                fullWidth
              />
            )}
          />
          <TextField
            error={!!formik.errors.amount}
            helperText={
              formik.errors.amount
                ? formik.errors.amount
                : deal.totalRaise
                  ? `Out of $${deal.totalRaise.toLocaleString()} available`
                  : `Total raise amount not set`
            }
            label="Amount (USD)"
            name="amount"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.amount || ''}
            type="number"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <Stack sx={{ pt: 1 }}>
            <Typography
              variant={'body1'}
              align={'center'}
              color={threshold ? 'primary' : ''}
            >
              <b>This deal is {Math.round(pctFunded * 100)}% funded</b>
            </Typography>
            {!isApproved || pctFunded > 1 ? (
              <></>
            ) : threshold ? (
              <Typography variant={'body2'} align={'center'}>
                The {threshold} funding threshold has been crossed and
                notifications will be sent to investors watching this deal
              </Typography>
            ) : (
              <Typography variant={'body2'} align={'center'}>
                No notifications will be sent to investors
              </Typography>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActionBar
        sx={{ mt: 2 }}
        isLoading={isLoading}
        loadingLabel={isNew ? 'Adding...' : 'Updating...'}
        submitLabel={buttonText}
        disabled={formik.isSubmitting || !formik.isValid}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default DealEditFundingDialog;
