import {
  Alert,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSessionUser } from '../hooks/session/useSessionUser';
import { phoneRegExp } from '../../helpers/is-valid-phone';
import { NotificationChannel } from '../enums/notification-channel.enum';
import { QualificationForm } from '../users/qualification-form';
import {
  InvestorCategories,
  InvestorCategory,
  QualificationChoices,
} from '../../helpers/investor-categories';

export const RegisterInvestorForm = (props) => {
  const { onSubmit } = props;
  const { sessionUser } = useSessionUser();
  const initialName =
    sessionUser?.name && sessionUser?.name.indexOf('@') === -1
      ? sessionUser?.name
      : '';

  const initialValues = {
    name: initialName,
    phone: '',
    linkedIn: '',
    category: InvestorCategory.Individual.id,
    terms: false,
    submit: null,
  };

  const validationShape = {
    name: Yup.string().max(255).required('Name is required'),
    terms: Yup.boolean().oneOf([true], 'You must agree to the terms'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Your phone number is not valid')
      .required('Your phone number is required')
      .max(15),
    linkedIn: Yup.string().url('LinkedIn Profile must be a valid URL').max(255),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        onSubmit(values, [
          NotificationChannel.DealApproval,
          NotificationChannel.DealFundingAdded,
        ]);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const isTouched = Object.keys(formik.touched).length > 0;
  const isQualificationTouched =
    formik.values.isAccreditedInvestor !== undefined ||
    formik.values.isQualifiedClient !== undefined ||
    formik.values.isQualifiedPurchaser !== undefined ||
    formik.values.isRIA !== undefined ||
    formik.values.isBrokerDealer !== undefined;

  const isQualified =
    formik.values.isAccreditedInvestor ||
    formik.values.isQualifiedClient ||
    formik.values.isQualifiedPurchaser ||
    formik.values.isRIA ||
    formik.values.isBrokerDealer;

  const isNotQualified = formik.values.isNotQualified;

  const qualfiicationChoices =
    QualificationChoices(formik.values.category) || [];

  const handleCheckboxChange = (e) => {
    formik.setFieldValue('terms', e.target.checked);
  };

  const handleInvestorCategoryChange = (e) => {
    formik.setValues({
      ...formik.values,
      isAccreditedInvestor: false,
      isQualifiedClient: false,
      isQualifiedPurchaser: false,
      isRIA: false,
      isBrokerDealer: false,
    });
    formik.setFieldValue('category', e.target.value);
  };

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid xs={6} md={6}>
          <TextField
            error={!!(formik.touched.name && formik.errors.name)}
            fullWidth
            helperText={formik.touched.name && formik.errors.name}
            label="Your Name"
            name="name"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            error={!!(formik.touched.phone && formik.errors.phone)}
            fullWidth
            helperText={formik.touched.phone && formik.errors.phone}
            label="Your Phone Number"
            name="phone"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="phone"
            required
            value={formik.values.phone}
          />
        </Grid>

        <Grid xs={6} md={6}>
          <TextField
            fullWidth
            helperText={
              'Your email is linked to your account and cannot be changed.'
            }
            label="Your Email"
            name="email"
            disabled
            required
            InputLabelProps={{ shrink: true }}
            value={sessionUser?.email}
          />
        </Grid>

        <Grid xs={6} md={6}>
          <TextField
            error={!!(formik.touched.linkedIn && formik.errors.linkedIn)}
            fullWidth
            helperText={
              formik.touched.linkedIn && formik.errors.linkedIn
                ? formik.errors.linkedIn
                : 'This helps us better understand your background.'
            }
            label="Your LinkedIn Profile URL"
            name="linkedIn"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.linkedIn}
          />
        </Grid>
        <Grid xs={12}>
          <Typography variant={'h5'} sx={{ py: 1 }} color={'text.gold'}>
            What Category of Investor are you?
          </Typography>
          <TextField
            sx={{ width: '50%' }}
            label="Investor Category"
            name="category"
            onChange={handleInvestorCategoryChange}
            value={formik.values.category}
            select
            helperText={InvestorCategory[formik.values.category]?.description}
          >
            {InvestorCategories.map((ic) => (
              <MenuItem key={ic.id} value={ic.id}>
                <ListItemText primary={ic.title} />
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {qualfiicationChoices.length > 0 && (
          <Grid xs={12} md={12}>
            <Typography variant={'h5'} sx={{ py: 1 }} color={'text.gold'}>
              Investor Qualifications
            </Typography>
            <Typography variant={'body2'} color="text.secondary">
              Only qualified prospective investors can register for an account
              and gain access to deals and sponsors on OZD. Please read each
              choice and check all that apply.
            </Typography>
            <Box sx={{ mt: 2 }}>
              <QualificationForm
                formik={formik}
                choices={qualfiicationChoices}
                isRegistration={true}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant={'caption'}>
                <i>
                  OZD has no obligation to, and does not, monitor or review the
                  qualifications of prospective investors. Sponsors have the
                  sole right to determine the investor qualifications for any
                  particular Opportunity Zone deal they sponsor, which may
                  require its investors to have higher net worth, greater assets
                  or other qualifications. Even if you indicate your good faith
                  belief that you currently satisfy any or all of the above
                  criteria, you may be requested by Sponsors to provide
                  additional information verifying such status in connection
                  with making each investment. Your certification above shall
                  not guarantee any right to participate in or invest in such
                  investment opportunity.
                </i>
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      {formik.errors.submit && (
        <Typography color="error" sx={{ mt: 3 }} variant="body2">
          {formik.errors.submit}
        </Typography>
      )}
      {isQualificationTouched && isNotQualified && (
        <Alert severity={'error'} variant={'outlined'}>
          You must attest to being an Accredited Investor, Qualified Client,
          Qualified Purchaser, Registered Investment Advisor or Broker / Dealer
          to register for Opportunity Zone Deals.
        </Alert>
      )}
      <Fade in={isQualified}>
        <Stack
          sx={{ pt: 2, borderTop: '1px solid lightgrey' }}
          alignItems={'center'}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.terms}
                value={formik.values.terms}
                onChange={handleCheckboxChange}
              />
            }
            label={
              <Typography variant={'body2'} color={'text.secondary'}>
                I have read and agree to the{' '}
                <a
                  href="https://www.opportunityzonedeals.com/terms-of-use"
                  target="_blank"
                >
                  Terms of Use
                </a>
                {', '}
                <a
                  href="https://www.opportunityzonedeals.com/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                {', and '}
                <a
                  href="https://www.opportunityzonedeals.com/disclosures"
                  target="_blank"
                >
                  Disclaimers
                </a>{' '}
              </Typography>
            }
          />
          <Button
            fullWidth
            size="large"
            sx={{ mt: 3 }}
            type="submit"
            variant="contained"
            disabled={!formik.isValid}
          >
            Create your Account
          </Button>
        </Stack>
      </Fade>
    </form>
  );
};
