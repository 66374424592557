import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {
  useDeleteOrganizationLogo,
  useOrganization,
  useUpdateOrganization,
  useUpdateOrganizationLogo,
} from '../../components/hooks/organizations/use-organizations';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import Photo from '@mui/icons-material/Photo';
import { neutral } from '../../components/theme/colors';
import { Role } from '../../components/enums/role.enum';
import copy from 'copy-to-clipboard';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { useSessionUser } from '../../components/hooks/session/useSessionUser';
import { useLocation } from 'react-router-dom';
import { getOrganizationShareLink } from '../../helpers/get-share-link';
import { phoneRegExp } from '../../helpers/is-valid-phone';
import { useSnackbar } from '../../components/hooks/util/use-snackbar';
import { StorageBucket } from '../../helpers/supabase';
import { DeleteConfirmDialog } from '../../components/dialogs/delete-confirm-dialog';
import AddressAutocompleteTextField from '../../components/maps/address-autocomplete-textfield';
import MapView from '../../components/maps/map-view';
import { AddressText } from '../../components/maps/address-text';

const IMG_WIDTH = 600;
const IMG_HEIGHT = 120;

export default function OrganizationSettings() {
  const { sessionUser } = useSessionUser();
  const { render: renderSnackbar, setSnackbar } = useSnackbar();
  const isSysAdmin = sessionUser?.role === Role.SysAdmin;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orgId = isSysAdmin ? queryParams.get('orgId') : sessionUser?.orgId;

  const { data: organization, isLoading } = useOrganization({
    id: orgId,
    options: {
      enabled: !!orgId,
    },
  });
  const updateOrganization = useUpdateOrganization();
  const fileInputRef = useRef();

  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  const [showDeleteImageConfirm, setShowDeleteImageConfirm] = useState(false);

  const updateOrganizationLogo = useUpdateOrganizationLogo();
  const deleteOrganizationLogo = useDeleteOrganizationLogo();

  useEffect(() => {
    if (shareLinkCopied) {
      setTimeout(() => setShareLinkCopied(false), 3000);
    }
  }, [shareLinkCopied]);

  const handleCopyShareLink = (e) => {
    e.stopPropagation();
    copy(getOrganizationShareLink(organization?.linkId));
    setShareLinkCopied(true);
  };

  const goToSponsorPage = (e) => {
    e.stopPropagation();
    window.open('/public/organizations/' + organization?.linkId, '_blank');
  };

  const initialValues = {
    ...organization,
    submit: null,
  };

  const validationShape = {
    name: Yup.string().max(255).required('Name is required'),
    bio: Yup.string().required('Company bio is required'),
    website: Yup.string()
      .url('Website must be a valid URL')
      .max(255)
      .required('Website is required'),
    email: Yup.string().email().required('Contact email is required'),
    phone: Yup.string().matches(
      phoneRegExp,
      'Contact phone number is not valid',
    ),
    address1: Yup.string().required('Address Line 1 is required'),
    latlng: Yup.string(),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await updateOrganization.mutateAsync({
          id: organization.id,
          updated: values,
        });
        //Show success toast
        setSnackbar({ message: 'Sponsor details have been updated!' });
      } catch (err) {
        formik.handleReset();
        const errObj = JSON.parse(err.error);
        setSnackbar({
          message: 'Error: Unable to save sponsor details: ' + errObj.message,
        });
        helpers.setStatus({ success: false });
        helpers.setErrors({ [errObj.field]: errObj.message });
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      ...organization,
      submit: null,
    });
  }, [organization]);

  const closeDelete = () => {
    setShowDeleteImageConfirm(false);
  };

  const openDelete = () => {
    setShowDeleteImageConfirm(true);
  };

  const selectImage = async (event) => {
    try {
      await updateOrganizationLogo.mutateAsync({
        id: organization.id,
        images: event.target.files,
      });
      setSnackbar({ message: 'Logo has been uploaded' });
    } catch (e) {
      setSnackbar({ message: 'Error: ' + e });
    }
  };

  const removeLogo = async () => {
    try {
      await deleteOrganizationLogo.mutateAsync({
        id: organization.id,
      });
      setShowDeleteImageConfirm(false);
      setSnackbar({ message: 'Logo has been removed.' });
    } catch (e) {
      setSnackbar({ message: 'Error: ' + e });
    }
  };

  const hasImg = organization?.logoImage;
  const logoImgSrc =
    hasImg &&
    `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.ORGANIZATION_IMAGES}/${organization?.id}/${organization?.logoImage}?fit=max&h=${IMG_HEIGHT}`;

  const isDisabled =
    updateOrganizationLogo.isLoading ||
    updateOrganization.isLoading ||
    !formik.isValid;

  const latlng = formik.values.latlng ? JSON.parse(formik.values.latlng) : null;

  return (
    <Container maxWidth="xl">
      <DeleteConfirmDialog
        open={showDeleteImageConfirm}
        title={'Remove your Sponsor Logo?'}
        message={'This will permanently remove the logo and can not be undone.'}
        onClose={closeDelete}
        isLoading={deleteOrganizationLogo.isLoading}
        handleSubmit={removeLogo}
      />
      {renderSnackbar()}
      <Stack spacing={3}>
        <Typography variant="h4">Sponsor Settings</Typography>
        {/*<Tabs value={0}>*/}
        {/*  <Tab label="Profile" />*/}
        {/*  /!*<Tab label="Users" />*!/*/}
        {/*  /!*<Tab label="Billing" />*!/*/}
        {/*</Tabs>*/}
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Grid container spacing={1}>
            <Grid xs={12}>
              <form noValidate onSubmit={formik.handleSubmit}>
                <Card>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{ pr: 1 }}
                  >
                    <CardHeader
                      title="Profile"
                      subheader={'Update your sponsor profile information'}
                    />
                    <Stack direction="row" alignItems={'center'} spacing={1}>
                      <Typography
                        variant={'link'}
                        sx={{ fontSize: 12 }}
                        onClick={goToSponsorPage}
                      >
                        {getOrganizationShareLink(organization?.linkId)}
                      </Typography>
                      <Tooltip
                        placement={'bottom'}
                        title={
                          shareLinkCopied
                            ? 'Share Link Copied to Clipboard!'
                            : 'Copy Share Link'
                        }
                      >
                        <IconButton
                          color={'primary'}
                          onClick={handleCopyShareLink}
                        >
                          <ContentCopy />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                  <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                      <Grid container spacing={3}>
                        <Grid xs={12} md={12}>
                          <TextField
                            error={
                              !!(formik.touched.name && formik.errors.name)
                            }
                            fullWidth
                            helperText={
                              formik.touched.name && formik.errors.name
                            }
                            label="Sponsor Name"
                            name="name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name || ''}
                            required
                          />
                        </Grid>
                        <Grid xs={12}>
                          <TextField
                            multiline
                            fullWidth
                            required
                            rows={5}
                            error={!!(formik.touched.bio && formik.errors.bio)}
                            helperText={formik.touched.bio && formik.errors.bio}
                            placeholder={
                              'Enter a 1-2 sentence company biography including years of experience and areas of focus'
                            }
                            label="Company Bio"
                            name="bio"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.bio || ''}
                          />
                        </Grid>
                        <Grid xs={6} md={6}>
                          <TextField
                            error={
                              !!(
                                formik.touched.pocName && formik.errors.pocName
                              )
                            }
                            fullWidth
                            helperText={
                              formik.touched.pocName && formik.errors.pocName
                            }
                            label="Primary Contact Name"
                            name="pocName"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.pocName || ''}
                            required
                          />
                        </Grid>
                        <Grid xs={6} md={6}>
                          <TextField
                            error={
                              !!(
                                formik.touched.pocTitle &&
                                formik.errors.pocTitle
                              )
                            }
                            fullWidth
                            helperText={
                              formik.touched.pocTitle && formik.errors.pocTitle
                            }
                            label="Primary Contact Title"
                            name="pocTitle"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.pocTitle || ''}
                            required
                          />
                        </Grid>
                        <Grid xs={6} md={6}>
                          <TextField
                            fullWidth
                            error={
                              !!(formik.touched.email && formik.errors.email)
                            }
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                            label="Primary Contact Email"
                            name="email"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email || ''}
                            required
                          />
                        </Grid>
                        <Grid xs={6} md={6}>
                          <TextField
                            fullWidth
                            error={
                              !!(formik.touched.phone && formik.errors.phone)
                            }
                            helperText={
                              formik.touched.phone && formik.errors.phone
                            }
                            label="Primary Contact Phone"
                            name="phone"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.phone || ''}
                            required
                          />
                        </Grid>
                        <Grid xs={6} md={6}>
                          <TextField
                            error={
                              !!(
                                formik.touched.website && formik.errors.website
                              )
                            }
                            fullWidth
                            helperText={
                              formik.touched.website && formik.errors.website
                            }
                            label="Sponsor Website"
                            name="website"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.website || ''}
                            required
                          />
                        </Grid>
                        <Grid xs={6} md={6}>
                          <TextField
                            error={
                              !!(
                                formik.touched.linkedIn &&
                                formik.errors.linkedIn
                              )
                            }
                            fullWidth
                            helperText={
                              formik.touched.linkedIn && formik.errors.linkedIn
                            }
                            label="Sponsor LinkedIn Profile URL"
                            name="linkedIn"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.linkedIn || ''}
                          />
                        </Grid>
                        <Grid xs={12} md={12}>
                          <AddressAutocompleteTextField
                            formik={formik}
                            fullWidth
                            label="Address"
                            name="address1"
                            addressRequired
                          />
                          {latlng && (
                            <Stack sx={{ mt: 2 }}>
                              <MapView
                                markers={[{ lat: latlng.lat, lng: latlng.lng }]}
                                height={150}
                              />
                              <AddressText
                                address1={formik.values.address1}
                                address2={formik.values.address2}
                                city={formik.values.city}
                                state={formik.values.state}
                                zip={formik.values.zip}
                              />
                            </Stack>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <Divider />
                  {updateOrganization.isLoading ? (
                    <CardActions sx={{ justifyContent: 'flex-end', my: 1 }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <CircularProgress size={20} />
                        <Button disabled color={'primary'}>
                          Saving...
                        </Button>
                      </Stack>
                    </CardActions>
                  ) : (
                    <CardActions sx={{ justifyContent: 'flex-end', my: 1 }}>
                      <Button
                        variant="contained"
                        disabled={isDisabled}
                        type="submit"
                      >
                        Save details
                      </Button>
                    </CardActions>
                  )}
                </Card>
                {formik.errors.submit && (
                  <Typography color="error" sx={{ mt: 3 }} variant="body2">
                    {formik.errors.submit}
                  </Typography>
                )}
              </form>
            </Grid>
            <Grid xs={12}>
              <Card>
                <CardHeader
                  title="Logo"
                  subheader={
                    'This logo will be displayed on your public profile'
                  }
                />
                <CardContent>
                  <Stack spacing={1} sx={{ alignItems: 'center' }}>
                    {hasImg ? (
                      <img src={logoImgSrc} alt={organization.name} />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: neutral[200],
                          borderRadius: 5,
                        }}
                      >
                        <Photo
                          sx={{
                            width: IMG_WIDTH,
                            height: IMG_HEIGHT,
                          }}
                        />
                      </div>
                    )}
                  </Stack>
                </CardContent>
                <Divider />
                {updateOrganizationLogo.isLoading ? (
                  <CardActions sx={{ justifyContent: 'flex-end', my: 1 }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <CircularProgress size={20} />
                      <Button disabled color={'primary'}>
                        Uploading Image...
                      </Button>
                    </Stack>
                  </CardActions>
                ) : (
                  <CardActions sx={{ justifyContent: 'flex-end', my: 1 }}>
                    {hasImg && (
                      <Button
                        variant="text"
                        color={'error'}
                        sx={{ mr: 2 }}
                        disabled={isDisabled}
                        onClick={openDelete}
                      >
                        Remove Logo
                      </Button>
                    )}
                    <label htmlFor="raised-button-file">
                      <TextField
                        ref={fileInputRef}
                        accept="image/*"
                        sx={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={selectImage}
                      />
                      <Button
                        variant="contained"
                        disabled={isDisabled}
                        onClick={() => fileInputRef.current.click()}
                      >
                        {organization?.logoImage
                          ? 'Change logo'
                          : 'Upload logo'}
                      </Button>
                    </label>
                  </CardActions>
                )}
              </Card>
            </Grid>
          </Grid>
        )}
      </Stack>
    </Container>
  );
}
