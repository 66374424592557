import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DialogActionBar } from './dialog-action-bar';

const DealCopyDialog = (props) => {
  const { open, title, onClose, onSubmit, isLoading, initialName } = {
    ...props,
  };

  const initialValues = {
    name: initialName,
    submit: null,
  };

  const validationShape = {
    name: Yup.string()
      .min(5, 'Name must be at least 5 characters')
      .max(255, 'Name must be at most 255 characters')
      .required('Please enter a new name for the deal'),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await onSubmit(values.name);
      } catch (err) {
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ [errObj.field]: errObj.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  useEffect(() => {
    if (open) {
      formik.setFieldValue('name', initialName);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'xs'} fullWidth>
      <DialogTitle>Copy Deal</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant={'h5'}>{title}</Typography>
          <Typography variant={'body1'}>
            This name will be used for the copied deal.
          </Typography>
          <TextField
            error={formik.errors.name !== undefined}
            fullWidth
            required
            helperText={formik.errors.name}
            label="New Deal Name"
            name="name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
          />
        </Stack>
      </DialogContent>
      <DialogActionBar
        isLoading={isLoading}
        loadingLabel={'Copying...'}
        submitLabel={'Copy'}
        onCancel={onClose}
        onSubmit={handleSubmit}
        disabled={
          formik.isSubmitting || formik.values.name === '' || !formik.isValid
        }
      />
    </Dialog>
  );
};

export default DealCopyDialog;
