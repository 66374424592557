import { IconButton, Snackbar, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CheckCircle, Close } from '@mui/icons-material';

export const useSnackbar = () => {
  const [snackbar, setSnackbar] = useState(null);
  const onCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(null);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={onCloseSnackbar}
    >
      <Close fontSize="small" />
    </IconButton>
  );

  return {
    setSnackbar: ({ message }) => {
      setSnackbar({ message });
    },
    render: (vertical = 'bottom', horizontal = 'right') => (
      <Snackbar
        open={snackbar !== null}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={6000}
        onClose={onCloseSnackbar}
        message={
          <Stack direction="row" alignItems={'center'} spacing={1}>
            <CheckCircle />
            <Typography>{snackbar?.message}</Typography>
          </Stack>
        }
        action={action}
      />
    ),
  };
};
