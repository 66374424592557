import React, { useRef, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import Add from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import { useUpdateDealAssetImage } from '../../hooks/deals/use-deal-images';
import { StorageBucket } from '../../../helpers/supabase';
import { DeleteConfirmDialog } from '../../dialogs/delete-confirm-dialog';
import { ImageLibrary } from '../../images/image-library';
import { SectionHeader } from '../../util/section-header';

export const EditDealAssetImages = ({
  dealId,
  asset,
  onCreateImage,
  onDeleteImage,
  isCreating,
  isDeleting,
  setSnackbar,
}) => {
  const fileInputRef = useRef();
  const updateDealAssetImage = useUpdateDealAssetImage();

  const [imgIdxToDelete, setImgIdxToDelete] = useState(null);

  const imgSrcs = asset?.images?.length
    ? asset.images.map(
        (img) =>
          `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.OFFERING_IMAGES}/${dealId}/assets/${asset.id}/${img.name}`,
      )
    : null;

  const hasImg = imgSrcs?.length;

  const handleDeleteImageConfirmClose = () => {
    setImgIdxToDelete(null);
  };

  const handleDeleteImage = (idx) => {
    setImgIdxToDelete(idx);
  };

  const fileListToArray = (filelist) => {
    return Array.from(filelist);
  };

  const uploadSelectedImages = async (event) => {
    const request = {
      dealId,
      assetId: asset.id,
      images: fileListToArray(event.target.files),
    };
    onCreateImage(request);
  };

  const removeImage = async () => {
    await onDeleteImage({
      dealId,
      assetId: asset.id,
      imageId: asset.images[imgIdxToDelete].id,
    });
    setImgIdxToDelete(null);
  };

  const handleUpdateImagePriority = async (imgIdx, newPriority) => {
    try {
      updateDealAssetImage.mutate({
        dealId: dealId,
        assetId: asset.id,
        imageId: asset.images[imgIdx].id,
        isPriority: newPriority,
      });
      setSnackbar({ message: 'Image priority has been updated!' });
    } catch (e) {
      setSnackbar({ message: 'Error: ' + e });
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <DeleteConfirmDialog
        title={'Remove this Project Image?'}
        message={
          'This will permanently remove the image and can not be undone.'
        }
        open={imgIdxToDelete !== null}
        onClose={handleDeleteImageConfirmClose}
        isLoading={isDeleting}
        handleSubmit={removeImage}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <SectionHeader
          title={'Images'}
          subtitle={'Images of the project that will attract investors'}
        />
        <label htmlFor="raised-button-file">
          <TextField
            ref={fileInputRef}
            accept="image/*"
            sx={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={uploadSelectedImages}
          />
          <Button
            fullWidth
            size={'small'}
            variant="text"
            onClick={() => fileInputRef.current.click()}
            startIcon={<Add />}
          >
            Add
          </Button>
        </label>
      </Stack>

      <Grid container alignItems={'center'}>
        <Grid xs={12}>
          {!hasImg ? (
            <Alert severity={'warning'}>No images have been added yet</Alert>
          ) : (
            <ImageLibrary
              images={asset?.images}
              imgSrcs={imgSrcs}
              onClickDelete={handleDeleteImage}
              onChangePriority={handleUpdateImagePriority}
            />
          )}
          {isCreating && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <CircularProgress size={20} />
              <Button disabled color={'primary'}>
                Uploading images...
              </Button>
            </Stack>
          )}
        </Grid>
        <Grid xs={12} sx={{ mt: 2 }}>
          <Typography variant={'caption'} color={'text.secondary'}>
            Images must be .jpg or .png files no larger than 4.5 MB
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
