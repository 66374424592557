import { Button, Chip, Grid, Stack, Tooltip } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Undo from '@mui/icons-material/Undo';
import Save from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBack from '@mui/icons-material/ArrowBack';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { DealStatus } from '../../enums/deal-status.enum';
import { Role } from '../../enums/role.enum';
import { success } from '../../theme/colors';
import { DealType } from '../../enums/deal-type.enum';
import { useSessionUser } from '../../hooks/session/useSessionUser';
import { useNavigate } from 'react-router-dom';
import { capitalize } from '../../../helpers/strings';
import { smartDateFormat, utcToLocalTime } from '../../../helpers/dates';
import { DealStatusChip } from '../chips/deal-status-chip';
import { grey } from '@mui/material/colors';
import { PublishButton } from '../publish-button';
import { DealPaidChip } from '../chips/deal-paid-chip';

export const EditTopNav = ({
  deal,
  formik,
  isNew,
  isChanged,
  isPreview,
  setPreview,
  onSave,
  isSaved,
  isSaving,
  onUndo,
  canSubmitForApproval,
  onSubmitForApproval,
  isLoading,
}) => {
  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (isSaving) setSaved(true);
  }, [isSaving]);

  if (isLoading) {
    return <></>;
  }

  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isOrgAdmin = sessionUser?.role === Role.OrgAdmin;
  const isDraft = deal?.status === DealStatus.Draft;
  const isPendingApproval = deal?.status === DealStatus.PendingReview;
  const isApproved = deal?.status === DealStatus.Approved;

  const isOwner =
    (isOrgAdmin && deal?.orgId === sessionUser?.orgId) ||
    deal?.ownerId === sessionUser?.id;

  const handleOpenPreview = () => {
    setPreview(true);
  };

  const handleClosePreview = () => {
    setPreview(false);
  };

  const hasErrors = isNew
    ? !saved
      ? false
      : Object.keys(formik.errors).length > 0 &&
        Object.keys(formik.touched).length > 0
    : Object.keys(formik.errors).length > 0;

  const numErrors = Object.keys(formik.errors).length;
  const submitErrors = formik.errors.submit;

  const handleSaveClick = (e) => {
    e.stopPropagation();
    onSave();
  };

  const handleUndo = (e) => {
    e.stopPropagation();
    onUndo();
  };

  const handleExitClick = (e) => {
    e.stopPropagation();
    navigate('/deals/' + deal.id);
  };

  const isFund = deal?.type === DealType.Fund;
  const dealWord = isFund ? 'Fund' : 'Deal';
  const activeSub = deal.subscriptions?.find((s) => !s.isCancelled);

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      sx={{
        py: 2,
        pr: 2,
        position: 'sticky',
        top: 0,
        zIndex: (theme) => theme.zIndex.appBar,
        backgroundColor: alpha(grey[100], 0.8),
      }}
    >
      <Grid container sx={{ pl: 3 }}>
        <Grid xs={12} md={12} lg={12} item>
          <Stack direction="row" sx={{ mb: 1 }} justifyContent="space-between">
            {!isPreview ? (
              <Stack>
                {!isSaving && (
                  <Button
                    color={'error'}
                    size={'small'}
                    sx={{ ml: -1 }}
                    onClick={handleExitClick}
                    startIcon={<ArrowBack />}
                  >
                    Exit{!isSaved && ' without saving'}
                  </Button>
                )}
              </Stack>
            ) : (
              <Stack>
                <Typography variant="h4">{dealWord} Page Preview</Typography>
                <Typography variant="body2" color="text.secondary">
                  This is how Investors will see the public{' '}
                  {dealWord.toLowerCase()} page.
                </Typography>
              </Stack>
            )}

            <Stack direction={'row'} columnGap={3}>
              {isSaving ? (
                <Stack direction="row" alignItems="center" columnGap={1}>
                  <CircularProgress size={20} />
                  <Button disabled color={'primary'} size={'small'}>
                    Saving...
                  </Button>
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={'center'}
                  spacing={2}
                >
                  {hasErrors && (
                    <Chip
                      variant="filled"
                      color="error"
                      icon={<ErrorOutline />}
                      label={
                        submitErrors
                          ? capitalize(submitErrors)
                          : `Unable to save, fix the ${numErrors} outstanding errors`
                      }
                    />
                  )}
                  {isChanged && (
                    <Button
                      size={'small'}
                      variant={'outlined'}
                      onClick={handleUndo}
                      startIcon={<Undo />}
                    >
                      Undo Changes
                    </Button>
                  )}
                  <Button
                    size={'small'}
                    variant={'contained'}
                    onClick={handleSaveClick}
                    startIcon={<Save />}
                    disabled={hasErrors || !isChanged || isSaved}
                  >
                    {isChanged ? 'Save' : 'Saved'}
                  </Button>
                  {!hasErrors && (
                    <>
                      {!isPreview && (
                        <Tooltip
                          placement={'bottom'}
                          title={`Preview how an Investor will see this ${dealWord.toLowerCase()}`}
                        >
                          <Button size={'small'} onClick={handleOpenPreview}>
                            Preview
                          </Button>
                        </Tooltip>
                      )}
                      {!isPreview && isDraft && (isSysAdmin || isOwner) && (
                        <PublishButton
                          deal={deal}
                          canSubmit={canSubmitForApproval}
                          isChanged={isChanged}
                          isSaved={isSaved}
                          isSysAdmin={isSysAdmin}
                          onSubmitForApproval={onSubmitForApproval}
                        />
                      )}
                      {!isPreview &&
                        isPendingApproval &&
                        (isSysAdmin || isOwner) && (
                          <Tooltip
                            placement={'bottom'}
                            title={
                              'Submitted for review ' +
                              smartDateFormat(utcToLocalTime(deal.submittedAt))
                            }
                          >
                            <span>
                              <DealStatusChip deal={deal} />
                            </span>
                          </Tooltip>
                        )}
                      {!isPreview && activeSub && <DealPaidChip />}
                      {!isPreview && isApproved && (isSysAdmin || isOwner) && (
                        <Stack>
                          <Tooltip
                            placement={'bottom'}
                            title={
                              'Publicly listed as of ' +
                              smartDateFormat(utcToLocalTime(deal.approvedAt))
                            }
                          >
                            <Stack
                              direction={'row'}
                              alignItems={'center'}
                              columnGap={1}
                              sx={{ cursor: 'default' }}
                            >
                              <CheckCircle color={'success'} />
                              <Typography
                                sx={{
                                  fontSize: '0.8125rem',
                                  fontWeight: 600,
                                  color: success['main'],
                                }}
                              >
                                Approved
                              </Typography>
                            </Stack>
                          </Tooltip>
                        </Stack>
                      )}
                    </>
                  )}
                  {isPreview && (
                    <Tooltip
                      placement={'bottom'}
                      title={`Go back to editing this ${dealWord.toLowerCase()}`}
                    >
                      <Button size={'small'} onClick={handleClosePreview}>
                        Back to Editor
                      </Button>
                    </Tooltip>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};
