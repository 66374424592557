import React, { memo, useEffect, useMemo, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import {
  Autocomplete,
  Box,
  debounce,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  addressFromPlace,
  getPlaceDetails,
  getPlacePredictions,
  PlacesLibraries,
} from './google-maps-utils';
import LocationOn from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';

function AddressAutocompleteTextField({ ...props }) {
  const { formik, fullWidth, label, name, required, addressRequired } = props;

  const value = formik.values[name];
  const isTouched = formik.touched[name];
  const hasError = formik.errors[name];

  const error = !!(isTouched && hasError);
  const helperText = isTouched && hasError ? hasError : null;

  const [selectedValue, setSelectedValue] = useState(value);
  const [searchResults, setSearchResults] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: PlacesLibraries,
  });

  useEffect(() => {
    if (isLoaded) {
      setSessionToken(new window.google.maps.places.AutocompleteSessionToken());
      const mapDiv = document.getElementById('autocomplete');
      setMap(new window.google.maps.Map(mapDiv));
    }
  }, [isLoaded]);

  const fetchPlaces = useMemo(
    () =>
      debounce(async (text) => {
        try {
          const { predictions } = await getPlacePredictions(
            text,
            sessionToken,
            addressRequired,
          );
          setSearchResults(predictions);
        } catch (e) {
          console.log(JSON.stringify(e, null, 2));
        }
      }, 200),
    [],
  );

  if (!isLoaded) {
    return <></>;
  }

  const handleTextChange = async (event, newText) => {
    if (event) {
      event.target.name = name;
      formik.handleChange(event);
    }
    if (!newText || newText === '') return;
    fetchPlaces(newText);
  };

  const onSelect = async ({
    address1,
    address2,
    city,
    state,
    postcode,
    latlng,
  }) => {
    formik.setValues(
      {
        ...formik.values,
        address1,
        address2,
        city,
        state,
        zip: postcode,
        latlng,
      },
      true,
    );

    setTimeout(() => formik.validateForm(), 100); //TODO: Fix this hack
  };

  const clearSelectPlace = (event) => {
    setSelectedValue('');
    onSelect(event, {
      address1: '',
      address2: '',
      city: '',
      state: '',
      postcode: '',
      latlng: null,
    });
  };

  const handleSelectPlace = async (event, newValue) => {
    if (!newValue) {
      clearSelectPlace(event);
      return;
    }

    setSelectedValue(newValue.structured_formatting.main_text);

    const { place_id } = newValue;

    const onGetPlaceComplete = (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const address = addressFromPlace(place);
        onSelect(address);
        //Reset session token after selection
        setSessionToken(
          new window.google.maps.places.AutocompleteSessionToken(),
        );
      } else {
        console.log('GetPlace error status: ' + status);
      }
    };

    getPlaceDetails(place_id, map, sessionToken, onGetPlaceComplete);
  };

  return (
    <>
      <Autocomplete
        disablePortal
        freeSolo
        autoComplete={true}
        value={selectedValue}
        filterOptions={(options) => options}
        inputValue={value || selectedValue || ''}
        onChange={handleSelectPlace}
        onInputChange={handleTextChange}
        options={searchResults || []}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : option.structured_formatting.main_text
        }
        renderOption={(props, option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props} key={option.place_id} name={name}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOn sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
                >
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            onBlur={formik.handleBlur}
            label={label}
            error={(isTouched && !selectedValue && required) || error}
            helperText={!selectedValue && required && helperText}
            fullWidth={fullWidth}
            name={name}
            {...params}
            required={required}
            inputProps={{ ...params.inputProps, autoComplete: 'off', required }}
          />
        )}
      />
      <div id={'autocomplete'}></div>
    </>
  );
}

export default memo(AddressAutocompleteTextField);
