import {
  Alert,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Role } from '../../components/enums/role.enum';
import { useSessionUser } from '../../components/hooks/session/useSessionUser';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DealStatus } from '../../components/enums/deal-status.enum';
import Button from '@mui/material/Button';
import { Add, Cancel, Inventory } from '@mui/icons-material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { DealManageItem } from '../../components/deals/manage/deal-manage-item';
import DealArchiveDialog from '../../components/dialogs/deal-archive-dialog';
import DealUnlistDialog from '../../components/dialogs/deal-unlist-dialog';
import DealCopyDialog from '../../components/dialogs/deal-copy-dialog';
import { useManageDeals } from '../../components/hooks/deals/use-deals';
import { useOrganization } from '../../components/hooks/organizations/use-organizations';
import {
  useArchiveDeal,
  useCopyDeal,
} from '../../components/hooks/deals/use-deal';
import { useUnlistDeal } from '../../components/hooks/deals/use-deal-approval';
import { useSnackbar } from '../../components/hooks/util/use-snackbar';

export const Manage = () => {
  const { sessionUser } = useSessionUser();
  const navigate = useNavigate();
  const { render: renderSnackbar, setSnackbar } = useSnackbar();
  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isInvestor = sessionUser?.role === Role.Investor;
  const hasOrg = sessionUser?.orgId && !isSysAdmin && !isInvestor;

  const { data: deals, isLoading: isLoadingDeals, error } = useManageDeals();
  const { data: organization, isLoading: isLoadingOrg } = useOrganization({
    id: sessionUser?.orgId,
    options: {
      enabled: !!sessionUser?.orgId,
    },
  });

  const copyDeal = useCopyDeal();
  const archiveDeal = useArchiveDeal();
  const unlistDeal = useUnlistDeal();

  const needsApproval = hasOrg && !organization?.approvedAt;
  const isLoading = isLoadingDeals || (hasOrg && isLoadingOrg);

  const [orgFilter, setOrgFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState(null);
  const [dealToArchive, setDealToArchive] = useState(null);
  const [dealToCopy, setDealToCopy] = useState(null);
  const [dealToUnlist, setDealToUnlist] = useState(null);

  const orgList = [];
  const statusList = [];
  deals?.forEach((deal) => {
    if (isSysAdmin && !orgList.find((org) => org.id === deal.organization.id)) {
      orgList.push({ ...deal.organization });
    }
    if (!statusList.find((status) => status === deal.status)) {
      statusList.push(deal.status);
    }
  });

  const handleOrgFilterChange = (event) => {
    setOrgFilter(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleTypeFilterChange = (event) => {
    setTypeFilter(event.target.value);
  };

  const handleRemoveOrg = (orgIdToRemove) => {
    setOrgFilter(orgFilter.filter((orgId) => orgId !== orgIdToRemove));
  };

  const handleRemoveStatus = (statusToRemove) => {
    setStatusFilter(statusFilter.filter((s) => s !== statusToRemove));
  };

  const handleAddDealClick = (event) => {
    navigate('/deals/create');
  };

  const handleArchivedClick = (event) => {
    navigate('/deals/archived');
  };

  const activeDeals = deals?.filter((deal) => deal.archivedAt === null);
  const filteredActiveDeals = activeDeals?.filter(
    (deal) =>
      (!orgFilter?.length ||
        orgFilter.find((orgId) => orgId === deal.organization.id)) &&
      (!statusFilter?.length ||
        statusFilter.find(
          (status) => status === deal.status.replace(' ', ''),
        )) &&
      (!typeFilter || typeFilter === 'All' || typeFilter === deal.type),
  );

  const handleCopyClick = async (deal) => {
    setDealToCopy(deal);
  };

  const closeCopy = () => {
    setDealToCopy(null);
  };

  const handleCopySubmit = async (newName) => {
    try {
      copyDeal.mutate({
        deal: dealToCopy,
        name: newName,
      });
      closeCopy();
      setSnackbar({ message: `${newName} has been copied.` });
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
      setSnackbar({ message: `Error: ${JSON.stringify(e, null, 2)}.` });
    }
  };

  const handleArchiveClick = (deal) => {
    setDealToArchive(deal);
  };

  const closeArchive = () => {
    setDealToArchive(null);
  };

  const handleArchiveSubmit = async () => {
    try {
      archiveDeal.mutate({
        dealId: dealToArchive.id,
        isUndo: false,
      });
      setSnackbar({ message: `${dealToArchive.name} has been archived.` });
      closeArchive();
    } catch (e) {
      setSnackbar({ message: `Error: ${JSON.stringify(e, null, 2)}.` });
    }
  };

  const handleUnlistClick = (deal) => {
    setDealToUnlist(deal);
  };

  const closeUnlist = () => {
    setDealToUnlist(null);
  };

  const handleUnlistSubmit = async () => {
    const isPendingApproval = dealToUnlist.status === DealStatus.PendingReview;

    try {
      unlistDeal.mutate({
        deal: dealToUnlist,
      });
      setSnackbar({
        message: isPendingApproval
          ? 'This deal has been removed from review'
          : 'This deal has been removed from publication',
      });
      closeUnlist();
    } catch (e) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  return (
    <Container maxWidth="xl">
      <DealArchiveDialog
        open={dealToArchive !== null}
        title={dealToArchive?.name}
        onClose={closeArchive}
        onSubmit={handleArchiveSubmit}
        isLoading={archiveDeal.isLoading}
      />
      <DealUnlistDialog
        open={dealToUnlist !== null}
        title={dealToUnlist?.name}
        onClose={closeUnlist}
        onSubmit={handleUnlistSubmit}
        isUnlist={dealToUnlist?.status === DealStatus.Approved}
        isLoading={unlistDeal.isLoading}
      />
      <DealCopyDialog
        open={dealToCopy !== null}
        title={dealToCopy?.name}
        onClose={closeCopy}
        onSubmit={handleCopySubmit}
        isLoading={copyDeal.isLoading}
        initialName={dealToCopy?.name + ' (Copy)'}
      />
      {renderSnackbar()}
      <Stack spacing={3} sx={{ mt: 1 }}>
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          {!isLoading && !isSysAdmin && !organization?.approvedAt ? (
            <Stack>
              <Typography variant="h4">
                Sponsor Account Setup In Progress
              </Typography>
            </Stack>
          ) : !isLoading ? (
            <Typography variant="h4">Manage Deals</Typography>
          ) : (
            <></>
          )}
          {!isLoading && (
            <Stack
              spacing={3}
              direction="row"
              justifyContent="space-between"
              alignItems={'center'}
            >
              {!isSysAdmin && deals?.length > 0 && organization?.approvedAt && (
                <Button
                  onClick={handleArchivedClick}
                  startIcon={<Inventory />}
                  color="inherit"
                  variant="text"
                >
                  Archived
                </Button>
              )}
              {(isSysAdmin || organization?.approvedAt) && (
                <Button
                  onClick={handleAddDealClick}
                  startIcon={<Add />}
                  variant="contained"
                >
                  Add Deal
                </Button>
              )}
            </Stack>
          )}
        </Stack>
        {deals?.length > 0 && (
          <Grid container>
            {isSysAdmin && (
              <Grid item xs={3} sx={{ pr: 2 }}>
                <FormControl fullWidth>
                  <InputLabel id="org-label">Organization</InputLabel>
                  <Select
                    labelId="org-label"
                    id="org-select"
                    label="Organization"
                    value={orgFilter || ''}
                    onChange={handleOrgFilterChange}
                    multiple
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                      >
                        {selected.map((value) => (
                          <Chip
                            sx={{ mr: 1 }}
                            key={value}
                            label={
                              orgList.find((org) => org.id === value)?.name
                            }
                            variant="outlined"
                            deleteIcon={
                              <Cancel
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                            onDelete={(e) => handleRemoveOrg(value)}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {orgList.map((org) => (
                      <MenuItem key={org.id} value={org.id}>
                        <Checkbox checked={orgFilter.indexOf(org.id) > -1} />
                        <ListItemText primary={org.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={3} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="org-select"
                  label="Status"
                  value={statusFilter || ''}
                  onChange={handleStatusFilterChange}
                  multiple
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {selected.map((value) => (
                        <Chip
                          sx={{ mr: 1 }}
                          key={value}
                          label={DealStatus[value]}
                          variant="outlined"
                          deleteIcon={
                            <Cancel
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                          onDelete={(e) => handleRemoveStatus(value)}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {Object.keys(DealStatus)
                    .filter((status) => status !== DealStatus.Archived)
                    .map((status) => (
                      <MenuItem key={status} value={status}>
                        <Checkbox checked={statusFilter.indexOf(status) > -1} />
                        <ListItemText primary={DealStatus[status]} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Deal Type"
                name="type"
                onChange={handleTypeFilterChange}
                value={typeFilter || ''}
                select
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Asset">Single Investment</MenuItem>
                <MenuItem value="Fund">Fund (Multiple Investments)</MenuItem>
                {/*<MenuItem value="Sale">Property for Sale</MenuItem>*/}
              </TextField>
            </Grid>
          </Grid>
        )}
        {isLoading ? (
          <Stack direction="row" justifyContent="center" spacing={1}>
            <CircularProgress size={30} sx={{ my: 2 }} />
          </Stack>
        ) : needsApproval ? (
          <Alert severity="info" sx={{ width: '100%' }}>
            {`We are finalizing the setup for your account. This usually takes less than one business day.`}
          </Alert>
        ) : filteredActiveDeals?.length > 0 ? (
          <Grid container>
            {filteredActiveDeals.map((deal) => (
              <Grid item xs={12} sm={6} lg={4} key={deal.id}>
                <DealManageItem
                  deal={deal}
                  onCopyClick={handleCopyClick}
                  onArchiveClick={handleArchiveClick}
                  onUnlistClick={handleUnlistClick}
                  isLoading={copyDeal.isLoading || archiveDeal.isLoading}
                  setSnackbar={setSnackbar}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <>
            <Alert severity="info" variant="standard" sx={{ width: '100%' }}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant={'body2'}>
                  {!activeDeals?.length
                    ? 'There are currently no deals to manage.'
                    : 'No results match your search criteria.'}
                </Typography>
              </Stack>
            </Alert>
            {!deals?.length > 0 && (
              <Stack spacing={1} alignItems="center" sx={{ my: 2 }}>
                <Typography variant={'h6'}>
                  {`Click 'Add Deal' to create your first deal!`}
                </Typography>
                <img
                  alt="Add your first deal"
                  src="/images/stock/business_deal.png"
                  style={{
                    width: '50%',
                    height: '50%',
                  }}
                />
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Container>
  );
};
