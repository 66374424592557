import { Chip } from '@mui/material';
import React from 'react';
import { DealType, DealTypeLabel } from '../../enums/deal-type.enum';

export const DealTypeChip = ({ type, sx }) => (
  <Chip
    sx={sx}
    label={DealTypeLabel[type]}
    color={
      type === DealType.Asset
        ? 'info'
        : type === DealType.Sale
          ? 'info2'
          : 'secondary'
    }
  />
);
