import * as Yup from 'yup';

const validationShape = {
  name: Yup.string().max(255).required('Name is required'),
  sqFt: Yup.number()
    .positive('Square Footage is not valid')
    .integer('Square Footage is not valid')
    .nullable(true),
  units: Yup.number()
    .positive('Number of units is not valid')
    .integer('Number of units is not valid')
    .nullable(true),
  acreage: Yup.number()
    .positive('Acreage is not valid')
    .integer('Acreage is not valid')
    .nullable(true),
  videoUrl: Yup.string()
    .url('Video URL is not valid')
    .max(255, 'Website can be no more than 255 characters')
    .nullable(true),
};

export const AssetSaveValidationSchema = Yup.object().shape(validationShape);
