import React, { useState } from 'react';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

export const CustomImageGallery = ({
  galleryRef,
  images,
  onClickImage,
  showFullscreenButton = true,
  showThumbnails = false,
}) => {
  return (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={(event) => event.stopPropagation()}
    >
      <ImageGallery
        ref={galleryRef}
        items={images}
        showThumbnails={showThumbnails && images?.length > 1}
        // renderItem={renderItem}
        onClick={onClickImage}
        showFullscreenButton={showFullscreenButton}
        renderLeftNav={(onClick, disabled) => (
          <button
            type="button"
            className="image-gallery-icon image-gallery-left-nav"
            aria-label="Next Image"
            onClick={onClick}
          >
            <ChevronLeft
              sx={{
                cursor: 'pointer',
              }}
              fontSize={'large'}
            />
          </button>
        )}
        renderRightNav={(onClick, disabled) => (
          <button
            type="button"
            className="image-gallery-icon image-gallery-right-nav"
            aria-label="Next Image"
            onClick={onClick}
          >
            <ChevronRight
              sx={{
                cursor: 'pointer',
              }}
              fontSize={'large'}
            />
          </button>
        )}
        renderPlayPauseButton={() => null}
        renderCustomControls={null}
      />
    </div>
  );
};
