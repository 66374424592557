import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import React from 'react';

export const AddressText = ({ ...props }) => {
  const { address1, address2, city, state, zip } = props;

  return (
    <Stack direction="row" alignItems="center" sx={{ my: 1 }} spacing={1}>
      {address1 && <Typography variant={'body1'}>{address1},</Typography>}
      {address2 && <Typography variant={'body1'}>{address2},</Typography>}
      <Typography variant="body1">
        {city}, {state} {zip}
      </Typography>
    </Stack>
  );
};
