import { useAuth0 } from '@auth0/auth0-react';

export const toSessionUser = (user) => {
  if (!user) return null;
  const id = user[process.env.REACT_APP_AUTH0_DOMAIN + '/ozd_user_id'];
  const orgId = user[process.env.REACT_APP_AUTH0_DOMAIN + '/orgId'];
  const role = user[process.env.REACT_APP_AUTH0_DOMAIN + '/role'];
  return {
    id,
    role,
    orgId: orgId ? orgId : null,
    name: user?.name,
    email: user?.email,
    picture: user?.picture,
    nickname: user?.nickname,
    auth0Id: user?.sub,
    email_verified: user?.email_verified,
    is_registered: !!id,
  };
};

export const useSessionUser = () => {
  const { user, isLoading, error } = useAuth0();
  return {
    sessionUser: toSessionUser(user),
    isLoading,
    error,
  };
};
