import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { DialogActionBar } from './dialog-action-bar';

const DealUnlistDialog = (props) => {
  const { open, title, onClose, onSubmit, multiple, isLoading, isUnlist } = {
    ...props,
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const message = isUnlist
    ? `By unlisting ${
        multiple ? 'these deals, they' : 'this deal, it'
      } will be removed from the public deal search and put into Draft status.`
    : `Removing ${multiple ? 'these deals' : 'this deal'} from
            review will put them into Draft status.`;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {isUnlist
          ? `Remove Deal${multiple ? 's' : ''} from Publication`
          : 'Remove from Review'}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant={'h5'}>{title}</Typography>
          <Typography variant={'body1'}>
            {message} You can re-submit this deal when ready.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActionBar
        isLoading={isLoading}
        loadingLabel={'Removing...'}
        submitLabel={
          isUnlist ? 'Remove from Publication' : 'Remove from Review'
        }
        submitColor={'error'}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default DealUnlistDialog;
