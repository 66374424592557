import {
  Button,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import Close from '@mui/icons-material/Close';
import Edit from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useSessionUser } from '../hooks/session/useSessionUser';
import { Role } from '../enums/role.enum';
import { StorageBucket } from '../../helpers/supabase';

export const OrganizationPopover = ({ ...props }) => {
  const { anchorEl, organization, open, onClose, isPublic } = props;
  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();
  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isOrgAdmin = sessionUser?.role === Role.OrgAdmin;
  const canEdit =
    (isSysAdmin || (isOrgAdmin && sessionUser.orgId === organization.id)) &&
    !isPublic;

  const goToSettings = (e) => {
    e.stopPropagation();
    if (isSysAdmin) {
      navigate('/settings/organization?orgId=' + organization.id);
    } else {
      navigate('/settings/organization');
    }
  };

  const goToSponsorPage = (e) => {
    e.stopPropagation();
    onClose();
    if (isPublic) {
      navigate('/public/organizations/' + organization.linkId);
    } else {
      navigate('/organizations/' + organization.id);
    }
  };

  const hasImg = organization.logoImage;
  const logoImgSrc =
    hasImg &&
    `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.ORGANIZATION_IMAGES}/${organization.id}/${organization.logoImage}?fit=max&h=40`;

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Stack spacing={2} sx={{ p: 4 }} onClick={(e) => e.stopPropagation()}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          {hasImg ? (
            <img src={logoImgSrc} alt={organization.name} />
          ) : (
            <Typography variant={'h5'}>{organization.name}</Typography>
          )}
          <Stack direction={'row'} columnGap={2}>
            {canEdit && (
              <Tooltip title={'Edit your sponsor details'}>
                <IconButton
                  onClick={goToSettings}
                  sx={{ height: 33, width: 33 }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={'Close'}>
              <IconButton onClick={onClose} sx={{ height: 33, width: 33 }}>
                <Close />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack>
          <Typography variant="caption" color="text.secondary">
            Company Bio
          </Typography>
          <Typography sx={{ minWidth: 350, maxWidth: 500 }}>
            {organization.bio}
          </Typography>
        </Stack>
        <Button
          variant={'text'}
          sx={{ fontSize: 12 }}
          onClick={goToSponsorPage}
        >
          View all offers from {organization.name}
        </Button>
      </Stack>
    </Popover>
  );
};
