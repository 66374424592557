import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { DealInfoBlock } from './deal-info-block';
import { ContactButton } from './contact-button';
import { useNavigate } from 'react-router-dom';
import { useSessionUser } from '../hooks/session/useSessionUser';
import { Role } from '../enums/role.enum';
import { useUpdateDealInvestStatus } from '../hooks/deals/use-deal-invest-status';
import { useSnackbar } from '../hooks/util/use-snackbar';
import DealInvestStatusDialog from '../dialogs/deal-invest-status-dialog';
import { DealStatusChip } from './chips/deal-status-chip';
import { DealFundingBar } from './deal-funding-bar';
import { InvestStatusButton } from './invest-status-button';

export const DealsWatchingTable = ({ items = [] }) => {
  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();
  const { render: renderSnackbar, setSnackbar } = useSnackbar();
  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isOrgAdmin = sessionUser?.role === Role.OrgAdmin;
  const isInvestor = sessionUser?.role === Role.Investor;

  const [showDealInvestStatus, setShowDealInvestStatus] = useState(null);

  const updateInvestStatus = useUpdateDealInvestStatus();

  const closeInvestStatusDialog = () => {
    setShowDealInvestStatus(null);
  };

  const handleSubmitInvestStatus = async ({
    dealId,
    status,
    amount,
    reason,
  }) => {
    try {
      updateInvestStatus.mutate({
        dealId,
        status,
        amount,
        reason,
      });
      setSnackbar({ message: 'Investment status has been updated.' });
      closeInvestStatusDialog();
    } catch (e) {
      console.log(e);
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  return (
    <Card>
      {renderSnackbar()}
      <DealInvestStatusDialog
        open={showDealInvestStatus !== null}
        deal={showDealInvestStatus}
        onClose={closeInvestStatusDialog}
        onSubmit={handleSubmitInvestStatus}
      />
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <colgroup>
            <col style={{ width: '30%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {(isSysAdmin || isOrgAdmin) && <TableCell>Status</TableCell>}
              {(isSysAdmin || isOrgAdmin) && <TableCell>Owner</TableCell>}
              <TableCell>Funding</TableCell>
              {isInvestor && (
                <>
                  <TableCell align={'center'}>Contact Status</TableCell>
                  <TableCell align={'center'}>Invest Status</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((deal) => {
              const investStatus = deal?.investments[0];

              const onRowClick = (e) => {
                e.stopPropagation();
                navigate('/deals/' + deal.id + '?backTo=/watchlist');
              };

              const openDealInvestStatus = (e) => {
                e.stopPropagation();
                setShowDealInvestStatus(deal);
              };

              return (
                <TableRow
                  hover
                  key={deal.id}
                  onClick={onRowClick}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <DealInfoBlock deal={deal} />
                  </TableCell>
                  {(isSysAdmin || isOrgAdmin) && (
                    <TableCell>
                      <DealStatusChip deal={deal} />
                    </TableCell>
                  )}
                  {(isSysAdmin || isOrgAdmin) && (
                    <TableCell>{deal?.owner?.name}</TableCell>
                  )}
                  <TableCell>
                    <DealFundingBar deal={deal} />
                  </TableCell>
                  <TableCell
                    align={'center'}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ContactButton deal={deal} setSnackbar={setSnackbar} />
                  </TableCell>
                  <TableCell
                    align={'center'}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {investStatus && (
                      <InvestStatusButton
                        deal={deal}
                        onClick={openDealInvestStatus}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};
