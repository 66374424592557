import { Backdrop, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const LoadingOverlay = ({ ...props }) => {
  const { text } = props;

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <Stack direction="column" spacing={2} alignItems="center">
        {text && <Typography>{text}</Typography>}
        <CircularProgress color="inherit" />
      </Stack>
    </Backdrop>
  );
};
