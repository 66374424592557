import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Alert,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import {
  useDeal,
  useUpdateDeal,
} from '../../../components/hooks/deals/use-deal';
import CircularProgress from '@mui/material/CircularProgress';
import { Role } from '../../../components/enums/role.enum';
import { DealStatus } from '../../../components/enums/deal-status.enum';
import {
  useBlocker,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { DealSaveValidationSchema } from '../../../validation/deal-save-validation-schema';
import { useSessionUser } from '../../../components/hooks/session/useSessionUser';
import { useCreateDealAsset } from '../../../components/hooks/deals/use-deal-asset';
import { DealToFormValues } from '../../../helpers/deal-to-form';
import { DealSubmitValidationSchema } from '../../../validation/deal-submit-validation-schema';
import { capitalize, oxfordComma } from '../../../helpers/strings';
import { DEFAULT_TIMELINE } from '../../../helpers/timeline';
import { DEFAULT_CAP_STRUCTURE } from '../../../helpers/cap-structure';
import { DEFAULT_EXP_RETURNS } from '../../../helpers/exp-returns';
import { EditAsset } from '../../../components/deals/assets/edit-asset';
import { EditTopNav } from '../../../components/deals/edit/edit-top-nav';
import { useSnackbar } from '../../../components/hooks/util/use-snackbar';
import { DealPublished } from '../../../components/deals/view/deal-published';
import { EditDealForm } from '../../../components/deals/edit/edit-form';
import { LeavePageConfirmDialog } from '../../../components/dialogs/leave-page-confirm-dialog';
import { useSubmitDeal } from '../../../components/hooks/deals/use-deal-approval';

const handleBeforeUnload = (e) => {
  e.preventDefault();
  e.returnValue = ''; // Standard for most browsers
  return ''; // For some older browsers
};

export default function EditDeal() {
  const { sessionUser } = useSessionUser();
  const navigate = useNavigate();
  const { id } = useParams();

  let [searchParams, setSearchParams] = useSearchParams();

  const isNew = searchParams.get('isNew');
  const stripe_session_id = searchParams.get('session_id');

  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isOrgAdmin = sessionUser?.role === Role.OrgAdmin;

  const [isSaved, setIsSaved] = useState(true);
  const [lastSavedValues, setLastSavedValues] = useState(null);
  const [isPreview, setPreview] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canSubmitForApproval, setCanSubmitForApproval] = useState(false);
  const [submitForApprovalError, setSubmitForApprovalError] = useState(null);
  const [assetIdxToEdit, setAssetIdxToEdit] = useState(null);

  const updateDeal = useUpdateDeal();
  const createAsset = useCreateDealAsset();
  const { render: renderSnackbar, setSnackbar } = useSnackbar();

  useEffect(() => {
    if (!/\d+/.test(id)) {
      navigate(-1);
    }
  }, [id, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isPreview]);

  const submitDeal = useSubmitDeal();
  const submitDealForApproval = () => {
    try {
      submitDeal.mutate({
        deal,
      });
      setSnackbar({
        message:
          'Subscription has been purchased and your deal is pending review.',
      });
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
    }
  };

  const {
    data: deal,
    isLoading: isLoadingDeal,
    error,
  } = useDeal({
    id: parseInt(id),
  });

  useEffect(() => {
    if (deal && stripe_session_id) {
      submitDealForApproval();
      setSearchParams('');
      // queryParams.delete('session_id');
    }
  }, [deal, stripe_session_id]);

  const formik = useFormik({
    initialValues: {},
    validationSchema: DealSaveValidationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await updateDeal.mutateAsync({
          id: deal?.id,
          updated: { ...values, sectors: values.sectors.join(',') },
        });
        //Show success toast
        // setSnackbarMessage('Your changes have been saved!');
        setIsSaved(true);
      } catch (err) {
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: errObj.message[0] });
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (deal) {
      const newDeal = DealToFormValues(deal);
      const isOwner =
        (isOrgAdmin && deal?.orgId === sessionUser?.orgId) ||
        newDeal?.ownerId === sessionUser?.id;

      if (isSaved) {
        formik.setValues(newDeal);
        setLastSavedValues(newDeal);
      }
      const canEdit =
        isSysAdmin || (isOwner && newDeal.status !== DealStatus.Approved);
      setCanEdit(canEdit);
      if (!canEdit) {
        navigate('/deals/' + id);
      }
    }
  }, [deal, isSaved]);

  const runSubmitValidation = () => {
    DealSubmitValidationSchema.validate(formik.values, { abortEarly: false })
      .then((validDeal) => {
        // console.log(JSON.stringify(validDeal, null, 2));
        setCanSubmitForApproval(true);
        setSubmitForApprovalError(null);
      })
      .catch((e) => {
        const errMsg = e.errors ? oxfordComma(e.errors) : e.message;
        setCanSubmitForApproval(false);
        setSubmitForApprovalError(capitalize(errMsg.toLowerCase()));
      });
  };

  useEffect(() => {
    if (deal) {
      runSubmitValidation();
    }
  }, [formik.values]);

  const isLoading =
    isLoadingDeal || !canEdit || Object.keys(formik.values).length === 0;

  const isChanged =
    lastSavedValues &&
    Object.entries(formik.values).reduce((acc, [key, value]) => {
      const hasChanged =
        JSON.stringify(lastSavedValues[key]) !== JSON.stringify(value);
      if (hasChanged) {
        // console.log('from ' + lastSavedValues[key]);
        // console.log('to ' + value);
        // console.log('key is changed ' + key);
        acc = acc + 1;
      }
      return acc;
    }, 0) > 0;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isChanged && currentLocation.pathname !== nextLocation.pathname,
  );

  const isPendingApproval = deal?.status === DealStatus.PendingReview;

  useEffect(() => {
    setIsSaved(!isChanged);
    if (isChanged) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isChanged]);

  const handleSaveChanges = () => {
    formik.handleSubmit();
  };

  const handleUndo = async () => {
    await formik.setValues(lastSavedValues);
  };

  const goToAddAsset = async () => {
    window.scrollTo(0, 0);
    const assetIds = deal.assets.map((a) => a.id);
    const newDeal = await createAsset.mutateAsync({
      dealId: deal.id,
      createAssetRequest: {
        name: 'New Project',
        timeline: JSON.stringify(DEFAULT_TIMELINE),
        capStructure: JSON.stringify(DEFAULT_CAP_STRUCTURE),
        expReturns: JSON.stringify(DEFAULT_EXP_RETURNS),
      },
    });
    const newAssetIdx = newDeal.assets.findIndex(
      (a) => !assetIds.includes(a.id),
    );
    setAssetIdxToEdit(newAssetIdx);
  };

  const goToEditAsset = (index) => {
    window.scrollTo(0, 0);
    setAssetIdxToEdit(index);
  };

  const closeEditAsset = () => {
    setAssetIdxToEdit(null);
    window.scrollTo(0, 0);
  };

  if (assetIdxToEdit !== null) {
    return (
      <EditAsset
        assetIdx={assetIdxToEdit}
        deal={deal}
        closeEditAsset={closeEditAsset}
      />
    );
  }

  return (
    <Container maxWidth="lg">
      {renderSnackbar()}
      <LeavePageConfirmDialog blocker={blocker} />
      <EditTopNav
        onSave={handleSaveChanges}
        onUndo={handleUndo}
        deal={deal}
        formik={formik}
        isSaving={updateDeal.isLoading}
        isChanged={isChanged}
        isSaved={isSaved}
        isPreview={isPreview}
        setPreview={setPreview}
        isLoading={isLoading || createAsset.isLoading}
        isNew={isNew}
        canSubmitForApproval={canSubmitForApproval}
        onSubmitForApproval={submitDealForApproval}
      />
      <div id="editor"></div>
      {isLoading || !deal ? (
        <Stack alignItems="center" rowGap={2}>
          <Typography variant={'h6'}>Setting up Editor</Typography>
          <CircularProgress />
        </Stack>
      ) : createAsset.isLoading ? (
        <Stack alignItems="center" rowGap={2}>
          <Typography variant={'h6'}>Creating New Project</Typography>
          <CircularProgress />
        </Stack>
      ) : isPreview ? (
        <Card>
          <CardContent>
            <DealPublished values={formik.values} deal={deal} />
          </CardContent>
        </Card>
      ) : (
        <>
          {isPendingApproval && (
            <Alert severity={'success'} sx={{ mb: 2 }}>
              Thank you for submitting, this deal is Pending Review and will be
              listed shortly.
            </Alert>
          )}
          {!isPendingApproval && submitForApprovalError && (
            <Alert severity={'warning'} sx={{ mb: 2 }}>
              <div style={{ whiteSpace: 'pre-line' }}>
                {'Fix the following to Submit:\n\n' +
                  submitForApprovalError +
                  '.'}
              </div>
            </Alert>
          )}
          <Card>
            <CardContent>
              <EditDealForm
                formik={formik}
                deal={deal}
                isNew={isNew}
                isSaving={updateDeal.isLoading}
                setSnackbar={setSnackbar}
                goToAddAsset={goToAddAsset}
                goToEditAsset={goToEditAsset}
              />
            </CardContent>
          </Card>
        </>
      )}
    </Container>
  );
}
