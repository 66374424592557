import { handleMutate } from '../util/use-handle-mutate';
import { useApi } from '../api/use-api';
import { useMutation, useQueryClient } from 'react-query';
import {
  DealQueryKeys,
  enrichDeal,
  setDealInDealQueryData,
  setDealQueryDataToPrevious,
  updateDealInList,
} from './deals-hook-utils';
import { RetryConfig } from '../api/retry-config';

const useAddToDealWatchlist = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ deal }) =>
      request({ url: `/deals/${deal.id}/watchlist`, method: 'POST' }),
    {
      onMutate: async ({ deal }) => {
        const tempWatching = [{ dealId: deal.id }];

        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, deal.id, (deal) => {
              deal.watching = tempWatching;
              return deal;
            }),
        );
        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) =>
            updateDealInList(old, deal.id, (deal) => {
              deal.watching = tempWatching;
              return deal;
            }),
        );
        const prevWatchlist = await handleMutate(
          queryClient,
          DealQueryKeys.watchlist(),
          (old) => [...old, enrichDeal({ ...deal, watching: tempWatching })],
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(deal.id),
          {
            ...deal,
            watching: tempWatching,
          },
        );

        return {
          prevWatchlist,
          prevManage,
          prevSearch,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [
            DealQueryKeys.searches(),
            DealQueryKeys.manage(),
            DealQueryKeys.watchlist(),
          ],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

const useRemoveFromDealWatchlist = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ deal }) =>
      request({ url: `/deals/${deal.id}/watchlist`, method: 'DELETE' }),
    {
      onMutate: async ({ deal }) => {
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, deal.id, (deal) => {
              deal.watching = [];
              return deal;
            }),
        );
        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) =>
            updateDealInList(old, deal.id, (deal) => {
              deal.watching = [];
              return deal;
            }),
        );
        const prevWatchlist = await handleMutate(
          queryClient,
          DealQueryKeys.watchlist(),
          (old) => old.filter((d) => d.id !== deal.id),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(deal.id),
          {
            ...deal,
            watching: [],
          },
        );

        return {
          prevWatchlist,
          prevManage,
          prevSearch,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [
            DealQueryKeys.searches(),
            DealQueryKeys.manage(),
            DealQueryKeys.watchlist(),
          ],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

export { useAddToDealWatchlist, useRemoveFromDealWatchlist };
