import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { toSessionUser } from '../components/hooks/session/useSessionUser';
import InternalServerErrorPage from './error/InternalServerErrorPage';
import { MainLayout } from '../components/layout/main-layout';
import { Main } from './main';
import Register from './register';
import { Role } from '../components/enums/role.enum';

export const Login = () => {
  const {
    user: authUser,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
    error,
  } = useAuth0();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSignup = queryParams.get('am') === 'signup';
  const role = queryParams.get('role') ?? Role.Investor;

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const loginOptions = {
        authorizationParams: {
          redirect_uri: `${window.location.origin}`,
          ...(isSignup ? { screen_hint: 'signup' } : {}),
          'ext-role': role,
          role,
        },
      };
      loginWithRedirect(loginOptions);
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, authUser, isSignup, role]);

  if (isLoading || !authUser) {
    return (
      <Backdrop
        sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (error) {
    const statusCode = error?.statusCode;
    if (!statusCode || statusCode === 500) {
      return <InternalServerErrorPage />;
    }
    if (statusCode === 401) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }

  const sessionUser = toSessionUser(authUser);
  const effectiveRole = sessionUser?.role ?? role;

  return (
    <Routes>
      <Route path="*" element={<MainLayout />}>
        {!sessionUser.is_registered ? (
          <>
            <Route path="registration/:role" element={<Register />} />
            <Route
              path="*"
              element={<Navigate to={'registration/' + effectiveRole} />}
              replace
            />
          </>
        ) : (
          <>
            <Route exact path="*" element={<Main />} />
          </>
        )}
      </Route>
    </Routes>
  );
};
