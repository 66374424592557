import { Card, CardContent, Container, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { usePublicDeal } from '../../components/hooks/deals/use-deal';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useViewPublicDeal } from '../../components/hooks/deals/use-deal-view';
import { TopNavPublic } from '../../components/layout/top-nav-public';
import { DealToFormValues } from '../../helpers/deal-to-form';
import { DealDetailsPublic } from '../../components/deals/deal-details-public';
import ScrollToTop from '../../components/util/scroll-to-top';

export default function DealPublic() {
  const { externalId } = useParams();
  const navigate = useNavigate();
  const [isPageViewTracked, setPageViewTracked] = useState(false);

  const {
    data: deal,
    isLoading,
    error,
  } = usePublicDeal({
    externalId,
  });

  const logPageView = useViewPublicDeal();

  const handlePageView = async () => {
    //Log deal view if user is Investor or Sponsor from other org
    if (deal && !logPageView.isLoading && !isPageViewTracked) {
      const anonymousId = uuid();
      const referrer = document.referrer; // Get the referrer URL
      const userAgent = navigator.userAgent; // Get the user agent

      console.log('Logging Page View');
      console.log('anonymousId: ' + anonymousId);
      console.log(referrer);
      console.log(userAgent);
      await logPageView.mutateAsync({
        anonymousId,
        externalId: deal.externalId,
        referrer,
        userAgent,
      });
      setPageViewTracked(true);
    }
  };

  useEffect(() => {
    handlePageView();
  }, [deal]);

  const handleClick = () => {
    navigate(`/?am=signup&returnTo=/deals/${deal.id}`);
  };

  return (
    <>
      <ScrollToTop />
      <TopNavPublic />
      <Container maxWidth="lg" sx={{ pt: 2 }}>
        {isLoading || !deal ? (
          <Stack alignItems="center" spacing={2}>
            <Typography variant={'h6'}>Loading Deal</Typography>
            <CircularProgress />
          </Stack>
        ) : (
          <Card>
            <CardContent>
              <DealDetailsPublic
                deal={deal}
                values={DealToFormValues(deal)}
                onShowMore={handleClick}
              />
            </CardContent>
          </Card>
        )}
      </Container>
    </>
  );
}
