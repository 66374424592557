export const RetryConfig = () => ({
  retry: (failureCount, errorMsg) => {
    const statusCode = errorMsg?.response?.status ?? errorMsg?.statusCode;
    if (!statusCode) return false;
    switch (statusCode) {
      case 401: {
        console.log(`${statusCode} retry`);
        return failureCount < 3;
      }
      case 400:
      case 404:
      case 409:
      case 500:
        return false;
      default:
        console.log(`${statusCode} retry`);
        return failureCount < 3;
    }
  },
});
