import { alpha } from '@mui/material/styles';

const withAlphas = (color) => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha30: alpha(color.main, 0.3),
    alpha50: alpha(color.main, 0.5),
  };
};

export const neutral = {
  50: '#F8F9FA',
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D2D6DB',
  400: '#9DA4AE',
  500: '#6C737F',
  600: '#4D5761',
  700: '#2F3746',
  800: '#1C2536',
  900: '#111927',
};

export const indigo = withAlphas({
  lightest: '#F5F7FF',
  light: '#EBEEFE',
  main: '#6366F1',
  dark: '#4338CA',
  darkest: '#312E81',
  contrastText: '#FFFFFF',
});

export const gold = withAlphas({
  lightest: '#F5F7FF',
  light: '#EBEEFE',
  main: '#D6A70A',
  dark: '#C09609',
  darkest: '#AB8508',
  contrastText: '#FFFFFF',
});

export const teal = withAlphas({
  lightest: '#E6FFFA',
  light: '#B2F5EA',
  main: '#4FD1C5',
  dark: '#38B2AC',
  darkest: '#285E61',
  contrastText: '#FFFFFF',
});

export const amber = withAlphas({
  lightest: '#FFFBEB',
  light: '#FEEBC8',
  main: '#F59E0B',
  dark: '#D97706',
  darkest: '#B45309',
  contrastText: '#FFFFFF',
});

export const crimson = withAlphas({
  lightest: '#FFE8E8',
  light: '#FEB2B2',
  main: '#E11D48',
  dark: '#BE123C',
  darkest: '#9F1239',
  contrastText: '#FFFFFF',
});

export const royal = withAlphas({
  lightest: '#4169E1',
  light: '#365F91',
  main: '#4169E1',
  dark: '#003366',
  darkest: '#002366',
  contrastText: '#FFFFFF',
});

export const charcoal = withAlphas({
  lightest: '#F2F2F2', // Very light gray for subtle contrast on white backgrounds
  light: '#A8A8A8', // Light charcoal, good for hover states or less prominent buttons
  main: '#505050', // Mid-tone charcoal, ideal for primary action buttons
  dark: '#303030', // Dark charcoal, great for active or selected states
  darkest: '#101010', // Nearly black, perfect for text or icons that need to stand out
  contrastText: '#FFFFFF', // White text for all button states to ensure legibility
});

export const success = withAlphas({
  lightest: '#F0FDF9',
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  darkest: '#134E48',
  contrastText: '#FFFFFF',
});

export const info = withAlphas({
  lightest: '#ECFDFF',
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  darkest: '#164C63',
  contrastText: '#FFFFFF',
});

export const warning = withAlphas({
  lightest: '#FFFAEB',
  light: '#FEF0C7',
  main: '#F79009',
  dark: '#B54708',
  darkest: '#7A2E0E',
  contrastText: '#FFFFFF',
});

export const error = withAlphas({
  lightest: '#FEF3F2',
  light: '#FEE4E2',
  main: '#F04438',
  dark: '#B42318',
  darkest: '#7A271A',
  contrastText: '#FFFFFF',
});
