import { useAuth0 } from '@auth0/auth0-react';
import ApiError from './api-error';
import { v4 as uuid } from 'uuid';

const API_BASE = process.env.REACT_APP_OZD_API_URL;

export const useApi = (isAnonymous, isUpload) => {
  const { getAccessTokenSilently } = useAuth0();

  // getAccessTokenSilently().then(console.log);

  return async ({ url, method = 'GET', body = null }) => {
    const correlationId = uuid();
    const headers = {
      'x-correlation-id': correlationId,
    };
    if (!isAnonymous) {
      const accessToken = await getAccessTokenSilently();
      headers.Authorization = `Bearer ${accessToken}`;
    }
    if (!isUpload) {
      headers['Content-Type'] = 'application/json';
    }

    const config = {
      method: method,
      headers: headers,
    };

    if (isUpload) {
      const formData = new FormData();
      for (const name in body) {
        formData.append(name, body[name]);
      }
      config.body = formData;
    } else {
      config.body = body ? JSON.stringify(body) : null;
    }

    const response = await fetch(API_BASE + url, config);
    const text = await response.text();

    if (!response.ok) {
      if (text) {
        if (typeof text === 'string') {
          throw new ApiError(text, response.status, correlationId);
        } else {
          const error = JSON.parse(text);
          error.url = response.url;
          error.headers = { ...response.headers };
          error.debugInfo = response.debugInfo;
          throw new ApiError(error.error, response.status, correlationId);
        }
      } else {
        throw new ApiError('', response.status, correlationId);
      }
    }
    return text ? JSON.parse(text) : '';
  };
};
