import { Button, DialogActions, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

export const DialogActionBar = ({ ...props }) => {
  const {
    isLoading,
    loadingLabel,
    cancelLabel = 'Cancel',
    submitLabel = 'Submit',
    onCancel,
    onSubmit,
    disabled,
    submitColor = 'primary',
    cancelColor,
  } = props;

  return isLoading ? (
    <DialogActions>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <CircularProgress size={20} />
        <Button disabled color={'primary'}>
          {loadingLabel}
        </Button>
      </Stack>
    </DialogActions>
  ) : (
    <DialogActions>
      <Button onClick={onCancel} color={cancelColor}>
        {cancelLabel}
      </Button>
      <Button
        onClick={onSubmit}
        variant={'contained'}
        disabled={disabled}
        color={submitColor}
      >
        {submitLabel}
      </Button>
    </DialogActions>
  );
};
