import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Add from '@mui/icons-material/Add';
import { DeleteForeverOutlined, EditOutlined } from '@mui/icons-material';
import Photo from '@mui/icons-material/Photo';
import { useDeleteDealAsset } from '../../hooks/deals/use-deal-asset';
import { DeleteConfirmDialog } from '../../dialogs/delete-confirm-dialog';
import { StorageBucket } from '../../../helpers/supabase';
import { neutral } from '../../theme/colors';
import { SectionHeader } from '../../util/section-header';

const IMG_WIDTH = 100;
const IMG_HEIGHT = 60;

export const EditDealAssets = ({
  deal,
  goToAddAsset,
  goToEditAsset,
  setSnackbar,
}) => {
  const [assetToDelete, setAssetToDelete] = useState(null);
  const deleteAsset = useDeleteDealAsset();

  const handleAddAsset = async () => {
    goToAddAsset();
  };

  const handleEditAsset = (assetIndex) => {
    goToEditAsset(assetIndex);
  };

  const handleDeleteAsset = () => {
    try {
      deleteAsset.mutate({
        dealId: deal.id,
        assetId: assetToDelete.id,
      });
      setSnackbar({
        message: assetToDelete.name + ' has been removed from the fund.',
      });
      setAssetToDelete(null);
    } catch (e) {
      setSnackbar({
        message: 'Error: ' + e,
      });
    }
  };

  const handleShowDeleteConfirm = (asset) => {
    setAssetToDelete(asset);
  };

  const handleCloseDeleteConfirm = () => {
    setAssetToDelete(null);
  };

  return (
    <Box sx={{ mb: 4 }}>
      <DeleteConfirmDialog
        open={assetToDelete !== null}
        title={'Remove ' + (assetToDelete?.name || '') + '?'}
        message={'The project will be permanently removed from this fund.'}
        onClose={handleCloseDeleteConfirm}
        handleSubmit={handleDeleteAsset}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <SectionHeader
          title={'Committed Projects'}
          subtitle={'The committed projects in this fund'}
        />
        <div>
          <Tooltip title={'Add a new project to this fund'}>
            <Button
              fullWidth
              size={'small'}
              variant="text"
              onClick={handleAddAsset}
              startIcon={<Add />}
            >
              Add
            </Button>
          </Tooltip>
        </div>
      </Stack>
      <Table padding="none" size={'small'}>
        <TableBody>
          {deal.assets?.length > 0 &&
            deal.assets.map((asset, index) => {
              const hasImg = asset.images?.length;
              const imageSrc =
                hasImg &&
                `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.OFFERING_IMAGES}/${deal.id}/assets/${asset.id}/${asset.images[0].name}?crop=focalpoint&fit=crop&h=${IMG_HEIGHT}&w=${IMG_WIDTH}`;

              const onEdit = () => {
                handleEditAsset(index);
              };
              const onRemove = () => {
                handleShowDeleteConfirm(asset);
              };

              return (
                <TableRow key={asset.id}>
                  <TableCell sx={{ pr: 2 }} align={'center'}>
                    {hasImg ? (
                      <img
                        src={imageSrc}
                        width={IMG_WIDTH}
                        height={IMG_HEIGHT}
                        alt={deal.name}
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: neutral[200],
                          borderRadius: 5,
                        }}
                      >
                        <Photo
                          sx={{
                            width: IMG_WIDTH,
                            height: IMG_HEIGHT,
                          }}
                        />
                      </div>
                    )}
                  </TableCell>
                  <TableCell sx={{ py: 1 }}>
                    <Typography variant={'subtitle1'}>{asset.name}</Typography>
                    {asset.city && asset.state && (
                      <Typography variant={'body1'}>
                        {asset.city}, {asset.state}
                      </Typography>
                    )}
                    <Typography variant={'body2'} color={'text.secondary'}>
                      {asset.sqFt
                        ? `${asset.sqFt.toLocaleString()} sq ft |`
                        : ''}
                      {asset.units
                        ? `${asset.units.toLocaleString()} units`
                        : ''}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-end"
                    >
                      <Tooltip
                        title="Edit the details of this project"
                        placement="top"
                      >
                        <IconButton onClick={onEdit} color="primary">
                          <EditOutlined />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Remove this project from the fund"
                        placement="top"
                      >
                        <IconButton onClick={onRemove} color="error">
                          <DeleteForeverOutlined />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {!deal?.assets?.length && (
        <Alert severity={'warning'}>
          No projects have been added to this deal yet
        </Alert>
      )}
    </Box>
  );
};
