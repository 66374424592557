import { DealStatus } from '../../enums/deal-status.enum';

const getDealStatus = (deal) => {
  return deal.archivedAt
    ? DealStatus.Archived
    : deal.approvedAt
      ? DealStatus.Approved
      : deal.submittedAt
        ? DealStatus.PendingReview
        : DealStatus.Draft;
};

export const enrichDeals = (deals) =>
  deals.map((deal) => ({ ...deal, status: getDealStatus(deal) }));

export const enrichDeal = (deal) => ({ ...deal, status: getDealStatus(deal) });

export const DealQueryKeys = {
  all: ['deals'], //for manage deals
  manage: () => [...DealQueryKeys.all, 'manage'],
  searches: () => [...DealQueryKeys.all, 'search'],
  watchlist: () => [...DealQueryKeys.all, 'watchlist'],
  approvals: () => [...DealQueryKeys.all, 'approvals'],
  search: (filters) => [...DealQueryKeys.searches(), { filters }], //once backend search filters are enabled
  detail: (id) => [...DealQueryKeys.all, 'detail', id],
  public: (externalId) => [...DealQueryKeys.all, 'public', externalId],
};

export const setDealQueryDataToPrevious = (
  queryClient,
  { prevManage, prevApprovals, prevWatchlist, prevSearch, prevDetail },
) => {
  if (prevManage) {
    queryClient.setQueryData(DealQueryKeys.manage(), prevManage);
  }
  if (prevApprovals) {
    queryClient.setQueryData(DealQueryKeys.approvals(), prevApprovals);
  }
  if (prevWatchlist) {
    queryClient.setQueryData(DealQueryKeys.watchlist(), prevWatchlist);
  }
  if (prevSearch) {
    queryClient.setQueryData(DealQueryKeys.searches(), prevSearch);
  }
  if (prevDetail) {
    queryClient.setQueryData(DealQueryKeys.detail(prevDetail.id), prevDetail);
  }
};

export const setDealInDealQueryData = (queryClient, listQueryKeys, deal) => {
  const newDeal = enrichDeal(deal);

  for (let i = 0; i < listQueryKeys.length; i++) {
    const queryKey = listQueryKeys[i];
    queryClient.setQueryData(queryKey, (old) => {
      if (!old) return;
      const copy = [...old];
      const idx = old.findIndex((d) => d.id === deal.id);
      if (idx > -1) {
        copy[idx] = newDeal;
      }
      return copy;
    });
  }

  //Always update the deal detail
  queryClient.setQueryData(DealQueryKeys.detail(deal.id), newDeal);
};

export const updateDealInList = (old, dealId, updateFunction) => {
  if (!old) return;
  const copy = [...old];
  const dealIdx = old.findIndex((d) => d.id === dealId);
  if (dealIdx > -1) {
    copy[dealIdx] = updateFunction({ ...old[dealIdx] });
  }
  return copy;
};
