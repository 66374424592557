import { Chip, Tooltip } from '@mui/material';
import React from 'react';

export const DealPaidChip = () => (
  <Tooltip placement={'bottom'} title={'This deal has an active subscription'}>
    <Chip
      sx={{ cursor: 'default' }}
      label={'$'}
      variant={'contained'}
      color={'success'}
    />
  </Tooltip>
);
