import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DialogActionBar } from './dialog-action-bar';
import { QualificationForm } from '../users/qualification-form';

const UserQualifyDialog = (props) => {
  const { open, qualification, onClose, onSubmit, isLoading } = props;

  const initialValues = {
    isAccreditedInvestor: qualification?.isAccreditedInvestor || false,
    isQualifiedClient: qualification?.isQualifiedClient || false,
    isQualifiedPurchaser: qualification?.isQualifiedPurchaser || false,
    isRIA: qualification?.isRIA || false,
    isNotQualified: qualification?.isNotQualified || false,
    submit: null,
  };

  const validationShape = {};

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await onSubmit({ ...values });
      } catch (err) {
        console.log(err);
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ [errObj.field]: errObj.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleSubmit = (e) => {
    e?.stopPropagation();
    formik.submitForm();
  };

  const handleClose = (e) => {
    e?.stopPropagation();
    onClose();
  };

  useEffect(() => {
    formik.setValues(initialValues);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'sm'}
      fullWidth
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>Are you a Qualified Investor?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Certain sponsors have requested that only prospective investors who
          qualify as “Accredited Investors”, “Qualified Clients” and/or
          “Qualified Purchasers” consider an investment in the Opportunity Zone
          Deals posted on this website. Please click, read, and accept all that
          apply.
        </DialogContentText>
        <QualificationForm formik={formik} />
      </DialogContent>
      <DialogActionBar
        isLoading={isLoading}
        loadingLabel={'Saving...'}
        submitLabel={'Save Answers'}
        disabled={
          formik.isSubmitting || formik.values.message === '' || !formik.isValid
        }
        onCancel={handleClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default UserQualifyDialog;
