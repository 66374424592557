import {
  Button,
  Checkbox,
  debounce,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSessionUser } from '../hooks/session/useSessionUser';
import { useApi } from '../hooks/api/use-api';
import { phoneRegExp } from '../../helpers/is-valid-phone';
import AddressAutocompleteTextField from '../maps/address-autocomplete-textfield';
import MapView from '../maps/map-view';
import { AddressText } from '../maps/address-text';

export const RegisterOrganizationForm = (props) => {
  const apiRequest = useApi();
  const { onSubmit, isSubmitting } = props;
  const [validating, setValidating] = useState(false);
  const { sessionUser } = useSessionUser();
  const initialName =
    sessionUser?.name && sessionUser?.name.indexOf('@') === -1
      ? sessionUser?.name
      : '';

  const [isPoc, setIsPoc] = useState(true);
  const [nameError, setNameError] = useState(null);

  const validateOrganizationNameDebounced = debounce(async (value) => {
    if (!value || value.length < 5) {
      setNameError('Sponsor Name must be at least 5 characters');
      return;
    }
    setValidating(true);
    const isValid = await apiRequest({
      url: `/registration/organizations/validate`,
      method: 'POST',
      body: { name: value },
    });
    setValidating(false);
    if (!isValid) {
      setNameError('Sponsor Name is already in use');
    } else {
      setNameError(null);
    }
  }, 500); // Adjust the delay as needed

  const initialValues = {
    name: '',
    pocName: initialName,
    pocTitle: '',
    email: sessionUser?.email || '',
    bio: '',
    phone: '',
    website: '',
    linkedIn: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    latlng: null,
    user: {
      name: initialName,
      email: sessionUser?.email || '',
      phone: '',
      linkedIn: '',
    },
    terms: false,
    submit: null,
  };

  const validationShape = {
    name: Yup.string().max(255).required('Sponsor Name is required'),
    bio: Yup.string(),
    pocName: Yup.string().max(255).required('Primary Contact Name is required'),
    pocTitle: Yup.string()
      .max(255)
      .required('Primary Contact Title is required'),
    email: Yup.string().email().required('Primary Contact Email is required'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Primary Contact Phone number is not valid')
      .required('Primary Contact Phone number is required')
      .max(15),
    website: Yup.string().url('Website must be a valid URL').max(255),
    linkedIn: Yup.string().url('LinkedIn Profile must be a valid URL').max(255),
    address1: Yup.string().max(255).required('Address Line 1 is required'),
    latlng: Yup.string().required('Address is required'),
    terms: Yup.boolean().oneOf([true], 'You must agree to the terms'),
    user: Yup.object().shape({
      name: Yup.string().max(255).required('Your name is required'),
      phone: Yup.string()
        .matches(phoneRegExp, 'Your Phone number is not valid')
        .required('Your Phone number is required')
        .max(15),
      linkedIn: Yup.string()
        .url('LinkedIn Profile must be a valid URL')
        .max(255),
    }),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, helpers) => {
      try {
        onSubmit(values);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const isTouched = Object.keys(formik.touched).length > 0;
  const latlng = formik.values.latlng ? JSON.parse(formik.values.latlng) : null;

  const handleChangePoc = () => {
    const newPoc = !isPoc;
    setIsPoc(newPoc);
    if (newPoc) {
      formik.setFieldValue('pocName', formik.values.user?.name, true);
      formik.setFieldValue('phone', formik.values.user?.phone, true);
    }
  };

  const handleAdminNameChange = (e) => {
    const newName = e.target.value;
    if (isPoc) {
      formik.setFieldValue('pocName', newName, true);
    }
    formik.setFieldValue('user.name', newName, true);
  };
  const handleAdminPhoneChange = (e) => {
    const newPhone = e.target.value;
    if (isPoc) {
      formik.setFieldValue('phone', newPhone, true);
    }
    formik.setFieldValue('user.phone', newPhone, true);
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    if (formik.values.name !== value) {
      formik.setFieldValue('name', value, true);
      validateOrganizationNameDebounced(value);
    }
  };
  const handleNameBlur = (e) => {
    const { value } = e.target;
    formik.setFieldTouched('name', true);
    if (formik.values.name !== value) {
      validateOrganizationNameDebounced(value);
    }
  };

  const handleCheckboxChange = (e) => {
    formik.setFieldValue('terms', e.target.checked);
  };

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        <Grid xs={12} md={12}>
          <Typography variant={'h5'} sx={{ py: 1 }} color={'text.gold'}>
            Complete your Sponsor Administrator Profile
          </Typography>
          <Typography variant={'body2'} color="text.secondary">
            The Sponsor Administrator can enter and change any information in
            your account.
          </Typography>
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            error={!!(formik.touched.user?.name && formik.errors.user?.name)}
            fullWidth
            helperText={formik.touched.user?.name && formik.errors.user?.name}
            label="Your Name"
            name="user.name"
            required
            onBlur={formik.handleBlur}
            onChange={handleAdminNameChange}
            value={formik.values.user?.name}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            error={!!(formik.touched.user?.phone && formik.errors.user?.phone)}
            fullWidth
            helperText={formik.touched.user?.phone && formik.errors.user?.phone}
            label="Your Phone Number"
            name="user.phone"
            required
            onBlur={formik.handleBlur}
            onChange={handleAdminPhoneChange}
            type="phone"
            value={formik.values.user?.phone}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            fullWidth
            helperText={
              'Your email is linked to your account and cannot be changed.'
            }
            label="Your Email"
            name="user.email"
            disabled
            required
            value={sessionUser?.email}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            error={
              !!(formik.touched.user?.linkedIn && formik.errors.user?.linkedIn)
            }
            fullWidth
            helperText={
              formik.touched.user?.linkedIn && formik.errors.user?.linkedIn
                ? formik.errors.user?.linkedIn
                : 'This helps us better understand your background.'
            }
            label="Your LinkedIn Profile URL"
            name="user.linkedIn"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.user?.linkedIn}
          />
        </Grid>
        <Grid xs={12} md={12} sx={{ mt: 4 }}>
          <Typography variant={'h5'} sx={{ py: 1 }} color={'text.gold'}>
            Enter your Business Information
          </Typography>
          <Typography variant={'body2'} color="text.secondary">
            Information about your business that is visible to Investors and
            other Sponsors.
          </Typography>
        </Grid>
        <Grid xs={12} md={12}>
          <TextField
            error={!!(formik.touched.name && formik.errors.name) || !!nameError}
            fullWidth
            helperText={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : nameError
                  ? nameError
                  : 'This is your public business name and must be at least 5 characters'
            }
            label="Business Name"
            name="name"
            onBlur={handleNameBlur}
            onChange={handleNameChange}
            value={formik.values.name}
            required
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            multiline
            rows={5}
            error={!!(formik.touched.bio && formik.errors.bio)}
            fullWidth
            helperText={
              formik.touched.bio && formik.errors.bio
                ? formik.errors.bio
                : 'Not required for registration, but you will need a bio to list a deal.'
            }
            placeholder={
              'Enter a 1-2 sentence company biography including years of experience and areas of focus'
            }
            label="Company Bio"
            name="bio"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bio || ''}
          />
        </Grid>

        <Grid xs={12} md={12}>
          <AddressAutocompleteTextField
            formik={formik}
            fullWidth
            required
            label="Address"
            name="address1"
          />
          {latlng && (
            <Stack sx={{ mt: 2 }}>
              <MapView
                markers={[{ lat: latlng.lat, lng: latlng.lng }]}
                height={150}
              />
              <AddressText
                address1={formik.values.address1}
                address2={formik.values.address2}
                city={formik.values.city}
                state={formik.values.state}
                zip={formik.values.zip}
              />
            </Stack>
          )}
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            error={!!(formik.touched.website && formik.errors.website)}
            fullWidth
            helperText={formik.touched.website && formik.errors.website}
            label="Business Website"
            name="website"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.website}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            error={!!(formik.touched.linkedIn && formik.errors.linkedIn)}
            fullWidth
            helperText={
              formik.touched.linkedIn && formik.errors.linkedIn
                ? formik.errors.linkedIn
                : 'This helps us speed up your business verification.'
            }
            label="Business LinkedIn Profile URL"
            name="linkedIn"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.linkedIn}
          />
        </Grid>

        <Grid xs={12} md={12} sx={{ mt: 4 }}>
          <Typography variant={'h5'} sx={{ py: 1 }} color={'text.gold'}>
            Designate a Primary Contact
          </Typography>
          <Typography variant={'body2'} color="text.secondary">
            The Primary Contact will be notified when Investors show interest in
            your Deals.
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={isPoc} />}
              value={true}
              label="The Primary Contact is the same as the Sponsor Administrator"
              onChange={handleChangePoc}
            />
          </FormGroup>
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            error={!!(formik.touched.pocName && formik.errors.pocName)}
            fullWidth
            helperText={formik.touched.pocName && formik.errors.pocName}
            label="Primary Contact Name"
            name="pocName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.pocName}
            required
            disabled={isPoc}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            fullWidth
            error={!!(formik.touched.phone && formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            label="Primary Contact Phone"
            name="phone"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phone}
            required
            disabled={isPoc}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            fullWidth
            error={!!(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            label="Primary Contact Email"
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
            required
          />
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            error={!!(formik.touched.pocTitle && formik.errors.pocTitle)}
            fullWidth
            helperText={formik.touched.pocTitle && formik.errors.pocTitle}
            label="Primary Contact Title"
            name="pocTitle"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.pocTitle}
            required
          />
        </Grid>
      </Grid>
      {formik.errors.submit && (
        <Typography color="error" sx={{ mt: 3 }} variant="body2">
          {formik.errors.submit}
        </Typography>
      )}
      <Stack
        sx={{ pt: 2, borderTop: '1px solid lightgrey' }}
        alignItems={'center'}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.terms}
              value={formik.values.terms}
              onChange={handleCheckboxChange}
            />
          }
          label={
            <Typography variant={'body2'} color={'text.secondary'}>
              I have read and agree to the{' '}
              <a
                href="https://www.opportunityzonedeals.com/terms-of-use"
                target="_blank"
              >
                Terms of Use
              </a>
              {', '}
              <a
                href="https://www.opportunityzonedeals.com/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </a>
              {', and '}
              <a
                href="https://www.opportunityzonedeals.com/disclosures"
                target="_blank"
              >
                Disclaimers
              </a>{' '}
            </Typography>
          }
        />
        <Button
          fullWidth
          size="large"
          sx={{ mt: 3 }}
          type="submit"
          variant="contained"
          disabled={isSubmitting || !formik.isValid || validating || nameError}
        >
          {validating ? 'Checking Sponsor Name' : 'Create your Account'}
        </Button>
      </Stack>
    </form>
  );
};
