import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import React from 'react';
import Stack from '@mui/material/Stack';
import MapView from '../../maps/map-view';
import { AddressText } from '../../maps/address-text';
import { DealType } from '../../enums/deal-type.enum';
import { DealHeader } from './deal-header';
import { SectionHeader } from '../../util/section-header';
import { DealExpReturns } from './deal-exp-returns';
import { DealAssets } from '../assets/deal-assets';
import { DealCapStructure } from './deal-cap-structure';
import { DealTimeline } from './deal-timeline';
import HTMLReactParser from 'html-react-parser';
import { DealPublishedKeyFields } from './deal-published-key-fields';

export const DealPublished = ({ values, deal, isPublic, onPublicClick }) => {
  const isFund = deal?.type === DealType.Fund;
  const isSale = deal?.type === DealType.Sale;
  const latlng = values.latlng ? JSON.parse(values.latlng) : null;

  return (
    <Stack
      sx={{
        pt: 2,
        pb: 10,
      }}
    >
      <Grid container spacing={4}>
        <DealHeader deal={deal} values={values} />
        {isSale ? (
          <Grid xs={6}>
            <SectionHeader
              title={(isFund ? 'Fund' : 'Deal') + ' Summary'}
              mb={0}
            />
            <Stack>{HTMLReactParser(values.description || '')}</Stack>
          </Grid>
        ) : (
          <Grid xs={6}>
            <SectionHeader
              title={'Expected Returns'}
              subtitle={'As projected by Sponsor'}
            />
            <DealExpReturns initialValue={values.expReturns} />
          </Grid>
        )}
        <Grid xs={6}>
          <Grid container>
            <DealPublishedKeyFields values={values} />
          </Grid>
        </Grid>
        {!isSale && (
          <Grid xs={12}>
            <SectionHeader
              title={(isFund ? 'Fund' : 'Deal') + ' Summary'}
              mb={0}
            />
            <Stack>{HTMLReactParser(values.description || '')}</Stack>
          </Grid>
        )}
        <Grid xs={12}>
          <Divider />
        </Grid>
        {isPublic ? (
          <Grid xs={12} align={'center'}>
            <Button
              size={'large'}
              variant="text"
              color={'info'}
              onClick={onPublicClick}
            >
              See More
            </Button>
          </Grid>
        ) : (
          <>
            {!isFund && latlng && (
              <Grid xs={isSale ? 12 : 6}>
                <MapView markers={[{ lat: latlng.lat, lng: latlng.lng }]} />
                <AddressText
                  address1={values.address1}
                  address2={values.address2}
                  city={values.city}
                  state={values.state}
                  zip={values.zip}
                />
              </Grid>
            )}
            {isFund && deal.assets?.length > 0 && (
              <Grid xs={12}>
                <DealAssets deal={deal} />
              </Grid>
            )}
            {!isFund && !isSale && (
              <Grid xs={6}>
                <SectionHeader
                  title={'Timeline'}
                  subtitle={'As projected by Sponsor'}
                />
                <DealTimeline initialValue={values.timeline} />
              </Grid>
            )}
          </>
        )}
        {!isFund && !isSale && (
          <Grid xs={12}>
            <Divider />
          </Grid>
        )}
        {!isPublic && !isFund && !isSale && (
          <Grid xs={6}>
            <SectionHeader
              title={'Capital Structure'}
              subtitle={'As projected by Sponsor'}
            />
            <DealCapStructure initialValue={values.capStructure} />
          </Grid>
        )}
      </Grid>
      <Stack direction={'row'} sx={{ mt: 10 }} justifyContent={'center'}>
        <Typography variant={'body1'} color={'text.secondary'}>
          All information is provided by the Sponsor and has not been
          investigated or verified by OZD.
        </Typography>
      </Stack>
    </Stack>
  );
};
