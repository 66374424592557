import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DialogActionBar } from './dialog-action-bar';

const DealRejectDialog = (props) => {
  const { open, title, onClose, onSubmit, multiple, isLoading } = { ...props };

  const initialValues = {
    message: '',
    submit: null,
  };

  const validationShape = {
    message: Yup.string().required('Rejection reason is required'),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await onSubmit(values.message);
      } catch (err) {
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ [errObj.field]: errObj.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleMessageChange = (e) => {
    formik.setFieldValue('message', e.target.value, true);
  };

  const handleSubmit = () => {
    formik.submitForm();
  };

  useEffect(() => {
    formik.resetForm();
  }, [title]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reject Deal{multiple ? 's' : ''}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant={'h5'}>{title}</Typography>
          <Typography variant={'body1'}>
            Rejecting this deal will move it back to Draft status. The owner of
            the deal will be notified of the rejection and the reason you
            provide below.
          </Typography>
          <TextField
            multiline
            rows={5}
            error={!!(formik.touched.message && formik.errors.message)}
            fullWidth
            required
            helperText={formik.touched.message && formik.errors.message}
            placeholder={
              'Enter a detailed explanation for why this deal is rejected...'
            }
            label="Reason for rejection"
            name="message"
            onBlur={formik.handleBlur}
            onChange={handleMessageChange}
            value={formik.values.message}
          />
        </Stack>
      </DialogContent>
      <DialogActionBar
        isLoading={isLoading}
        loadingLabel={'Rejecting...'}
        submitLabel={'Reject'}
        onCancel={onClose}
        onSubmit={handleSubmit}
        disabled={
          formik.isSubmitting || formik.values.message === '' || !formik.isValid
        }
        submitColor={'error'}
      />
    </Dialog>
  );
};

export default DealRejectDialog;
