import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import { StateList, StateMap } from '../../helpers/get-states';
import { DealSector } from '../enums/deal-sector.enum';

export const SettingsDealFilters = ({
  states,
  sectors,
  onSubmit,
  isLoading,
}) => {
  const [stateFilter, setStateFilter] = useState(states || []);
  const [sectorFilter, setSectorFilter] = useState(sectors || []);

  const handleStateFilterChange = (event) => {
    setStateFilter(event.target.value);
  };

  const handleRemoveState = (state) => {
    setStateFilter(stateFilter.filter((s) => s !== state));
  };

  const handleSectorFilterChange = (event) => {
    setSectorFilter(event.target.value);
  };

  const handleRemoveSector = (sector) => {
    setSectorFilter(sectorFilter.filter((s) => s !== sector));
  };

  const handleSubmit = () => {
    onSubmit(stateFilter, sectorFilter);
  };

  const isStatesChanged =
    states.sort().join(',') !== stateFilter.sort().join(',');
  const isSectorsChanged =
    sectors.sort().join(',') !== sectorFilter.sort().join(',');
  const isChanged = isStatesChanged || isSectorsChanged;

  return (
    <Card>
      <CardHeader
        subheader="Filter Email Notifications and Search to deals that fall within these categories."
        title="Deal Filters"
      />
      <Divider />
      <CardContent>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <FormControl fullWidth>
            <InputLabel id="state-label" shrink={true}>
              States
            </InputLabel>
            <Select
              labelId="state-label"
              id="demo-simple-select"
              value={stateFilter}
              label="States"
              onChange={handleStateFilterChange}
              multiple
              displayEmpty
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {stateFilter.length > 0 ? (
                    stateFilter.map((state) => (
                      <Chip
                        sx={{ mr: 1, mb: 1 }}
                        key={state}
                        label={StateMap[state]}
                        variant="outlined"
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={(e) => handleRemoveState(state)}
                      />
                    ))
                  ) : (
                    <Chip
                      sx={{ mr: 1, mb: 1 }}
                      key={'All'}
                      label={'All States Included'}
                      variant="contained"
                    />
                  )}
                </Box>
              )}
            >
              {StateList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox
                    checked={
                      stateFilter.findIndex((s) => s === option.value) > -1
                    }
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="sector-label" shrink={true}>
              {'Sectors'}
            </InputLabel>
            <Select
              labelId="sector-label"
              value={sectorFilter || ''}
              label={'Sectors'}
              onChange={handleSectorFilterChange}
              multiple
              displayEmpty
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {sectorFilter.length > 0 ? (
                    sectorFilter.map((sector) => (
                      <Chip
                        sx={{ mr: 1, mb: 1 }}
                        key={sector}
                        label={DealSector[sector]}
                        variant="outlined"
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={(e) => handleRemoveSector(sector)}
                      />
                    ))
                  ) : (
                    <Chip
                      sx={{ mr: 1, mb: 1 }}
                      key={'All'}
                      label={'All Sectors Included'}
                      variant="contained"
                    />
                  )}
                </Box>
              )}
            >
              {Object.keys(DealSector).map((value) => (
                <MenuItem key={value} value={value}>
                  <Checkbox
                    checked={sectorFilter.findIndex((s) => s === value) > -1}
                  />
                  <ListItemText primary={DealSector[value]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </CardContent>
      <Divider />
      {isLoading ? (
        <CardActions sx={{ justifyContent: 'flex-end', my: 1 }}>
          <CircularProgress size={20} />
          <Button disabled color={'primary'}>
            Saving...
          </Button>
        </CardActions>
      ) : (
        <CardActions sx={{ justifyContent: 'flex-end', my: 1 }}>
          <Button
            variant="contained"
            disabled={isLoading || !isChanged}
            onMouseUp={handleSubmit}
          >
            Save Changes
          </Button>
        </CardActions>
      )}
    </Card>
  );
};
