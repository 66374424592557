import { useApi } from '../api/use-api';
import { useQuery } from 'react-query';
import { RetryConfig } from '../api/retry-config';

const queryKey = 'subscriptions';

const useSubscriptions = ({ options }) => {
  const request = useApi();
  return useQuery(
    [queryKey],
    async () => request({ url: `/payments/subscriptions` }),
    {
      ...RetryConfig(),
      ...options,
    },
  );
};

const useCustomerPortal = ({ options = null }) => {
  const request = useApi();
  return useQuery(
    'portal',
    async () =>
      request({
        url: '/payments/customer-portal?redirectUrl=subscriptions',
      }),
    {
      ...RetryConfig(),
      ...options,
    },
  );
};

export { useSubscriptions, useCustomerPortal };
