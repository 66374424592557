import {
  Alert,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Refresh from '@mui/icons-material/Refresh';
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { DEFAULT_TIMELINE } from '../../../helpers/timeline';
import DealEditTimelineMilestoneDialog from '../../dialogs/deal-edit-timeline-milestone-dialog';
import DealDeleteTimelineMilestoneDialog from '../../dialogs/deal-delete-timeline-milestone-dialog';
import { SectionHeader } from '../../util/section-header';
import { format } from 'date-fns';

export const EditTimeline = ({ initialValue, onChange }) => {
  const [milestones, setMilestones] = useState(
    initialValue ? JSON.parse(initialValue) : [],
  );
  const [milestoneToEdit, setMilestoneToEdit] = useState(null);
  const [milestoneToDelete, setMilestoneToDelete] = useState(null);

  const handleChange = () => {
    onChange(JSON.stringify(milestones));
  };

  useEffect(() => {
    handleChange();
  }, [milestones]);

  useEffect(() => {
    setMilestones(initialValue ? JSON.parse(initialValue) : []);
  }, [initialValue]);

  const handleRestoreDefault = () => {
    setMilestones(DEFAULT_TIMELINE);
  };

  const closeEditMilestone = () => {
    setMilestoneToEdit(null);
  };

  const closeDeleteMilestone = () => {
    setMilestoneToDelete(null);
  };

  const openEditMilestone = ({ name, month, year, idx }) => {
    setMilestoneToEdit({ name, month, year, idx });
  };

  const openAddMilestone = () => {
    setMilestoneToEdit({
      name: 'New Milestone',
      idx: -1,
    });
  };

  const openDeleteMilestone = ({ name, month, year, idx }) => {
    setMilestoneToDelete({ name, month, year, idx });
  };

  const handleSaveMilestone = (updatedMilestone) => {
    const { name, month, year, idx } = updatedMilestone;

    if (idx === -1) {
      setMilestones([...milestones, { name, month, year }]);
    } else {
      const newMilestones = [...milestones];
      newMilestones[idx].name = name;
      newMilestones[idx].month = month;
      newMilestones[idx].year = year;
      setMilestones(newMilestones);
    }
    closeEditMilestone();
  };

  const handleDeleteMilestone = () => {
    const newMilestones = milestones.filter(
      (ms, idx) => idx !== milestoneToDelete.idx,
    );
    setMilestones(newMilestones);
    closeDeleteMilestone();
  };

  return (
    <>
      <DealEditTimelineMilestoneDialog
        open={milestoneToEdit !== null}
        milestone={milestoneToEdit}
        onClose={closeEditMilestone}
        onSubmit={handleSaveMilestone}
      />
      <DealDeleteTimelineMilestoneDialog
        open={milestoneToDelete !== null}
        title={milestoneToDelete?.name}
        onClose={closeDeleteMilestone}
        onSubmit={handleDeleteMilestone}
      />
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <SectionHeader
          title={'Timeline'}
          subtitle={'The key milestones of the project'}
        />
        <Stack direction="row" spacing={1}>
          <Tooltip title={'Restore the Timeline to the OZD default'}>
            <Button
              size={'small'}
              onClick={handleRestoreDefault}
              startIcon={<Refresh />}
            >
              Restore Default
            </Button>
          </Tooltip>
          <Tooltip title="Add Milestone" placement="top">
            <Button
              size={'small'}
              onClick={openAddMilestone}
              startIcon={<AddOutlined />}
            >
              Add
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack sx={{ mt: 1 }}>
        {!milestones.length ? (
          <Alert severity={'warning'}>
            No milestones have been added to this timeline yet
          </Alert>
        ) : (
          <Table padding="none" size={'small'}>
            <TableBody>
              {milestones
                .sort(
                  (
                    { month: m1 = 12, year: y1 = 9999 },
                    { month: m2 = 12, year: y2 = 9999 },
                  ) => {
                    if (y1 === y2) {
                      return m1 > m2 ? 1 : m1 < m2 ? -1 : 0;
                    }
                    return y1 > y2 ? 1 : y1 < y2 ? -1 : 0;
                  },
                )
                .map(({ name, month, year }, idx) => {
                  const monthStr = month
                    ? format(new Date(2023, parseInt(month) - 1, 1), 'MMM')
                    : null;

                  const handleEdit = () => {
                    openEditMilestone({ name, month, year, idx });
                  };

                  const handleRemove = () => {
                    openDeleteMilestone({ name, month, year, idx });
                  };

                  return (
                    <TableRow key={name}>
                      <TableCell>
                        <b>{name}</b>
                      </TableCell>
                      <TableCell align={'right'}>
                        {monthStr || '[Month]'} {year || '[Year]'}
                      </TableCell>
                      <TableCell align={'center'}>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="flex-end"
                        >
                          <Tooltip title="Edit Milestone" placement="top">
                            <IconButton onClick={handleEdit} color="primary">
                              <EditOutlined />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove Milestone" placement="top">
                            <IconButton onClick={handleRemove} color="error">
                              <DeleteForeverOutlined />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
      </Stack>
    </>
  );
};
