import { Alert, Grid } from '@mui/material';
import { DealHeaderPublic } from '../deals/view/deal-header-public';
import { DealToFormValues } from '../../helpers/deal-to-form';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DealHeader } from '../deals/view/deal-header';

export const OrganizationDeals = ({ deals, isPublic }) => {
  const navigate = useNavigate();

  if (deals.length > 0) {
    return deals.map((deal, index) => {
      const link = isPublic
        ? '/public/deals/' + deal.externalId
        : '/deals/' + deal.id;

      const handleView = (e) => {
        e.stopPropagation();
        navigate(link);
      };

      const values = DealToFormValues(deal);

      return (
        <Grid
          item
          xs={6}
          onClick={handleView}
          key={deal.id}
          sx={{ cursor: 'pointer' }}
        >
          {isPublic ? (
            <DealHeaderPublic deal={values} isSponsorPage />
          ) : (
            <DealHeader values={values} deal={deal} isSponsorPage />
          )}
        </Grid>
      );
    });
  }

  return <Alert severity={'warning'}>This Sponsor has no listed deals.</Alert>;
};
