import { Alert, Card, Container, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useManageDeals } from '../../components/hooks/deals/use-deals';
import {
  useArchiveDeal,
  useDeleteDeal,
} from '../../components/hooks/deals/use-deal';
import { useSnackbar } from '../../components/hooks/util/use-snackbar';
import DealDeleteDialog from '../../components/dialogs/deal-delete-dialog';
import { DealsArchivedTable } from '../../components/deals/table-archived';

export default function Archived() {
  const { render: renderSnackbar, setSnackbar } = useSnackbar();

  const [toDelete, setToDelete] = useState(null);

  const { data: deals, isLoading } = useManageDeals();
  const deleteDeal = useDeleteDeal();
  const archiveDeal = useArchiveDeal();

  const closeDelete = () => {
    setToDelete(null);
  };

  const handleDelete = async () => {
    try {
      deleteDeal.mutate({ id: toDelete.id });
      setSnackbar({ message: `${toDelete.name} has been deleted.` });
      closeDelete();
    } catch (e) {
      setSnackbar({ message: `Error: ${e}` });
    }
  };

  const openDelete = (deal) => {
    setToDelete(deal);
  };

  const handleUnarchive = async (deal) => {
    try {
      archiveDeal.mutate({
        dealId: deal.id,
        isUndo: true,
      });
      setSnackbar({ message: `${deal.name} has been unarchived.` });
    } catch (e) {
      setSnackbar({ message: `Error: ${e}` });
    }
  };

  const archivedDeals = deals?.filter((d) => !!d.archivedAt);

  return (
    <Container maxWidth="xl">
      {renderSnackbar()}
      <DealDeleteDialog
        open={toDelete !== null}
        title={toDelete?.name}
        onClose={closeDelete}
        onSubmit={handleDelete}
      />
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Typography variant="h4">Archived Deals</Typography>
        </Stack>
        <Card>
          {isLoading ? (
            <Stack direction="row" justifyContent="center" spacing={1}>
              <CircularProgress size={30} sx={{ my: 2 }} />
            </Stack>
          ) : archivedDeals?.length > 0 ? (
            <DealsArchivedTable
              items={archivedDeals}
              onDeleteClick={openDelete}
              onUnarchiveClick={handleUnarchive}
            />
          ) : (
            <Alert severity="info" sx={{ width: '100%' }}>
              <Typography variant={'body2'}>
                There are currently no archived deals in your account.
              </Typography>
            </Alert>
          )}
        </Card>
      </Stack>
    </Container>
  );
}
