import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Unstable_Grid2 as Grid,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import CircularProgress from '@mui/material/CircularProgress';
import { useSessionUser } from '../hooks/session/useSessionUser';
import { phoneRegExp } from '../../helpers/is-valid-phone';

export const SettingsContact = ({ userProfile, onSubmit, isLoading }) => {
  const { sessionUser } = useSessionUser();

  const initialValues = {
    name: userProfile?.name,
    phone: userProfile?.phone,
    linkedIn: userProfile?.linkedIn,
    submit: null,
  };

  const validationShape = {
    name: Yup.string().max(255).required('Name is required'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .max(15),
    linkedIn: Yup.string()
      .url('LinkedIn Profile must be a valid URL')
      .max(255)
      .nullable(true),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        onSubmit(values);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const isTouched = Object.keys(formik.touched).length > 0;
  const isChanged =
    Object.entries(formik.values).reduce((acc, [key, value]) => {
      const hasChanged =
        key !== 'submit' &&
        JSON.stringify(userProfile[key]) !== JSON.stringify(value);
      if (hasChanged) {
        acc = acc + 1;
      }
      return acc;
    }, 0) > 0;

  useEffect(() => {
    if (userProfile) {
      formik.setValues({
        name: userProfile.name,
        phone: userProfile.phone,
        linkedIn: userProfile.linkedIn,
        submit: null,
      });
    }
  }, [userProfile]);

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader
          subheader="Update your account profile information"
          title="Account Profile"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <TextField
                error={!!(formik.touched.name && formik.errors.name)}
                fullWidth
                helperText={formik.touched.name && formik.errors.name}
                label="Your Name"
                name="name"
                required
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                error={!!(formik.touched.phone && formik.errors.phone)}
                fullWidth
                helperText={formik.touched.phone && formik.errors.phone}
                label="Your Phone Number"
                name="phone"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="phone"
                value={formik.values.phone || ''}
              />
            </Grid>

            <Grid xs={6}>
              <TextField
                fullWidth
                helperText={
                  'Your email is linked to your account and cannot be changed.'
                }
                label="Your Email"
                name="email"
                disabled
                value={sessionUser?.email}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                error={!!(formik.touched.linkedIn && formik.errors.linkedIn)}
                fullWidth
                helperText={formik.touched.linkedIn && formik.errors.linkedIn}
                label="LinkedIn Public Profile"
                name="linkedIn"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.linkedIn || ''}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {isLoading ? (
          <CardActions sx={{ justifyContent: 'flex-end', my: 1 }}>
            <CircularProgress size={20} />
            <Button disabled color={'primary'}>
              Saving...
            </Button>
          </CardActions>
        ) : (
          <CardActions sx={{ justifyContent: 'flex-end', my: 1 }}>
            <Button
              variant="contained"
              disabled={isLoading || !formik.isValid || !isChanged}
              type="submit"
            >
              Save Changes
            </Button>
          </CardActions>
        )}
      </Card>
    </form>
  );
};
