export const oxfordComma = (strList, verb) => {
  const count = strList?.length;
  if (!count) {
    return '';
  }

  const verbText = verb ? (count === 1 ? ' is ' : ' are ') + verb : '';
  if (count === 1) {
    return strList[0] + verbText;
  }
  if (count === 2) {
    return strList[0] + ' and ' + strList[1] + verbText;
  }

  const copy = [...strList];
  const last = copy.pop();
  const result = copy.join(', ') + ' and ' + last;
  return result + verbText;
};

export const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};
