import { neutral } from '../../theme/colors';
import Photo from '@mui/icons-material/Photo';
import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { StorageBucket } from '../../../helpers/supabase';

const IMG_WIDTH = 125;
const IMG_HEIGHT = 75;

export const AssetInfoBlock = ({ asset }) => {
  const hasImg = asset.images?.length;
  const imageSrc =
    hasImg &&
    `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.OFFERING_IMAGES}/${asset.dealId}/assets/${asset.id}/${asset.images[0].name}?crop=focalpoint&fit=crop&h=${IMG_HEIGHT}&w=${IMG_WIDTH}`;

  return (
    <Stack spacing={3}>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          {hasImg ? (
            <img
              src={imageSrc}
              width={IMG_WIDTH}
              height={IMG_HEIGHT}
              alt={asset.name}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: neutral[200],
                borderRadius: 5,
              }}
            >
              <Photo
                sx={{
                  width: IMG_WIDTH,
                  height: IMG_HEIGHT,
                }}
              />
            </div>
          )}
          <Stack sx={{ mt: 1 }}>
            <Typography variant="subtitle2">
              <b>{asset.name}</b>
            </Typography>
            {asset.city && asset.state && (
              <Typography variant="subtitle2">
                {asset.city}, {asset.state}
              </Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={10}>
          <Stack sx={{ mt: -2 }}>
            {HTMLReactParser(asset?.description || '')}
          </Stack>
        </Grid>
      </Grid>
      {/*<Divider />*/}
      {/*<Grid container>*/}
      {/*  <Grid xs={4} sx={{ px: 2 }}>*/}
      {/*    <SectionHeader*/}
      {/*      title={'Timeline'}*/}
      {/*      subtitle={'As projected by Sponsor'}*/}
      {/*      size={'sm'}*/}
      {/*    />*/}
      {/*    <ViewTimeline initialValue={asset?.timeline} />*/}
      {/*  </Grid>*/}
      {/*  <Grid xs={4} sx={{ px: 2 }}>*/}
      {/*    <SectionHeader*/}
      {/*      title={'Capital Structure'}*/}
      {/*      subtitle={'As projected by Sponsor'}*/}
      {/*      size={'sm'}*/}
      {/*    />*/}
      {/*    <ViewCapStructure initialValue={asset?.capStructure} />*/}
      {/*  </Grid>*/}
      {/*  <Grid xs={4} sx={{ px: 2 }}>*/}
      {/*    <SectionHeader*/}
      {/*      title={'Expected Returns'}*/}
      {/*      subtitle={'As projected by Sponsor'}*/}
      {/*      size={'sm'}*/}
      {/*    />*/}
      {/*    <ViewExpReturns initialValue={asset?.expReturns} />*/}
      {/*  </Grid>*/}
      {/*  <Grid xs={12} sx={{ mb: 4 }}></Grid>*/}
      {/*</Grid>*/}
    </Stack>
  );
};
