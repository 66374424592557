import { Button, Stack, Tooltip } from '@mui/material';
import React from 'react';
import CurrencyExchange from '@mui/icons-material/CurrencyExchange';
import { DealInvestStatus } from '../enums/deal-invest-status.enum';

export const InvestStatusButton = ({ deal, onClick, fullWidth = false }) => {
  const status = deal?.investments[0];

  if (!status) {
    return <></>;
  }

  const isEvaluating = status?.status === DealInvestStatus.Evaluating;
  const isPassed = status?.status === DealInvestStatus.Passed;
  const tooltip = `Update your investment status of this deal`;

  return (
    <Tooltip placement={'top'} title={tooltip}>
      <Stack alignItems={'center'}>
        <Button
          variant="contained"
          size={'small'}
          fullWidth={fullWidth}
          color={isEvaluating ? 'info' : isPassed ? 'warning' : 'success'}
          startIcon={<CurrencyExchange />}
          onClick={onClick}
          disabled={!status}
          sx={{
            minWidth: 175,
          }}
        >
          {status ? `Status: ${status.status}` : `Evaluating`}
        </Button>
      </Stack>
    </Tooltip>
  );
};
