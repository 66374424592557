import {
  Checkbox,
  FormControlLabel,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Help } from '@mui/icons-material';
import React from 'react';
import { Qualifications } from '../../helpers/qualifications';

const QualificationCheckbox = ({
  checked,
  value,
  onChange,
  disabled,
  title,
  subtitle,
  icon,
}) => {
  return (
    <FormControlLabel
      sx={{ alignItems: 'flex-start' }}
      onClick={(e) => e.stopPropagation()}
      control={
        <Checkbox
          sx={{ mt: -1 }}
          checked={checked}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={
        <Stack sx={{ ml: 2 }}>
          <Stack direction={'row'} gap={1}>
            {icon}
            <Typography variant={'body1'}>{title}</Typography>
            {/*<Tooltip title={tooltip}>*/}
            {/*  <Help />*/}
            {/*</Tooltip>*/}
          </Stack>
          <Typography variant={'caption'} color={'text.secondary'}>
            {subtitle}
          </Typography>
        </Stack>
      }
    />
  );
};

export const QualificationForm = ({
  formik,
  choices,
  isRegistration,
  disabled,
}) => {
  const handleChange = (e) => {
    const c = e.target.value;
    if (c === 'isNotQualified' && !formik.values.isNotQualified) {
      formik.setValues({
        ...formik.values,
        isAccreditedInvestor: false,
        isQualifiedClient: false,
        isQualifiedPurchaser: false,
        isRIA: false,
        isBrokerDealer: false,
        isNotQualified: true,
      });
    } else {
      formik.setFieldValue(c, !formik.values[c]);
    }
  };

  return (
    <Stack sx={{ mt: 1 }} spacing={2}>
      {choices.map((choice) => (
        <QualificationCheckbox
          key={choice.id}
          checked={formik.values[choice.value] || false}
          value={choice.value}
          onChange={handleChange}
          disabled={formik.values.isNotQualified || disabled}
          title={choice.title}
          subtitle={choice.description}
          icon={choice.icon}
        />
      ))}
      {isRegistration && (
        <FormControlLabel
          onClick={(e) => e.stopPropagation()}
          control={
            <Checkbox
              sx={{ mt: -1 }}
              checked={formik.values.isNotQualified || false}
              value={'isNotQualified'}
              onChange={handleChange}
            />
          }
          label={
            <Stack sx={{ ml: 2, mt: -1 }}>
              <Typography variant={'body1'}>
                I am none of the above or not sure
              </Typography>
            </Stack>
          }
        />
      )}
    </Stack>
  );
};
