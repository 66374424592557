import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import React from 'react';

export const SectionHeader = ({
  title,
  subtitle,
  mb = 3,
  size = 'md',
  color,
}) => (
  <Stack>
    <Typography variant={size === 'sm' ? 'h6' : 'h5'} color={color}>
      {title}
    </Typography>
    {subtitle && (
      <Typography variant="body2" sx={{ mb }} color={'text.secondary'}>
        {subtitle}
      </Typography>
    )}
  </Stack>
);
