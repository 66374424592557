import React from 'react';
import { Alert, Box, Card, CardContent, Grid, Stack } from '@mui/material';
import { SectionHeader } from '../../util/section-header';
import MapView from '../../maps/map-view';
import { AssetInfoBlock } from './asset-info-block';

export const DealAssets = ({ deal }) => {
  const assetMarkers = deal.assets
    .filter((a) => a.latlng !== null)
    .map((a) => {
      const latlng = a.latlng ? JSON.parse(a.latlng) : null;
      return latlng ? { lat: latlng.lat, lng: latlng.lng } : null;
    });
  return (
    <Box sx={{ mb: 4 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <SectionHeader
          title={'Committed Projects'}
          subtitle={'The individual projects in which the fund is invested'}
        />
      </Stack>

      {deal.assets?.length > 0 && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <MapView markers={assetMarkers} height={250} />
        </Stack>
      )}
      <Grid container spacing={2}>
        {deal.assets?.length > 0 &&
          deal.assets.map((asset, index) => {
            return (
              <Grid item xs={12} key={asset.id}>
                <Card elevation={6}>
                  <CardContent
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <AssetInfoBlock asset={asset} />
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      {!deal?.assets?.length && (
        <Alert severity={'warning'}>
          This fund has no committed projects yet.
        </Alert>
      )}
    </Box>
  );
};
