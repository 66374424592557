import React, { Fragment, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import Add from '@mui/icons-material/Add';
import { Delete } from '@mui/icons-material';
import {
  DealReturnMetric,
  DealReturnMetricKey,
} from '../../helpers/exp-returns';
import { DialogActionBar } from './dialog-action-bar';

const DealEditExpReturnsDialog = ({ open, onClose, onSubmit, metrics }) => {
  const initialValues = {
    IRR: metrics ? metrics[DealReturnMetricKey.IRR] : '',
    CashOnCash: metrics ? metrics[DealReturnMetricKey.CashOnCash] : '',
    customMetrics: metrics?.customMetrics
      ? metrics.customMetrics.map((cm) => ({
          value: cm.value,
          label: cm.label,
          unit: cm.unit,
        }))
      : [],
  };

  const validationSchema = Yup.object().shape({
    IRR: Yup.number().min(0, 'Must be a positive number').nullable(true),
    CashOnCash: Yup.number().min(0, 'Must be a positive number').nullable(true),
    customMetrics: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required('Label is required'),
        value: Yup.number()
          .required('Value is required')
          .min(0, 'Must be a positive number')
          .nullable(true),
        unit: Yup.string().required('Unit is required').nullable(true),
      }),
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        onSubmit({ ...values });
      } catch (err) {
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ [errObj.field]: errObj.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  const handleAddRow = () => {
    const customMetrics = [
      ...formik.values.customMetrics,
      { label: '', value: '', unit: '%' },
    ];
    formik.setFieldValue('customMetrics', customMetrics);
  };

  const handleRemoveRow = async (index) => {
    const customMetrics = [...formik.values.customMetrics];
    customMetrics.splice(index, 1);
    await formik.setFieldValue('customMetrics', customMetrics);
    formik.handleSubmit();
  };

  useEffect(() => {
    if (open) {
      formik.setValues({
        IRR: metrics ? metrics[DealReturnMetricKey.IRR] : '',
        CashOnCash: metrics ? metrics[DealReturnMetricKey.CashOnCash] : '',
        customMetrics: metrics?.customMetrics
          ? metrics.customMetrics.map((cm) => ({
              value: cm.value,
              label: cm.label,
              unit: cm.unit,
            }))
          : [],
      });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Edit Expected Return Metrics</Typography>
          <Button
            onClick={handleAddRow}
            startIcon={<Add />}
            disabled={!formik.isValid}
          >
            Add Custom Metric
          </Button>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={9}>
            <Typography variant={'body2'}>
              <b>{DealReturnMetric[DealReturnMetricKey.IRR].label}</b>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={'Value'}
              error={!!formik.errors.IRR}
              helperText={formik.errors.IRR}
              name="IRR"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.IRR !== undefined ? formik.values.IRR : ''}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {DealReturnMetric[DealReturnMetricKey.IRR].unit}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography variant={'body2'}>
              <b>{DealReturnMetric[DealReturnMetricKey.CashOnCash].label}</b>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={'Value'}
              error={!!formik.errors.CashOnCash}
              helperText={formik.errors.CashOnCash}
              name="CashOnCash"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={
                formik.values.CashOnCash !== undefined
                  ? formik.values.CashOnCash
                  : ''
              }
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {DealReturnMetric[DealReturnMetricKey.CashOnCash].unit}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {formik.values.customMetrics.map((row, index) => (
            <Fragment key={index}>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  required
                  label={'Label'}
                  value={row.label || ''}
                  name={`customMetrics[${index}].label`}
                  error={
                    !!(
                      formik.touched.customMetrics?.[index]?.label &&
                      formik.errors.customMetrics?.[index]?.label
                    )
                  }
                  helperText={
                    formik.touched.customMetrics?.[index]?.label &&
                    formik.errors.customMetrics?.[index]?.label
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  required
                  label={'Value'}
                  value={row.value !== undefined ? row.value : ''}
                  name={`customMetrics[${index}].value`}
                  error={
                    !!(
                      formik.touched.customMetrics?.[index]?.value &&
                      formik.errors.customMetrics?.[index]?.value
                    )
                  }
                  helperText={
                    formik.touched.customMetrics?.[index]?.value &&
                    formik.errors.customMetrics?.[index]?.value
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="number"
                />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  fullWidth
                  required
                  value={row.unit || ''}
                  name={`customMetrics[${index}].unit`}
                  error={
                    !!(
                      formik.touched.customMetrics?.[index]?.unit &&
                      formik.errors.customMetrics?.[index]?.unit
                    )
                  }
                  helperText={
                    formik.touched.customMetrics?.[index]?.unit &&
                    formik.errors.customMetrics?.[index]?.unit
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  select
                  SelectProps={{ native: true }}
                >
                  <option value="%">%</option>
                  <option value="x">x</option>
                </TextField>
              </Grid>
              <Grid item xs={1} align="center">
                <IconButton
                  sx={{ mt: 1 }}
                  onClick={() => handleRemoveRow(index)}
                >
                  <Delete color="error" />
                </IconButton>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </DialogContent>
      <DialogActionBar
        submitLabel={`Save`}
        disabled={formik.isSubmitting || !formik.isValid}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default DealEditExpReturnsDialog;
