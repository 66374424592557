import { Chip } from '@mui/material';
import React from 'react';
import { DealStatus } from '../../enums/deal-status.enum';

export const DealStatusChip = ({ deal }) => (
  <Chip
    sx={{ cursor: 'default' }}
    label={deal.status}
    variant={deal?.status === DealStatus.Approved ? 'contained' : 'outlined'}
    color={
      deal?.status === DealStatus.Approved
        ? 'success'
        : deal?.status === DealStatus.PendingReview
          ? 'info'
          : 'default'
    }
  />
);
