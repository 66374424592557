import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useApi } from '../api/use-api';
import { RetryConfig } from '../api/retry-config';
import { handleMutate } from '../util/use-handle-mutate';

export const OrganizationQueryKeys = {
  all: ['organizations'], //for manage deals
  approvals: () => [...OrganizationQueryKeys.all, 'approvals'],
  details: () => [...OrganizationQueryKeys.all, 'detail'],
  detail: (id) => [...OrganizationQueryKeys.details(), id], //for a single deal detail
};

const useOrganizations = ({ options = null }) => {
  const request = useApi();
  return useQuery(
    OrganizationQueryKeys.all,
    async () => request({ url: '/organizations' }),
    {
      ...RetryConfig(),
      ...options,
    },
  );
};

const useOrganization = ({ id, initialData, options = null }) => {
  const request = useApi();
  return useQuery(
    OrganizationQueryKeys.detail(id),
    async () => request({ url: '/organizations/' + id }),
    {
      initialData,
      ...RetryConfig(),
      ...options,
    },
  );
};

const usePublicOrganization = ({ linkId, options = null }) => {
  const request = useApi(true);
  return useQuery(
    OrganizationQueryKeys.detail(linkId),
    async () => request({ url: '/public/organizations/' + linkId }),
    {
      ...RetryConfig(),
      ...options,
    },
  );
};

const useApprovalOrganizations = () => {
  const request = useApi();
  return useQuery(
    OrganizationQueryKeys.approvals(),
    async () => request({ url: `/organizations/approvals` }),
    {
      ...RetryConfig(),
    },
  );
};

const handleSuccess = (queryClient, updatedOrg) => {
  queryClient.setQueryData(OrganizationQueryKeys.all, (old) => {
    if (!old) return;
    const idx = old.findIndex((org) => org.id === updatedOrg.id);
    const copy = [...old];
    if (idx > -1) {
      copy[idx] = updatedOrg;
    }
    return copy;
  });
  queryClient.setQueryData(
    OrganizationQueryKeys.detail(updatedOrg.id),
    updatedOrg,
  );
};

const useUpdateOrganization = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, updated }) =>
      request({
        url: `/organizations/${id}`,
        method: 'PATCH',
        body: updated,
      }),
    {
      onMutate: async ({ id, updated }) => {
        return handleMutate(
          queryClient,
          OrganizationQueryKeys.detail(id),
          (old) => ({
            ...old,
            ...updated,
          }),
        );
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(
          OrganizationQueryKeys.detail(variables.id),
          previousValue,
        );
      },
      onSuccess: (data, error, variables) => {
        handleSuccess(queryClient, data);
      },
      ...RetryConfig(),
    },
  );
};

const useUpdateOrganizationLogo = () => {
  const request = useApi(false, true);
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, images }) =>
      request({
        url: `/organizations/${id}/logo`,
        method: 'POST',
        body: images,
      }),
    {
      onSuccess: (data, variables, context) => {
        handleSuccess(queryClient, data);
      },
      ...RetryConfig(),
    },
  );
};

const useDeleteOrganizationLogo = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id }) =>
      request({
        url: `/organizations/${id}/logo`,
        method: 'DELETE',
      }),
    {
      onSuccess: (data, variables, context) => {
        handleSuccess(queryClient, data);
      },
      ...RetryConfig(),
    },
  );
};

const useApproveOrganization = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id }) =>
      request({
        url: `/organizations/${id}/approve`,
        method: 'POST',
      }),
    {
      onMutate: async ({ id }) => {
        const prevDetail = await handleMutate(
          queryClient,
          OrganizationQueryKeys.detail(id),
          (old) => {
            old.approvedAt = new Date().toISOString();
            return old;
          },
        );

        const prevOrgs = await handleMutate(
          queryClient,
          OrganizationQueryKeys.approvals(),
          (old) => old.filter((org) => org.id !== id),
        );

        return {
          prevOrgs,
          prevDetail,
        };
      },
      // On failure, roll back to the previous value
      onError: (err, variables, { prevOrgs, prevDetail }) => {
        queryClient.setQueryData(
          OrganizationQueryKeys.detail(variables.id),
          prevDetail,
        );
        queryClient.setQueryData(OrganizationQueryKeys.approvals(), prevOrgs);
      },
      onSuccess: (data, error, variables) => {
        queryClient.invalidateQueries(OrganizationQueryKeys.approvals()).then();
      },
      ...RetryConfig(),
    },
  );
};

export {
  useOrganizations,
  useApprovalOrganizations,
  useOrganization,
  useUpdateOrganization,
  useUpdateOrganizationLogo,
  useDeleteOrganizationLogo,
  useApproveOrganization,
  usePublicOrganization,
};
