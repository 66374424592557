import { format, isToday, isYesterday, parse, parseISO } from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';

export const localDate = (isoDate) => {
  if (!isoDate) return;
  if (isoDate.indexOf('T') < 0) return;
  return parse(isoDate.split('T')[0], 'yyyy-MM-dd', new Date());
};

export const localTime = (isoDate) => {
  if (!isoDate) return;
  return parseISO(isoDate);
};

const formatInTimeZone = (date, fmt, tz) =>
  formatTz(utcToZonedTime(date, tz), fmt, { timeZone: tz });

export const utcToLocalTime = (isoDateUtc, fmt = 'yyyy-MM-dd @ h:mmaaa') => {
  if (!isoDateUtc) return;
  if (isoDateUtc.indexOf('T') < 0) return;
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const parsedTime = parseISO(isoDateUtc);
  return utcToZonedTime(parsedTime, timeZone);
};

export const smartDateFormat = (localDate, fmt = 'yyyy-MM-dd') => {
  if (isToday(localDate)) {
    return 'Today';
  }
  if (isYesterday(localDate)) {
    return 'Yesterday';
  }
  return format(localDate, fmt);
};
