import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { DialogActionBar } from './dialog-action-bar';

export const DeleteConfirmDialog = ({
  open,
  title,
  message,
  onClose,
  handleSubmit,
  isLoading,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActionBar
        isLoading={isLoading}
        loadingLabel={'Deleting...'}
        submitLabel={'Yes, Delete'}
        cancelLabel={'Nevermind'}
        cancelColor={'primary'}
        submitColor={'error'}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};
