import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { neutral } from '../theme/colors';

export const Footer = () => {
  return (
    <Stack
      sx={{
        display: 'flex',
        py: 3,
        backgroundColor: 'black',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      spacing={2}
    >
      <Stack direction="row" spacing={3}>
        <Link
          to={`http://www.opportunityzonedeals.com/terms-of-use`}
          target={'_blank'}
          style={{
            color: neutral['400'],
          }}
        >
          Terms of Use
        </Link>
        <Link
          to={`http://www.opportunityzonedeals.com/privacy-policy`}
          target={'_blank'}
          style={{
            color: neutral['400'],
          }}
        >
          Privacy Policy
        </Link>
        <Link
          to={`http://www.opportunityzonedeals.com/disclosures`}
          target={'_blank'}
          style={{
            color: neutral['400'],
          }}
        >
          Disclosures
        </Link>
      </Stack>
      <Typography textAlign="center" sx={{ color: 'white' }}>
        © {new Date().getFullYear()} Opportunity Zone Deals
      </Typography>
    </Stack>
  );
};
