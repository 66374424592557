import React from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import { common } from '@mui/material/colors';
import {
  useAddToDealWatchlist,
  useRemoveFromDealWatchlist,
} from '../hooks/deals/use-deal-watchlist';
import { success } from '../theme/colors';

export const WatchlistButton = ({
  deal,
  fullWidth = false,
  setSnackbar,
  iconOnly = false,
}) => {
  const addToWatchlist = useAddToDealWatchlist();
  const removeFromWatchlist = useRemoveFromDealWatchlist();

  const isWatching = deal?.watching?.length > 0;
  const icon = isWatching ? (
    <Visibility sx={{ color: iconOnly ? success.main : undefined }} />
  ) : (
    <Visibility />
  );
  const title = isWatching ? 'Stop Watching' : 'Add to Watchlist';

  const handleClick = (e) => {
    e.stopPropagation();
    const isWatching = deal?.watching?.length > 0;
    try {
      if (!isWatching) {
        addToWatchlist.mutate({
          deal,
        });
        setSnackbar({ message: deal.name + ' was added to your watch list.' });
      } else {
        removeFromWatchlist.mutate({
          deal,
        });
        setSnackbar({
          message: deal.name + ' was removed from your watch list.',
        });
      }
    } catch (err) {
      console.log('error: ' + JSON.stringify(err, null, 2));
    }
  };

  const isLoading = addToWatchlist.isLoading || removeFromWatchlist.isLoading;

  return (
    <Tooltip
      placement={'top'}
      title={
        isWatching
          ? 'Remove this deal from your Watchlist'
          : 'Add this deal to your Watchlist'
      }
    >
      <span>
        {iconOnly ? (
          <IconButton
            onClick={handleClick}
            disabled={isLoading}
            sx={{
              backgroundColor: common.black,
              color: common.white,
              '&:hover': {
                backgroundColor: 'grey.800',
              },
            }}
          >
            {icon}
          </IconButton>
        ) : (
          <Button
            variant="contained"
            fullWidth={fullWidth}
            size={'small'}
            onClick={handleClick}
            startIcon={icon}
            sx={{
              minWidth: 175,
            }}
          >
            {title}
          </Button>
        )}
      </span>
    </Tooltip>
  );
};
