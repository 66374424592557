import { useMutation, useQueryClient } from 'react-query';
import { useApi } from '../api/use-api';
import { RetryConfig } from '../api/retry-config';

const useViewDeal = () => {
  const request = useApi();
  return useMutation(
    async ({ dealId, referrer, userAgent }) =>
      request({
        url: `/deals/${dealId}/view`,
        method: 'POST',
        body: { referrer, userAgent },
      }),
    {
      ...RetryConfig(),
    },
  );
};

const useViewPublicDeal = () => {
  const request = useApi(true);
  return useMutation(
    async ({ externalId, anonymousId, referrer, userAgent }) =>
      request({
        url: `/public/deals/${externalId}/view`,
        method: 'POST',
        body: {
          anonymousId,
          referrer,
          userAgent,
        },
      }),
    {
      ...RetryConfig(),
    },
  );
};

export { useViewDeal, useViewPublicDeal };
