export const sortByCreatedAt = (d1, d2) => {
  if (d1.createdAt > d2.createdAt) {
    return -1;
  }
  if (d1.createdAt < d2.createdAt) {
    return 1;
  }
  return 0;
};

export const sortThreadsByLatestMessage = (t1, t2) => {
  const lm1 = t1.messages[t1.messages.length - 1];
  const lm2 = t2.messages[t2.messages.length - 1];
  if (lm1.createdAt > lm2.createdAt) {
    return -1;
  }
  if (lm1.createdAt < lm2.createdAt) {
    return 1;
  }
  return 0;
};
