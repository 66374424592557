import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Role } from '../../../components/enums/role.enum';
import { DealStatus } from '../../../components/enums/deal-status.enum';
import CircularProgress from '@mui/material/CircularProgress';
import { useDeal } from '../../../components/hooks/deals/use-deal';
import { useFormik } from 'formik';
import { useViewDeal } from '../../../components/hooks/deals/use-deal-view';
import { DealType } from '../../../components/enums/deal-type.enum';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSessionUser } from '../../../components/hooks/session/useSessionUser';
import { useSnackbar } from '../../../components/hooks/util/use-snackbar';
import { smartDateFormat, utcToLocalTime } from '../../../helpers/dates';
import { sortByCreatedAt } from '../../../helpers/messages-sort';
import { DealSubmitValidationSchema } from '../../../validation/deal-submit-validation-schema';
import { DealToFormValues } from '../../../helpers/deal-to-form';
import { DealActionsOrganization } from '../../../components/deals/view/deal-actions-organization';
import { DealActionsInvestor } from '../../../components/deals/view/deal-actions-investor';
import { DealKpis } from '../../../components/deals/view/deal-kpis';
import { DealThreads } from '../../../components/deals/view/deal-threads';
import { DealPublished } from '../../../components/deals/view/deal-published';
import { useSubmitDeal } from '../../../components/hooks/deals/use-deal-approval';

export default function ViewDeal() {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const openDialog = queryParams.get('openDialog');
  const stripe_session_id = queryParams.get('session_id');

  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();
  const { render: renderSnackbar, setSnackbar } = useSnackbar();

  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isOrgAdmin = sessionUser?.role === Role.OrgAdmin;
  const isOrgUser = sessionUser?.orgId;
  const isInvestor = sessionUser?.role === Role.Investor;
  const [isPageViewTracked, setPageViewTracked] = useState(false);

  const { data: deal, isLoading } = useDeal({
    id: parseInt(id),
  });

  const logPageView = useViewDeal();
  const handlePageView = async () => {
    //Log deal view if user is Investor or Sponsor from other org
    if (
      deal &&
      !logPageView.isLoading &&
      !isPageViewTracked &&
      (isInvestor || (isOrgUser && sessionUser?.orgId !== deal?.orgId))
    ) {
      const referrer = document.referrer;
      const userAgent = navigator.userAgent;
      await logPageView.mutateAsync({ dealId: id, referrer, userAgent });
      setPageViewTracked(true);
    }
  };

  useEffect(() => {
    handlePageView();
  }, [deal]);

  useEffect(() => {
    if (!/\d+/.test(id)) {
      navigate(-1);
    }
  }, [id, navigate]);

  const isOwner =
    (isOrgAdmin && deal?.orgId === sessionUser?.orgId) ||
    deal?.ownerId === sessionUser?.id;
  const isDraft = deal?.status === DealStatus.Draft;
  const isApproved = deal?.status === DealStatus.Approved;
  const isArchived = deal?.archivedAt;
  const isFund = deal?.type === DealType.Fund;
  const dealWord = isFund ? 'fund' : 'deal';

  const lastContact =
    deal?.messages?.length > 0 ? deal?.messages.sort(sortByCreatedAt)[0] : null;
  const lastContactSentAtLocal = lastContact?.createdAt
    ? utcToLocalTime(lastContact?.createdAt)
    : null;

  const handleEditClick = () => {
    navigate(`/deals/${id}/edit`);
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: DealSubmitValidationSchema,
  });

  useEffect(() => {
    if (deal) {
      formik.setValues(DealToFormValues(deal), true);
    }
  }, [deal]);

  const submitDeal = useSubmitDeal();
  const submitDealForApproval = () => {
    try {
      submitDeal.mutate({
        deal,
      });
      setSnackbar({
        message:
          'Subscription has been purchased and your deal is pending review.',
      });
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
    }
  };
  useEffect(() => {
    if (stripe_session_id) {
      submitDealForApproval();
      queryParams.delete('session_id');
    }
  }, [stripe_session_id]);

  return (
    <Container maxWidth="lg" sx={{ my: 2 }}>
      {isLoading || !deal ? (
        <Stack alignItems="center" spacing={2}>
          <Typography variant={'h6'}>Loading Deal</Typography>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {renderSnackbar()}
          <Stack
            spacing={5}
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-end"
          >
            {isInvestor ? (
              <DealActionsInvestor
                deal={deal}
                isLoading={isLoading}
                setSnackbar={setSnackbar}
                openDialog={openDialog}
              />
            ) : (
              <DealActionsOrganization
                deal={deal}
                isLoading={isLoading}
                setSnackbar={setSnackbar}
                onEditClick={handleEditClick}
                canSubmitForApproval={formik.isValid}
                onSubmitForApproval={submitDealForApproval}
              />
            )}
          </Stack>
          <Stack spacing={1} sx={{ my: 2 }}>
            {(isSysAdmin || isOwner) && (
              <Alert
                severity={
                  isArchived || (isDraft && !formik.isValid)
                    ? 'warning'
                    : isApproved
                      ? 'success'
                      : 'info'
                }
                elevation={6}
              >
                {isArchived ? (
                  <Typography variant={'body2'}>
                    {`This ${dealWord} is archived and not currently active.`}
                  </Typography>
                ) : deal.approvedAt ? (
                  <Typography variant={'body2'}>
                    {`This ${dealWord} is approved and publicly listed.`}
                  </Typography>
                ) : (
                  <Typography variant={'body2'}>
                    {isDraft
                      ? formik.isValid
                        ? `This is a draft ${dealWord} and needs to be completed and submitted to be listed publicly.`
                        : `This ${dealWord} needs all required fields entered in order to submit to be listed publicly.`
                      : isSysAdmin
                        ? `This ${dealWord} is pending review and not yet listed publicly.`
                        : `Hang tight, this ${dealWord} is pending review and not yet listed publicly.`}
                  </Typography>
                )}
              </Alert>
            )}
            {isInvestor && lastContactSentAtLocal && (
              <Alert severity={'info'} elevation={6}>
                <Typography variant={'body2'}>
                  {`You sent a message about this ${dealWord} ${smartDateFormat(
                    lastContactSentAtLocal,
                    `'on' M/d/yy`,
                  )}.`}
                </Typography>
              </Alert>
            )}
            {(isSysAdmin || isOwner) && isApproved && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title={'Deal Page Performance'} />
                    <CardContent>
                      <DealKpis deal={deal} />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={{ maxHeight: 275, overflowY: 'auto' }}>
                    <CardHeader title={'Recent Messages'} />
                    <CardContent>
                      <Stack>
                        <DealThreads deal={deal} />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
            <Card>
              {isApproved && <CardHeader title={'Live View'} />}
              <CardContent>
                <DealPublished deal={deal} values={DealToFormValues(deal)} />
              </CardContent>
            </Card>
          </Stack>
        </>
      )}
    </Container>
  );
}
