import * as React from 'react';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import MoreHorizOutlined from '@mui/icons-material/MoreHorizOutlined';

export default function MoreEllipsesMenu({ items }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreHorizOutlined />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx: { p: 0 },
        }}
      >
        <Paper sx={{ maxWidth: '100%' }}>
          {items.map((item) => {
            const handleItemOnClick = (e) => {
              item.onClick(e);
              handleClose(e);
            };
            return (
              <MenuItem key={item.primary} onClick={handleItemOnClick}>
                <ListItemIcon>
                  {React.cloneElement(item.icon, { color: item.color })}
                </ListItemIcon>
                <ListItemText
                  primary={item.primary}
                  secondary={item.secondary}
                />
              </MenuItem>
            );
          })}
        </Paper>
      </Menu>
    </div>
  );
}
