import React, { useEffect, useRef, useState } from 'react';

export default function WysiwygEditor({ ...props }) {
  const editorRef = useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  const [editorLoaded, setEditorLoaded] = useState(false);

  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = {
        CKEditor: require('@ckeditor/ckeditor5-react').CKEditor,
        ClassicEditor: require('./ckeditor'),
      };
    }
    setEditorLoaded(true);
  }, []);

  return (
    <>
      {editorLoaded ? (
        <CKEditor editor={ClassicEditor} {...props} height={50} />
      ) : (
        <div>Editor loading</div>
      )}
    </>
  );
}
