import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Refresh from '@mui/icons-material/Refresh';
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import AddOutlined from '@mui/icons-material/AddOutlined';
import {
  DEFAULT_CAP_STRUCTURE,
  EMPTY_CAP_STRUCTURE,
} from '../../../helpers/cap-structure';
import DealEditCapStructureLineItemDialog from '../../dialogs/deal-edit-cap-structure-line-item-dialog';
import DealDeleteCapStructureLineItemDialog from '../../dialogs/deal-delete-cap-structure-line-item-dialog';
import { SectionHeader } from '../../util/section-header';
export const EditCapStructure = ({ initialValue, onChange }) => {
  const [form, setForm] = useState(
    initialValue ? JSON.parse(initialValue) : EMPTY_CAP_STRUCTURE,
  );

  const [equityLineToEdit, setEquityLineToEdit] = useState(null);
  const [equityLineToDelete, setEquityLineToDelete] = useState(null);
  const [debtLineToEdit, setDebtLineToEdit] = useState(null);
  const [debtLineToDelete, setDebtLineToDelete] = useState(null);

  const handleChange = () => {
    onChange(JSON.stringify(form));
  };

  useEffect(() => {
    handleChange();
  }, [form]);

  useEffect(() => {
    setForm(initialValue ? JSON.parse(initialValue) : EMPTY_CAP_STRUCTURE);
  }, [initialValue]);

  const totalEquity =
    form.investors?.reduce((prev, curr) => (prev += curr.amount), 0) || 0;
  const totalDebt =
    form.debt?.reduce((prev, curr) => (prev += curr.amount), 0) || 0;
  const totalSources = totalEquity + totalDebt;

  const handleRestoreDefault = () => {
    setForm(DEFAULT_CAP_STRUCTURE);
  };

  const closeEditEquityLine = () => {
    setEquityLineToEdit(null);
  };

  const closeDeleteEquityLine = () => {
    setEquityLineToDelete(null);
  };

  const closeEditDebtLine = () => {
    setDebtLineToEdit(null);
  };

  const closeDeleteDebtLine = () => {
    setDebtLineToDelete(null);
  };

  const openEditEquityLine = ({ name, amount, idx }) => {
    setEquityLineToEdit({ name, amount, idx });
  };

  const openAddEquityLine = () => {
    setEquityLineToEdit({ name: '', amount: 0, idx: -1 });
  };

  const openDeleteEquityLine = ({ name, amount, idx }) => {
    setEquityLineToDelete({ name, amount, idx });
  };

  const openEditDebtLine = ({ name, amount, idx }) => {
    setDebtLineToEdit({ name, amount, idx });
  };

  const openAddDebtLine = () => {
    setDebtLineToEdit({ name: '', amount: 0, idx: -1 });
  };

  const openDeleteDebtLine = ({ name, amount, idx }) => {
    setDebtLineToDelete({ name, amount, idx });
  };

  const handleSaveEquityLine = ({ name, amount, idx }) => {
    if (idx === -1) {
      setForm({ ...form, investors: [...form.investors, { name, amount }] });
    } else {
      const newInvestors = [...form.investors];
      newInvestors[idx].name = name;
      newInvestors[idx].amount = amount;
      setForm({ ...form, investors: newInvestors });
    }
    closeEditEquityLine();
  };

  const handleDeleteEquityLine = () => {
    const newInvestors = form.investors.filter(
      (inv, idx) => idx !== equityLineToDelete.idx,
    );
    setForm({ ...form, investors: newInvestors });
    closeDeleteEquityLine();
  };

  const handleSaveDebtLine = ({ name, amount, idx }) => {
    if (idx === -1) {
      setForm({ ...form, debt: [...form.debt, { name, amount }] });
    } else {
      const newDebt = [...form.debt];
      newDebt[idx].name = name;
      newDebt[idx].amount = amount;
      setForm({ ...form, debt: newDebt });
    }
    closeEditDebtLine();
  };

  const handleDeleteDebtLine = () => {
    const newDebt = form.debt.filter(
      (inv, idx) => idx !== debtLineToDelete.idx,
    );
    setForm({ ...form, debt: newDebt });
    closeDeleteDebtLine();
  };

  return (
    <>
      <DealEditCapStructureLineItemDialog
        open={equityLineToEdit !== null}
        lineItem={equityLineToEdit}
        onClose={closeEditEquityLine}
        onSubmit={handleSaveEquityLine}
      />
      <DealDeleteCapStructureLineItemDialog
        open={equityLineToDelete !== null}
        title={equityLineToDelete?.name}
        onClose={closeDeleteEquityLine}
        onSubmit={handleDeleteEquityLine}
      />
      <DealEditCapStructureLineItemDialog
        open={debtLineToEdit !== null}
        lineItem={debtLineToEdit}
        onClose={closeEditDebtLine}
        onSubmit={handleSaveDebtLine}
        isDebt
      />
      <DealDeleteCapStructureLineItemDialog
        open={debtLineToDelete !== null}
        title={debtLineToDelete?.name}
        onClose={closeDeleteDebtLine}
        onSubmit={handleDeleteDebtLine}
        isDebt
      />
      <Stack
        direction="row"
        alignItems={'flex-start'}
        justifyContent="space-between"
      >
        <SectionHeader
          title={'Capital Structure'}
          subtitle={'The projected composition of the project'}
        />
        <Stack direction="row" alignItems={'center'}>
          <Tooltip title={'Restore the Capital Structure to the OZD default'}>
            <Button
              size={'small'}
              onClick={handleRestoreDefault}
              startIcon={<Refresh />}
            >
              Restore Default
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack sx={{ mt: 1 }}>
        <Table padding="normal" size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align={'right'} sx={{ py: 2 }}>
                Gross
              </TableCell>
              <TableCell align={'right'}>% of Total</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>
                  <u>Equity</u>
                </b>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align={'right'}>
                <Tooltip title="Add Equity Line" placement="top">
                  <IconButton onClick={openAddEquityLine} color="primary">
                    <AddOutlined />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
            {form.investors
              .sort((i1, i2) => (i1.amount > i2.amount ? -1 : 1))
              .map(({ name, amount }, idx) => {
                const handleEdit = () => {
                  openEditEquityLine({ name, amount, idx });
                };

                const handleRemove = () => {
                  openDeleteEquityLine({ name, amount, idx });
                };

                return (
                  <TableRow key={name}>
                    <TableCell>{name}</TableCell>
                    <TableCell align={'right'}>
                      {amount.toLocaleString()}
                    </TableCell>
                    <TableCell align={'right'}>
                      {totalSources
                        ? Math.round((amount * 100) / totalSources)
                        : 0}
                      %
                    </TableCell>
                    <TableCell align={'center'}>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                      >
                        <Tooltip title="Edit Equity Line" placement="top">
                          <IconButton onClick={handleEdit} color="primary">
                            <EditOutlined />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove Equity Line" placement="top">
                          <IconButton onClick={handleRemove} color="error">
                            <DeleteForeverOutlined />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <TableCell sx={{ py: 2, borderBottom: '1px solid grey' }}>
                <b>Total Equity</b>
              </TableCell>
              <TableCell
                align={'right'}
                sx={{ borderBottom: '1px solid grey' }}
              >
                <b>{totalEquity.toLocaleString()}</b>
              </TableCell>
              <TableCell
                align={'right'}
                sx={{ borderBottom: '1px solid grey' }}
              >
                <b>
                  {totalSources
                    ? Math.round((totalEquity * 100) / totalSources)
                    : 0}
                  %
                </b>
              </TableCell>
              <TableCell sx={{ borderBottom: '1px solid grey' }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>
                  <u>Debt</u>
                </b>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align={'right'}>
                <Tooltip title="Add Debt Line" placement="top">
                  <IconButton onClick={openAddDebtLine} color="primary">
                    <AddOutlined />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
            {form.debt
              .sort((i1, i2) => (i1.amount > i2.amount ? -1 : 1))
              .map(({ name, amount }, idx) => {
                const handleEdit = () => {
                  openEditDebtLine({ name, amount, idx });
                };

                const handleRemove = () => {
                  openDeleteDebtLine({ name, amount, idx });
                };

                return (
                  <TableRow key={name}>
                    <TableCell>{name}</TableCell>
                    <TableCell align={'right'}>
                      {amount.toLocaleString()}
                    </TableCell>
                    <TableCell align={'right'}>
                      {totalSources
                        ? Math.round((amount * 100) / totalSources)
                        : 0}
                      %
                    </TableCell>
                    <TableCell align={'center'}>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                      >
                        <Tooltip title="Edit Debt Line" placement="top">
                          <IconButton onClick={handleEdit} color="primary">
                            <EditOutlined />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove Debt Line" placement="top">
                          <IconButton onClick={handleRemove} color="error">
                            <DeleteForeverOutlined />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <TableCell sx={{ borderBottom: '1px solid grey' }}>
                <b>Total Debt</b>
              </TableCell>
              <TableCell
                align={'right'}
                sx={{ borderBottom: '1px solid grey' }}
              >
                <b>{totalDebt.toLocaleString() || '$0'}</b>
              </TableCell>
              <TableCell
                align={'right'}
                sx={{ borderBottom: '1px solid grey' }}
              >
                <b>
                  {totalSources
                    ? Math.round((totalDebt * 100) / totalSources)
                    : 0}
                  %
                </b>
              </TableCell>
              <TableCell sx={{ borderBottom: '1px solid grey' }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ py: 2 }}>
                <b>Total Sources</b>
              </TableCell>
              <TableCell align={'right'}>
                <b>{totalSources.toLocaleString()}</b>
              </TableCell>
              <TableCell align={'right'}>
                <b>
                  {totalSources
                    ? Math.round((totalSources * 100) / totalSources)
                    : 0}
                  %
                </b>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </>
  );
};
