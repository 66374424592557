import { Role } from '../enums/role.enum';

export const NavItems = (role) => {
  const list = [];

  if (role === Role.SysAdmin) {
    list.push({
      title: 'Approvals',
      path: '/approvals',
    });
  }

  if (role === Role.SysAdmin || role === Role.OrgAdmin) {
    list.push({
      title: 'Manage Deals',
      path: '/deals',
    });
  }

  list.push({
    title: 'Find Deals',
    path: '/search',
  });

  if (role === Role.Investor) {
    list.push({
      title: 'Watchlist',
      path: '/watchlist',
    });
  }

  if (role !== Role.SysAdmin) {
    // list.push({
    //   title: 'Notifications',
    //   path: '/notifications',
    // });
    list.push({
      title: 'Messages',
      path: '/messages',
    });
  }

  return list;
};
