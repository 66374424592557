import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { oxfordQualifications } from '../../helpers/qualifications';
import { DialogActionBar } from './dialog-action-bar';

const DealCreateThreadDialog = (props) => {
  const { open, deal, onClose, onSubmit, isLoading, fromUser } = props;

  const initialValues = {
    message: `Dear ${deal?.organization?.name},\n\nI saw the ${
      deal?.name
    } deal on OpportunityZoneDeals.com and I'd like to learn more.  I am ${oxfordQualifications(
      fromUser?.qualification,
    )}. You can reach me any of the following ways:\n\nPhone: ${
      fromUser?.phone
    }\nEmail: ${fromUser?.email}\n\nSincerely,\n${fromUser?.name}`,
    submit: null,
  };

  const validationShape = {
    message: Yup.string().required('Message is required'),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await onSubmit({ message: values.message, dealId: deal.id });
      } catch (err) {
        console.log(err);
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ [errObj.field]: errObj.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleMessageChange = (e) => {
    formik.setFieldValue('message', e.target.value, true);
  };

  const handleSubmit = (e) => {
    e?.stopPropagation();
    formik.submitForm();
  };

  const handleClose = (e) => {
    e?.stopPropagation();
    onClose();
  };

  useEffect(() => {
    formik.setValues(initialValues);
  }, [deal]);

  if (!deal) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
      <DialogTitle>Send a Message about {deal.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This message will be sent directly to the Sponsor.
        </DialogContentText>
        <TextField
          margin="dense"
          id="name"
          label="To"
          type="email"
          fullWidth
          disabled
          value={`${deal?.organization.name}`}
        />
        <TextField
          margin="dense"
          id="name"
          label="Message"
          error={formik.errors.message}
          helperText={formik.errors.message}
          fullWidth
          multiline
          required
          rows={12}
          value={formik.values.message}
          onChange={handleMessageChange}
        />
      </DialogContent>
      <DialogActionBar
        isLoading={isLoading}
        loadingLabel={'Sending...'}
        submitLabel={'Send Message'}
        disabled={
          formik.isSubmitting || formik.values.message === '' || !formik.isValid
        }
        onCancel={handleClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default DealCreateThreadDialog;
