import React, { useRef, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import Add from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import {
  useCreateDealImages,
  useDeleteDealImage,
  useUpdateDealImage,
} from '../../hooks/deals/use-deal-images';
import { StorageBucket } from '../../../helpers/supabase';
import { DeleteConfirmDialog } from '../../dialogs/delete-confirm-dialog';
import { SectionHeader } from '../../util/section-header';
import { ImageLibrary } from '../../images/image-library';

export const EditDealImages = ({ deal, onUploadSuccess, setSnackbar }) => {
  const fileInputRef = useRef();
  const [imgIdxToDelete, setImgIdxToDelete] = useState(null);

  const createDealImages = useCreateDealImages();
  const deleteDealImage = useDeleteDealImage();
  const updateDealImage = useUpdateDealImage();

  const imgSrcs = deal?.images?.length
    ? deal.images.map(
        (img) =>
          `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.OFFERING_IMAGES}/${deal.id}/${img.name}`,
      )
    : null;

  const hasImg = imgSrcs?.length;

  const handleDeleteImageConfirmClose = () => {
    setImgIdxToDelete(null);
  };

  const handleDeleteImage = (idx) => {
    setImgIdxToDelete(idx);
  };

  const fileListToArray = (filelist) => {
    return Array.from(filelist);
  };

  const uploadSelectedImages = async (event) => {
    try {
      const newDeal = await createDealImages.mutateAsync({
        id: deal.id,
        images: fileListToArray(event.target.files),
      });
      onUploadSuccess(newDeal.images);
      setSnackbar({ message: 'Deal Image(s) have been uploaded!' });
    } catch (e) {
      setSnackbar({
        message: `Error: ${e}.  Make sure the file size and type are correct.`,
      });
    }
  };

  const removeImage = async () => {
    try {
      await deleteDealImage.mutateAsync({
        dealId: deal.id,
        imageId: deal.images[imgIdxToDelete].id,
      });
      setImgIdxToDelete(null);
    } catch (e) {
      setSnackbar({
        message: `Error: ${e}`,
      });
    }
  };

  const handleUpdateImagePriority = async (imgIdx, newPriority) => {
    try {
      updateDealImage.mutate({
        dealId: deal.id,
        imageId: deal.images[imgIdx].id,
        isPriority: newPriority,
      });
      setSnackbar({ message: 'Image priority has been updated!' });
    } catch (e) {
      setSnackbar({
        message: `Error: ${e}`,
      });
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <DeleteConfirmDialog
        title={`Remove this image?`}
        message={
          'This will permanently remove the image and can not be undone.'
        }
        open={imgIdxToDelete !== null}
        onClose={handleDeleteImageConfirmClose}
        isLoading={deleteDealImage.isLoading}
        handleSubmit={removeImage}
      />
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <SectionHeader
          title={'Images'}
          subtitle={`High quality images that will attract investors`}
        />
        <Stack direction="row" alignItems={'center'}>
          <label htmlFor="raised-button-file">
            <TextField
              ref={fileInputRef}
              accept="image/*"
              sx={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={uploadSelectedImages}
              inputProps={{
                multiple: true,
              }}
            />
            <Button
              fullWidth
              size={'small'}
              variant="text"
              onClick={() => fileInputRef.current.click()}
              startIcon={<Add />}
            >
              Add
            </Button>
          </label>
        </Stack>
      </Stack>

      <Grid container alignItems={'center'}>
        <Grid xs={12}>
          {!hasImg ? (
            <Alert severity={'warning'}>No images have been added yet</Alert>
          ) : (
            <ImageLibrary
              images={deal.images}
              imgSrcs={imgSrcs}
              onClickDelete={handleDeleteImage}
              onChangePriority={handleUpdateImagePriority}
            />
          )}
          {createDealImages.isLoading && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <CircularProgress size={20} />
              <Button disabled color={'primary'}>
                Uploading images...
              </Button>
            </Stack>
          )}
        </Grid>
        <Grid xs={12} sx={{ mt: 2 }}>
          <Typography variant={'caption'} color={'text.secondary'}>
            Images must be .jpg or .png files no larger than 4.5 MB
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
