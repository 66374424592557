import React, { useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useApi } from '../hooks/api/use-api';
import { SubscriptionPlans } from '../billing/subscription-plans';

const DealPaywallDialog = ({ open, title, deal, onClose }) => {
  const request = useApi();
  const [isCheckingOut, setIsCheckingOut] = useState(false);

  const handleCheckout = async (stripePriceId) => {
    setIsCheckingOut(true);
    try {
      const response = await request({
        method: 'POST',
        url: '/payments/subscription',
        body: {
          dealId: deal.id,
          stripePriceId,
        },
      });
      window.open(response.returnUrl, '_self');
    } catch (e) {
      console.log(e);
      setIsCheckingOut(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'}>
      <DialogTitle>Purchase a Subscription</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant={'h5'}>{title}</Typography>
          {!isCheckingOut && (
            <Typography variant={'body1'}>
              Put your deal in front of 1000s of investors. Choose one of the
              plans below.
            </Typography>
          )}
        </Stack>
        {isCheckingOut ? (
          <Stack spacing={2} sx={{ my: 4, py: 8 }} alignItems={'center'}>
            <Typography variant={'body1'}>Taking you to checkout...</Typography>
            <CircularProgress />
          </Stack>
        ) : (
          <SubscriptionPlans
            isCheckout
            isCheckingOut={isCheckingOut}
            handleCheckout={handleCheckout}
          />
        )}
        {!isCheckingOut && (
          <Stack spacing={2}>
            <Typography variant={'body1'}>
              Listings can be canceled anytime before the next renewal period.
            </Typography>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DealPaywallDialog;
