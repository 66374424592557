import { handleMutate } from '../util/use-handle-mutate';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { RetryConfig } from '../api/retry-config';
import { useApi } from '../api/use-api';

const queryKey = 'notifications';

const useNotifications = ({ options }) => {
  const request = useApi();
  return useQuery([queryKey], async () => request({ url: `/notifications` }), {
    ...RetryConfig(),
    ...options,
    cacheTime: 5 * 60 * 1000, //5 minutes
  });
};

const useViewNotifications = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ notificationIds }) =>
      request({
        url: `/notifications/view`,
        method: 'POST',
        body: { notificationIds },
      }),
    {
      onMutate: async ({ notificationIds }) => {
        return handleMutate(queryClient, [queryKey], (old) =>
          old.map((n) => {
            return {
              ...n,
              views: [{ notificationId: n.id, viewedFrom: 'Browser' }],
            };
          }),
        );
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData([queryKey], previousValue);
      },
      onSuccess: (data, error, variables) => {},
      ...RetryConfig(),
    },
  );
};

export { useNotifications, useViewNotifications };
