import React from 'react';
import { Stack, Typography } from '@mui/material';

export default function InternalServerErrorPage() {
  return (
    <Stack
      spacing={5}
      sx={{ mt: 5 }}
      alignItems="center"
      justifyContent="center"
    >
      <img
        alt="Something went wrong"
        src={'/images/errors/error-500.png'}
        style={{
          width: 200,
          height: 200,
        }}
      />
      <Typography variant={'h6'}>Something went wrong</Typography>
    </Stack>
  );
}
