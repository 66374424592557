import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { add, format } from 'date-fns';
import { DialogActionBar } from './dialog-action-bar';

const Months = () => {
  const now = new Date('1/1/2023');
  const months = [];
  for (let i = 0; i < 12; i++) {
    const month = add(now, { months: i });
    months.push({ label: format(month, 'MMMM'), value: format(month, 'M') });
  }
  return months;
};

const Years = (yearsAhead) => {
  const now = new Date();
  const years = [
    format(add(now, { years: -5 }), 'yyyy'),
    format(add(now, { years: -4 }), 'yyyy'),
    format(add(now, { years: -3 }), 'yyyy'),
    format(add(now, { years: -2 }), 'yyyy'),
    format(add(now, { years: -1 }), 'yyyy'),
  ];
  for (let i = 0; i < yearsAhead; i++) {
    years.push(format(add(now, { years: i }), 'yyyy'));
  }
  return years;
};

const DealEditTimelineMilestoneDialog = ({
  open,
  onClose,
  onSubmit,
  milestone,
}) => {
  const isAdd = milestone?.idx === -1;

  const initialValues = {
    ...milestone,
    submit: null,
  };

  const validationShape = {
    name: Yup.string().required('Name is required'),
    month: Yup.number().nullable(),
    year: Yup.string().nullable(),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        onSubmit({
          ...values,
          month: values.month ? parseInt(values.month) : undefined,
          idx: milestone.idx,
        });
      } catch (err) {
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ [errObj.field]: errObj.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  useEffect(() => {
    if (open) {
      formik.setValues({
        ...milestone,
        submit: null,
      });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{isAdd ? 'Add' : 'Edit'} Milestone</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant={'body1'}>
            Enter the milestone and projected completion date
          </Typography>
          <TextField
            error={!!formik.errors.name}
            helperText={formik.errors.name}
            fullWidth
            label="Name"
            name="name"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name || ''}
          />

          <Stack direction={'row'} columnGap={2}>
            <TextField
              error={!!formik.errors.month}
              helperText={formik.errors.month}
              label="Month"
              name="month"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.month || ''}
              select
              fullWidth
              SelectProps={{ native: true }}
            >
              <option value=""></option>
              {Months().map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </TextField>

            <TextField
              error={!!formik.errors.year}
              helperText={formik.errors.year}
              label="Year"
              name="year"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.year || ''}
              select
              fullWidth
              SelectProps={{ native: true }}
            >
              <option value=""></option>
              {Years(15).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </TextField>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActionBar
        submitLabel={`${isAdd ? 'Add' : 'Update'} Milestone`}
        disabled={formik.isSubmitting || !formik.isValid}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default DealEditTimelineMilestoneDialog;
