import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { DialogActionBar } from './dialog-action-bar';

const DealDeleteFundingDialog = ({
  open,
  funding,
  onClose,
  onSubmit,
  isLoading,
}) => {
  const handleSubmit = () => {
    onSubmit(funding);
  };

  if (!funding) {
    return <></>;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Remove Funding?</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant={'body1'}>
            This will remove all funding for this deal. You should only do this
            if you made a mistake.
          </Typography>
          <Stack direction={'row'} columnGap={2}>
            <Typography variant={'subtitle1'}>Funding To Remove:</Typography>
            <Typography variant={'h6'}>
              ${funding.amount.toLocaleString()}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActionBar
        isLoading={isLoading}
        loadingLabel={'Removing...'}
        submitLabel={'Remove Funding'}
        submitColor={'error'}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default DealDeleteFundingDialog;
