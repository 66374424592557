import { useState } from 'react';
import {
  useApproveDeal,
  useRejectDeal,
} from '../hooks/deals/use-deal-approval';
import DealApproveDialog from '../dialogs/deal-approve-dialog';
import DealRejectDialog from '../dialogs/deal-reject-dialog';
import { Alert, CircularProgress, ImageList, Stack } from '@mui/material';
import { DealManageItem } from './manage/deal-manage-item';

export default function DealApprovals({ deals, isLoading, setSnackbar }) {
  const [dealToApprove, setDealToApprove] = useState(null);
  const [dealToReject, setDealToReject] = useState(null);
  const approveDeal = useApproveDeal();
  const rejectDeal = useRejectDeal();

  const closeApprove = () => {
    setDealToApprove(null);
  };

  const openApprove = (deal) => {
    setDealToApprove(deal);
  };

  const handleApproveSubmit = async () => {
    try {
      approveDeal.mutate({
        id: dealToApprove.id,
      });
      setSnackbar({
        message:
          dealToApprove.name + ' has been approved is now publicly listed.',
      });
      closeApprove();
    } catch (e) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  const closeReject = () => {
    setDealToReject(null);
  };

  const openReject = (deal) => {
    setDealToReject(deal);
  };

  const handleRejectSubmit = async (message) => {
    try {
      rejectDeal.mutate({
        id: dealToReject.id,
        message,
      });
      closeReject();
      setSnackbar({
        message: `This deal has been rejected and message sent to the owner.`,
      });
    } catch (e) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  return (
    <>
      <DealApproveDialog
        open={dealToApprove !== null}
        title={dealToApprove?.name}
        isLoading={approveDeal.isLoading}
        onClose={closeApprove}
        onSubmit={handleApproveSubmit}
      />
      <DealRejectDialog
        open={dealToReject !== null}
        title={dealToReject?.name}
        onClose={closeReject}
        onSubmit={handleRejectSubmit}
        isLoading={rejectDeal.isLoading}
      />
      {isLoading ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : deals?.length > 0 ? (
        <ImageList cols={3} gap={5}>
          {deals.map((deal) => (
            <DealManageItem
              key={deal.id}
              deal={deal}
              isApproval
              onApproveClick={openApprove}
              onRejectClick={openReject}
            />
          ))}
        </ImageList>
      ) : (
        <Alert severity="success" sx={{ width: '100%' }}>
          {`You're all caught up! There are no Deals to approve at this time. 😎`}
        </Alert>
      )}
    </>
  );
}
