import { Button, Unstable_Grid2 as Grid } from '@mui/material';
import React from 'react';
import Stack from '@mui/material/Stack';
import { DealHeaderPublic } from './view/deal-header-public';

export const DealDetailsPublic = ({ values, onShowMore }) => {
  return (
    <Stack
      sx={{
        px: 2,
        pb: 10,
      }}
    >
      <Grid container spacing={4}>
        <DealHeaderPublic deal={values} />
        <Grid xs={12} align={'center'}>
          <Button variant="contained" onClick={onShowMore}>
            See the Full Listing
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};
