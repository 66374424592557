import { memo, useCallback, useEffect, useState } from 'react';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { PlacesLibraries } from './google-maps-utils';

function MapView({ markers, height = 300 }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: PlacesLibraries,
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // let bounds = new google.maps.LatLngBounds();
    // markers.forEach((location) => {
    //   bounds.extend({ lat: location.lat, lng: location.lng });
    // });
    // map.fitBounds(bounds);
    // map.setZoom(5);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      if (markers.length > 1) {
        let bounds = new window.google.maps.LatLngBounds();
        markers.forEach((location) => {
          bounds.extend({ lat: location.lat, lng: location.lng });
        });
        map.fitBounds(bounds);
      } else {
        map.setCenter(markers[0]);
      }
      map.setZoom(15);
    }
  }, [map]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: height + 'px',
      }}
      center={markers[0]}
      onUnmount={onUnmount}
      onLoad={onLoad}
    >
      {markers.map((marker, idx) => {
        return <MarkerF key={idx} position={marker} />;
      })}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default memo(MapView);
