import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import React from 'react';

export const KeyFieldStack = ({ label, value, valueDisplay }) => (
  <Stack sx={{ mb: 4 }}>
    <Typography variant="h6" align="left">
      {label}
    </Typography>
    <Typography variant="h6" align="left" color="text.secondary">
      {value ? valueDisplay : `--`}
    </Typography>
  </Stack>
);
