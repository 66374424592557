import {
  BookmarkAdded,
  DocumentScanner,
  FoodBank,
  MilitaryTech,
  NextPlan,
  Person,
  WorkspacePremium,
} from '@mui/icons-material';
import React from 'react';
import { Qualifications } from './qualifications';

export const InvestorCategory = {
  Individual: {
    icon: <MilitaryTech />,
    id: 'Individual',
    title: 'Individual',
    description: `I am an individual investor.`,
  },
  BusinessEntity: {
    icon: <BookmarkAdded />,
    id: 'BusinessEntity',
    title: 'Business Entity',
    description: `I am a corporation, limited liability company or partnership.`,
  },
  FamilyOffice: {
    icon: <WorkspacePremium />,
    id: 'FamilyOffice',
    title: 'Family Office',
    description: `I am a family office.`,
  },
  Trust: {
    icon: <Person />,
    id: 'Trust',
    title: 'Trust',
    description: `I am a Trust.`,
  },
  Institutional: {
    icon: <FoodBank />,
    id: 'Institutional',
    title: 'Institutional',
    description: `I am a bank, savings and loan association, insurance company, registered investment company,
business development company, or small business investment company or rural business
investment company.`,
  },
  EmployeeBenefitPlan: {
    icon: <NextPlan />,
    id: 'EmployeeBenefitPlan',
    title: 'Employee Benefit Plan',
    description: `I am an employee benefit plan (within the meaning of the Employee Retirement Income Security
Act) if a bank, insurance company, or registered investment adviser makes the investment
decisions, or if the plan has total assets in excess of $5 million.`,
  },
};

export const InvestorCategories = Object.keys(InvestorCategory).map(
  (key) => InvestorCategory[key],
);

export const QualificationChoices = (category) => {
  const choices = [];
  switch (category) {
    case InvestorCategory.Individual.id:
      choices.push({
        ...Qualifications.AccreditedInvestor,
        description: `I am an “accredited investor” under the Securities Act of 1933, as amended, because I
a) have a net worth in excess of $1 million (excluding my primary residence) or b) have annual
income in excess of $200,000 (or $300,000 with my spouse) for each of the past two calendar
years and expect to earn the same or greater income in the current calendar year.`,
      });
      choices.push({
        ...Qualifications.QualifiedClient,
        description: `I am a “qualified client” because I have at least $1.1 million in total assets under
management by a registered investment advisor or a net worth (together, with assets held jointly
with my spouse) of at least $2.2 million (excluding my primary residence).`,
      });
      choices.push({
        ...Qualifications.QualifiedPurchaser,
        description: `I am a “qualified purchaser” under the Investment Company Act of 1940, as amended,
because I own at least $5 million of “investments” (as defined by the Securities and Exchange
Commission (the “SEC”)).`,
      });
      choices.push({
        ...Qualifications.RIA,
        description: `I am a registered investment advisor registered with the SEC or my state’s securities
commission.`,
      });
      choices.push({
        ...Qualifications.BrokerDealer,
        description: `I am a broker-dealer registered with the SEC and the Financial Industry regulatory
Authority.`,
      });
      break;
    case InvestorCategory.BusinessEntity.id:
      choices.push({
        ...Qualifications.AccreditedInvestor,
        description: `I am an “accredited investor” under the Securities Act of 1933, as amended, because a) I
have at least $5 million in assets and was not formed for the specific purpose of a particular
investment, b) all of my equity owners are accredited investors under the Securities Act of 1933, as amended, or c) I am a tax exempt charitable organization, corporation, limited liability corporation, or
partnership with assets in excess of $5 million.`,
      });
      choices.push({
        ...Qualifications.QualifiedClient,
        description: `I am a “qualified client” because I have at least $1.1 million in total assets under
management by a registered investment advisor or a net worth of at least $2.2 million.`,
      });
      choices.push({
        ...Qualifications.QualifiedPurchaser,
        description: `I am a “qualified purchaser” under the Investment Company Act of 1940, as amended,
because a) I own not less than $5,000,000 in investments and am owned, directly or indirectly, by
or for two (2) or more natural persons who are related as siblings or spouse (including former
spouses), or direct lineal descendants by birth or adoption, spouses of such persons, the estates of
such persons, or foundations, charitable organizations, or trusts established by or for the benefit
of such persons, or b) I, acting for my own account or the accounts of other “qualified purchasers”, own and
invest on a discretionary basis at least $25 million in investments.`,
      });
      choices.push({
        ...Qualifications.RIA,
        description: `I am a registered investment advisor registered with the Securities and Exchange
Commission (the “SEC”) or my state’s securities commission.`,
      });
      choices.push({
        ...Qualifications.BrokerDealer,
        description: `I am a broker-dealer registered with the SEC and the Financial Industry regulatory
Authority.`,
      });
      break;
    case InvestorCategory.FamilyOffice.id:
      choices.push({
        ...Qualifications.AccreditedInvestor,
        description: `I am an “accredited investor” under the Securities Act of 1933, as amended, because I
have at least $5 million in assets and whose prospective investments are directed by a person
who has such knowledge and experience in financial and business matters that such family office
is capable of evaluating the merits and risks of the prospective investment, or b) all of my equity owners are accredited investors under the Securities Act of 1933, as amended.`,
      });
      choices.push({
        ...Qualifications.QualifiedClient,
        description: `I am a “qualified client” because I have at least $1.1 million in total assets under
management by a registered investment advisor or a net worth of at least $2.2 million.`,
      });
      choices.push({
        ...Qualifications.QualifiedPurchaser,
        description: `I am a “qualified purchaser” under the Investment Company Act of 1940, as amended,
because a) I own not less than $5,000,000 in investments and am owned, directly or indirectly, by
or for two (2) or more natural persons who are related as siblings or spouse (including former
spouses), or direct lineal descendants by birth or adoption, spouses of such persons, the estates of
such persons, or foundations, charitable organizations, or trusts established by or for the benefit
of such persons, or b) I, acting for my own account or the accounts of other “qualified purchasers”, own and
invest on a discretionary basis at least $25 million in investments.`,
      });
      break;
    case InvestorCategory.Trust.id:
      choices.push({
        ...Qualifications.AccreditedInvestor,
        description: `I am an “accredited investor” under the Securities Act of 1933, as amended, because I have total assets in excess of $5 million, was not
formed for the specific purpose of a particular investment and have a trustee with sufficient
knowledge and experience in financial and business matters that it is capable of evaluating the
merits and risks of the prospective investment.`,
      });
      choices.push({
        ...Qualifications.QualifiedClient,
        description: `I am a “qualified client” because I have at least $1.1 million in total assets under
management by a registered investment advisor or a net worth of at least $2.2 million.`,
      });
      choices.push({
        ...Qualifications.QualifiedPurchaser,
        description: `I am a “qualified purchaser” under the Investment Company Act of 1940, as amended, because a) I was not formed for the specific purposes of a
particular investment and each person who is authorized to make decisions with respect to the
trust (e.g., the trustee) and each settlor or other person who has contributed asset to the trust, is a
“qualified purchaser”, or b) I, acting for my own account or the accounts of other “qualified purchasers”, own and
invest on a discretionary basis at least $25 million in investments.`,
      });
      break;
    case InvestorCategory.Institutional.id:
      choices.push({
        ...Qualifications.AccreditedInvestor,
        description: `I am an “accredited investor” under the Securities Act of 1933, as amended, because I am
a bank, savings and loan association, insurance company, registered investment company, business
development company, or small business investment company or rural business investment
company.`,
      });
      choices.push({
        ...Qualifications.QualifiedPurchaser,
        description: `I am a “qualified purchaser” under the Investment Company Act of
1940, as amended, because I, acting for my own account or the accounts of other
qualified purchasers, own and invest on a discretionary basis, not less than
$25,000,000 in investments.`,
      });
      break;
    case InvestorCategory.EmployeeBenefitPlan.id:
      choices.push({
        ...Qualifications.AccreditedInvestor,
        description: `I am an “accredited investor” under the Securities Act of 1933, as amended, because I am
(a) a plan established and maintained by a state, its political subdivisions, or any agency or
instrumentality of a state or its political subdivisions, for the benefit of its employees, and have
total assets in excess of $5 million, or (b) an employee benefit plan (within the meaning of the
Employee Retirement Income Security Act) if a bank, insurance company, or registered
investment adviser makes the investment decisions, or if the plan has total assets in excess of
$5 million.`,
      });
      choices.push({
        ...Qualifications.QualifiedPurchaser,
        description: `I am a “qualified purchaser” under the Investment Company Act of 1940, as amended,
because I invest on a discretionary basis, not less than $25,000,000 in investments,
and participants in the plan are not permitted to decide whether or how much to invest in
particular investment alternatives, and the decision to invest is made by the plan trustee or
other plan fiduciary that makes investment decisions for the plan.`,
      });
      break;
  }
  return choices;
};
