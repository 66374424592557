import { Stack, Table, TableBody, Typography } from '@mui/material';
import React from 'react';
import {
  DealReturnMetric,
  DealReturnMetricKey,
  DEFAULT_EXP_RETURNS,
} from '../../../helpers/exp-returns';
import { ExpReturnRow } from './deal-exp-returns-row';

export const DealExpReturns = ({ initialValue }) => {
  const form = initialValue ? JSON.parse(initialValue) : DEFAULT_EXP_RETURNS;

  return (
    <Stack spacing={2}>
      <Table padding="none" size={'small'}>
        <TableBody>
          {form[DealReturnMetricKey.IRR] !== '' && (
            <ExpReturnRow
              label={DealReturnMetric[DealReturnMetricKey.IRR].label}
              value={form[DealReturnMetricKey.IRR]}
              unit={DealReturnMetric[DealReturnMetricKey.IRR].unit}
              isView
            />
          )}
          {form[DealReturnMetricKey.CashOnCash] !== '' && (
            <ExpReturnRow
              label={DealReturnMetric[DealReturnMetricKey.CashOnCash].label}
              value={form[DealReturnMetricKey.CashOnCash]}
              unit={DealReturnMetric[DealReturnMetricKey.CashOnCash].unit}
              isView
            />
          )}
          {form.customMetrics?.map((metric, index) => (
            <ExpReturnRow
              key={index}
              label={metric.label}
              value={metric.value}
              unit={metric.unit}
              isView
            />
          ))}
        </TableBody>
      </Table>
      <Typography variant="caption" color="text.secondary">
        *{' '}
        <i>
          Please carefully review the Deal Summary and Disclaimers sections,
          including Sponsor’s assumptions about expected returns.
        </i>
      </Typography>
    </Stack>
  );
};
