import { useApi } from '../api/use-api';
import { useMutation, useQueryClient } from 'react-query';
import { handleMutate } from '../util/use-handle-mutate';
import {
  DealQueryKeys,
  setDealInDealQueryData,
  setDealQueryDataToPrevious,
  updateDealInList,
} from './deals-hook-utils';
import { RetryConfig } from '../api/retry-config';

const sortByClosedAt = (f1, f2) => {
  if (f1.closedAt < f2.closedAt) {
    return -1;
  }
  if (f1.closedAt > f2.closedAt) {
    return 1;
  }
  return 0;
};

const addFundingInDeal = (deal, newFunding) => {
  const updated = [...deal.funding];
  updated.push({
    ...newFunding,
    closedAt: newFunding.closedAt.toISOString(),
  });
  updated.sort(sortByClosedAt);
  deal.funding = updated;
  return deal;
};

const updateFundingInDeal = (deal, fundingId, newFunding) => {
  const fundingIdx = deal.funding.findIndex((f) => f.id === fundingId);
  const updated = [...deal.funding];
  updated[fundingIdx] = {
    ...deal.funding[fundingIdx],
    ...newFunding,
    closedAt: newFunding.closedAt.toISOString(),
  };
  updated.sort(sortByClosedAt);
  deal.funding = updated;
  return deal;
};

const removeFundingInDeal = (deal, fundingId) => {
  deal.funding = deal.funding.filter((df) => df.id !== fundingId);
  return deal;
};

const useAddDealFunding = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, createFundingRequest }) =>
      request({
        url: `/deals/${dealId}/funding`,
        method: 'POST',
        body: createFundingRequest,
      }),
    {
      onMutate: async ({ dealId, createFundingRequest }) => {
        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              addFundingInDeal(deal, createFundingRequest),
            ),
        );
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              addFundingInDeal(deal, createFundingRequest),
            ),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(dealId),
          (old) => addFundingInDeal({ ...old }, createFundingRequest),
        );

        return {
          prevSearch,
          prevManage,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [DealQueryKeys.searches(), DealQueryKeys.manage()],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

const useUpdateDealFunding = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, fundingId, updated }) =>
      request({
        url: `/deals/${dealId}/funding/${fundingId}`,
        method: 'PATCH',
        body: updated,
      }),
    {
      onMutate: async ({ dealId, fundingId, updated }) => {
        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              updateFundingInDeal(deal, fundingId, updated),
            ),
        );
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              updateFundingInDeal(deal, fundingId, updated),
            ),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(dealId),
          (old) => updateFundingInDeal({ ...old }, fundingId, updated),
        );

        return {
          prevSearch,
          prevManage,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [DealQueryKeys.searches(), DealQueryKeys.manage()],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

const useDeleteDealFunding = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, fundingId }) =>
      request({
        url: `/deals/${dealId}/funding/${fundingId}`,
        method: 'DELETE',
      }),
    {
      onMutate: async ({ dealId, fundingId }) => {
        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              removeFundingInDeal(deal, fundingId),
            ),
        );
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              removeFundingInDeal(deal, fundingId),
            ),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(dealId),
          (old) => removeFundingInDeal({ ...old }, fundingId),
        );

        return {
          prevSearch,
          prevManage,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [DealQueryKeys.searches(), DealQueryKeys.manage()],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

export { useAddDealFunding, useUpdateDealFunding, useDeleteDealFunding };
