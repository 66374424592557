export const DEFAULT_CAP_STRUCTURE = {
  investors: [
    { name: 'LP Investors', amount: 0 },
    { name: 'Sponsors', amount: 0 },
    { name: 'Other', amount: 0 },
  ],
  debt: [
    { name: 'First Mortgage', amount: 0 },
    { name: 'Mezzanine', amount: 0 },
  ],
};

export const EMPTY_CAP_STRUCTURE = { investors: [], debt: [] };
