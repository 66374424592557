import React, { useEffect, useState } from 'react';
import { Button, Stack, Tooltip } from '@mui/material';
import DealInvestStatusDialog from '../../dialogs/deal-invest-status-dialog';
import { useUpdateDealInvestStatus } from '../../hooks/deals/use-deal-invest-status';
import copy from 'copy-to-clipboard';
import Link from '@mui/icons-material/Link';
import { getDealShareLink } from '../../../helpers/get-share-link';
import { InvestStatusButton } from '../invest-status-button';
import { ContactButton } from '../contact-button';
import { WatchlistButton } from '../watchlist-button';

const DealDialogs = {
  CreateThread: 'CreateThread',
  InvestStatus: 'InvestStatus',
  UserQualify: 'UserQualify',
};

export const DealActionsInvestor = ({
  deal,
  isLoading,
  setSnackbar,
  openDialog,
}) => {
  const [showDialog, setShowDialog] = useState(openDialog);
  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  useEffect(() => {
    if (shareLinkCopied) {
      setTimeout(() => setShareLinkCopied(false), 3000);
    }
  }, [shareLinkCopied]);

  const updateInvestStatus = useUpdateDealInvestStatus();

  if (isLoading) {
    return <></>;
  }

  const closeDialogs = () => {
    setShowDialog(null);
  };

  const openUpdateInvestStatus = (e) => {
    e.stopPropagation();
    setShowDialog(DealDialogs.InvestStatus);
  };

  const handleSubmitInvestStatus = async ({
    dealId,
    status,
    amount,
    reason,
  }) => {
    try {
      updateInvestStatus.mutate({
        dealId,
        status,
        amount,
        reason,
      });
      setSnackbar({ message: 'Investment status has been updated.' });
      closeDialogs();
    } catch (e) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  const handleCopyShareLink = (e) => {
    e.stopPropagation();
    copy(getDealShareLink(deal));
    setShareLinkCopied(true);
  };

  return (
    <>
      <DealInvestStatusDialog
        open={showDialog === DealDialogs.InvestStatus}
        deal={deal}
        onClose={closeDialogs}
        onSubmit={handleSubmitInvestStatus}
      />
      <Stack
        spacing={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: 2 }}
      >
        <Tooltip
          placement={'bottom'}
          title={
            shareLinkCopied
              ? 'Share Link Copied to Clipboard!'
              : 'Copy Share Link'
          }
        >
          <Stack alignItems={'center'}>
            <Button
              size={'small'}
              variant={'text'}
              onClick={handleCopyShareLink}
              startIcon={<Link />}
            >
              Get Share Link
            </Button>
          </Stack>
        </Tooltip>
        <WatchlistButton fullWidth deal={deal} setSnackbar={setSnackbar} />
        <ContactButton deal={deal} fullWidth setSnackbar={setSnackbar} />
        <InvestStatusButton
          deal={deal}
          onClick={openUpdateInvestStatus}
          fullWidth
        />
      </Stack>
    </>
  );
};
