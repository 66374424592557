import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { DEFAULT_CAP_STRUCTURE } from '../../../helpers/cap-structure';

export const DealCapStructure = ({ initialValue }) => {
  const form = initialValue ? JSON.parse(initialValue) : DEFAULT_CAP_STRUCTURE;

  const totalEquity = form.investors.reduce(
    (prev, curr) => (prev += curr.amount),
    0,
  );
  const totalDebt = form.debt.reduce((prev, curr) => (prev += curr.amount), 0);
  const totalSources = totalEquity + totalDebt;

  return (
    <Stack sx={{ mt: 1 }}>
      <Table padding="normal" size={'small'}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align={'right'} sx={{ py: 2 }}>
              Gross
            </TableCell>
            <TableCell align={'right'}>% of Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>
                <u>Equity</u>
              </b>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          {form.investors
            .sort((i1, i2) => (i1.amount > i2.amount ? -1 : 1))
            .map(({ name, amount }, idx) => {
              return (
                <TableRow key={name}>
                  <TableCell>{name}</TableCell>
                  <TableCell align={'right'}>
                    {amount.toLocaleString()}
                  </TableCell>
                  <TableCell align={'right'}>
                    {totalSources
                      ? Math.round((amount * 100) / totalSources)
                      : 0}
                    %
                  </TableCell>
                </TableRow>
              );
            })}
          <TableRow>
            <TableCell sx={{ borderBottom: '1px solid grey' }}>
              <b>Total Equity</b>
            </TableCell>
            <TableCell align={'right'} sx={{ borderBottom: '1px solid grey' }}>
              <b>{totalEquity.toLocaleString()}</b>
            </TableCell>
            <TableCell align={'right'} sx={{ borderBottom: '1px solid grey' }}>
              <b>
                {totalSources
                  ? Math.round((totalEquity * 100) / totalSources)
                  : 0}
                %
              </b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>
                <u>Debt</u>
              </b>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          {form.debt
            .sort((i1, i2) => (i1.amount > i2.amount ? -1 : 1))
            .map(({ name, amount }, idx) => {
              return (
                <TableRow key={name}>
                  <TableCell>{name}</TableCell>
                  <TableCell align={'right'}>
                    {amount.toLocaleString()}
                  </TableCell>
                  <TableCell align={'right'}>
                    {totalSources
                      ? Math.round((amount * 100) / totalSources)
                      : 0}
                    %
                  </TableCell>
                </TableRow>
              );
            })}
          <TableRow>
            <TableCell sx={{ borderBottom: '1px solid grey' }}>
              <b>Total Debt</b>
            </TableCell>
            <TableCell align={'right'} sx={{ borderBottom: '1px solid grey' }}>
              <b>{totalDebt.toLocaleString()}</b>
            </TableCell>
            <TableCell align={'right'} sx={{ borderBottom: '1px solid grey' }}>
              <b>
                {totalSources
                  ? Math.round((totalDebt * 100) / totalSources)
                  : 0}
                %
              </b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Total Sources</b>
            </TableCell>
            <TableCell align={'right'}>
              <b>{totalSources.toLocaleString()}</b>
            </TableCell>
            <TableCell align={'right'}>
              <b>{totalSources ? 100 : 0}%</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
};
