import { oxfordComma } from './strings';
import {
  BookmarkAdded,
  CardGiftcard,
  MilitaryTech,
  Person,
  WorkspacePremium,
} from '@mui/icons-material';
import React from 'react';

export const Qualifications = {
  AccreditedInvestor: {
    icon: <MilitaryTech />,
    title: 'I am an Accredited Investor',
    value: 'isAccreditedInvestor',
  },
  QualifiedClient: {
    icon: <BookmarkAdded />,
    title: 'I am a Qualified Client',
    value: 'isQualifiedClient',
  },
  QualifiedPurchaser: {
    icon: <WorkspacePremium />,
    title: 'I am a Qualified Purchaser',
    value: 'isQualifiedPurchaser',
  },
  RIA: {
    icon: <Person />,
    title: 'I am a Registered Investment Advisor',
    value: 'isRIA',
  },
  BrokerDealer: {
    icon: <CardGiftcard />,
    title: 'I am a Broker / Dealer',
    value: 'isBrokerDealer',
  },
};

export const oxfordQualifications = (qualification) => {
  let quals = [];
  if (qualification?.isAccreditedInvestor) {
    quals.push('an Accredited Investor');
  }
  if (qualification?.isQualifiedClient) {
    quals.push('a Qualified Client');
  }
  if (qualification?.isQualifiedPurchaser) {
    quals.push('a Qualified Purchaser');
  }
  if (qualification?.isRIA) {
    quals.push('a Registered Investment Advisor');
  }
  if (quals.length === 0) {
    quals.push('not a qualified investor');
  }
  return oxfordComma(quals);
};
