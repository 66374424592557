import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import { QualificationForm } from './qualification-form';
import {
  InvestorCategories,
  InvestorCategory,
  QualificationChoices,
} from '../../helpers/investor-categories';

export const SettingsQualification = ({ userProfile, onSubmit, isLoading }) => {
  const isReadOnly =
    userProfile.qualification.category &&
    (userProfile?.qualification?.isAccreditedInvestor ||
      userProfile?.qualification?.isQualifiedClient ||
      userProfile?.qualification?.isQualifiedPurchaser ||
      userProfile?.qualification?.isRIA ||
      userProfile?.qualification?.isBrokerDealer);

  const initialValues = {
    category: userProfile?.qualification.category,
    isAccreditedInvestor:
      userProfile?.qualification?.isAccreditedInvestor || false,
    isQualifiedClient: userProfile?.qualification?.isQualifiedClient || false,
    isQualifiedPurchaser:
      userProfile?.qualification?.isQualifiedPurchaser || false,
    isRIA: userProfile?.qualification?.isRIA || false,
    isBrokerDealer: userProfile?.qualification?.isBrokerDealer || false,
    isNotQualified: userProfile?.qualification?.isNotQualified || false,
    submit: null,
  };

  const validationShape = {};

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, helpers) => {
      try {
        onSubmit({ ...values, submit: undefined });
        formik.setValues({ ...values, submit: null });
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (userProfile) {
      formik.setValues({
        category: userProfile?.qualification.category,
        isAccreditedInvestor:
          userProfile?.qualification?.isAccreditedInvestor || false,
        isQualifiedClient:
          userProfile?.qualification?.isQualifiedClient || false,
        isQualifiedPurchaser:
          userProfile?.qualification?.isQualifiedPurchaser || false,
        isRIA: userProfile?.qualification?.isRIA || false,
        isBrokerDealer: userProfile?.qualification?.isBrokerDealer || false,
        isNotQualified: userProfile?.qualification?.isNotQualified || false,
        submit: null,
      });
    }
  }, [userProfile]);

  const isChanged =
    Object.entries(formik.values).reduce((acc, [key, value]) => {
      const compare = userProfile?.qualification?.[key] || false;
      const hasChanged =
        key !== 'submit' && JSON.stringify(compare) !== JSON.stringify(value);
      if (hasChanged) {
        acc = acc + 1;
      }
      return acc;
    }, 0) > 0;

  const qualficationChoices =
    QualificationChoices(formik.values.category) || [];

  const handleInvestorCategoryChange = (e) => {
    formik.setValues({
      ...formik.values,
      isAccreditedInvestor: false,
      isQualifiedClient: false,
      isQualifiedPurchaser: false,
      isRIA: false,
      isBrokerDealer: false,
    });
    formik.setFieldValue('category', e.target.value);
  };

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader
          subheader={
            isReadOnly
              ? 'To change this information, contact support@opportunityzonedeals.com'
              : 'Change your investor category'
          }
          title="What Category of Investor are you?"
        />
        <Divider />
        <CardContent>
          <TextField
            sx={{ width: '50%' }}
            label="Investor Category"
            name="category"
            onChange={handleInvestorCategoryChange}
            value={formik.values.category}
            select
            disabled={isReadOnly}
            readOnly={isReadOnly}
            helperText={InvestorCategory[formik.values.category]?.description}
          >
            {InvestorCategories.map((ic) => (
              <MenuItem key={ic.id} value={ic.id}>
                <ListItemText primary={ic.title} />
              </MenuItem>
            ))}
          </TextField>
        </CardContent>
        {qualficationChoices.length > 0 && (
          <>
            <Divider />
            <CardContent>
              <Typography variant={'h6'}>Investor Qualifications</Typography>
              <Typography
                variant={'body2'}
                color={'text.secondary'}
                sx={{ pb: 2 }}
              >
                {isReadOnly
                  ? 'To change this information, contact support@opportunityzonedeals.com'
                  : 'Change your investor qualifications'}
              </Typography>
              <QualificationForm
                formik={formik}
                choices={qualficationChoices}
                disabled={isReadOnly}
              />
              <Box sx={{ mt: 2 }}>
                <Typography variant={'caption'}>
                  <i>
                    OZD has no obligation to, and does not, monitor or review
                    the qualifications of prospective investors. Sponsors have
                    the sole right to determine the investor qualifications for
                    any particular Opportunity Zone deal they sponsor, which may
                    require its investors to have higher net worth, greater
                    assets or other qualifications. Even if you indicate your
                    good faith belief that you currently satisfy any or all of
                    the above criteria, you may be requested by Sponsors to
                    provide additional information verifying such status in
                    connection with making each investment. Your certification
                    above shall not guarantee any right to participate in or
                    invest in such investment opportunity.
                  </i>
                </Typography>
              </Box>
            </CardContent>
          </>
        )}

        {!isReadOnly && (
          <>
            <Divider />
            {isLoading ? (
              <CardActions sx={{ justifyContent: 'flex-end', my: 1 }}>
                <CircularProgress size={20} />
                <Button disabled color={'primary'}>
                  Saving...
                </Button>
              </CardActions>
            ) : (
              <CardActions sx={{ justifyContent: 'flex-end', my: 1 }}>
                <Button
                  variant="contained"
                  disabled={isLoading || !formik.isValid || !isChanged}
                  type="submit"
                >
                  Save Changes
                </Button>
              </CardActions>
            )}
          </>
        )}
      </Card>
    </form>
  );
};
