import {
  Card,
  CardActions,
  CardContent,
  ImageListItem,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import UnpublishedOutlined from '@mui/icons-material/UnpublishedOutlined';
import Inventory2Outlined from '@mui/icons-material/Inventory2Outlined';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import LinkOutlined from '@mui/icons-material/LinkOutlined';
import Preview from '@mui/icons-material/Preview';
import Approval from '@mui/icons-material/Approval';
import { useNavigate } from 'react-router-dom';
import { useSessionUser } from '../../hooks/session/useSessionUser';
import { Role } from '../../enums/role.enum';
import { DealStatus } from '../../enums/deal-status.enum';
import copy from 'copy-to-clipboard';
import { getDealShareLink } from '../../../helpers/get-share-link';
import { DealStatusChip } from '../chips/deal-status-chip';
import MoreEllipsesMenu from '../../util/more-ellipses-menu';
import { DealInfoBlock } from '../deal-info-block';
import { DoDisturb } from '@mui/icons-material';

export const DealManageItem = (props) => {
  const {
    deal,
    onCopyClick,
    onArchiveClick,
    onApproveClick,
    onRejectClick,
    onUnlistClick,
    isApproval,
    setSnackbar,
  } = props;
  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();
  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isOrgAdmin = sessionUser?.role === Role.OrgAdmin;

  const [shareLinkCopied, setShareLinkCopied] = useState(false);

  useEffect(() => {
    if (shareLinkCopied) {
      setTimeout(() => setShareLinkCopied(false), 3000);
    }
  }, [shareLinkCopied]);

  const isApproved = deal?.status === DealStatus.Approved;
  const isPendingApproval = deal?.status === DealStatus.PendingReview;

  const isOwner =
    (isOrgAdmin && deal?.orgId === sessionUser?.orgId) ||
    deal?.ownerId === sessionUser?.id;

  const handleCopy = (e) => {
    e.stopPropagation();
    onCopyClick(deal);
  };

  const handleArchive = (e) => {
    e.stopPropagation();
    onArchiveClick(deal);
  };

  const handleApprove = (e) => {
    e.stopPropagation();
    onApproveClick(deal);
  };

  const handleReject = (e) => {
    e.stopPropagation();
    onRejectClick(deal);
  };

  const handleUnlist = (e) => {
    e.stopPropagation();
    onUnlistClick(deal);
  };

  const handleView = (e) => {
    e.stopPropagation();
    navigate('/deals/' + deal.id);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    navigate('/deals/' + deal.id + '/edit');
  };

  const handleCopyShareLink = (e) => {
    e.stopPropagation();
    copy(getDealShareLink(deal));
    setSnackbar({ message: 'Share link has been copied' });
  };

  const menuItems = [];

  if (isApproval) {
    menuItems.push({
      primary: 'Approve this Deal',
      secondary: 'Make this deal viewable to all registered users of OZD',
      icon: <Approval />,
      onClick: handleApprove,
      color: 'success',
    });
    menuItems.push({
      primary: 'Reject this Deal',
      secondary: 'Send this deal back to the sponsor including reasons',
      icon: <DoDisturb />,
      onClick: handleReject,
      color: 'error',
    });
  }

  menuItems.push({
    primary: 'View Deal Page',
    secondary: 'View the shareable deal page for this deal',
    icon: <Preview />,
    onClick: handleView,
  });

  if (isSysAdmin || !isApproved) {
    menuItems.push({
      primary: 'Edit Details',
      secondary: 'Change the details about this deal',
      icon: <EditOutlined />,
      onClick: handleEdit,
    });
  }

  if (!isSysAdmin) {
    menuItems.push({
      primary: 'Save As New',
      secondary: 'Copy this deal into a new deal',
      icon: <FileCopyOutlined />,
      onClick: handleCopy,
    });
  }

  if (isApproved) {
    menuItems.push({
      primary: 'Get Share Link',
      secondary: 'Share this deal on the web',
      icon: <LinkOutlined />,
      onClick: handleCopyShareLink,
    });
  }

  if ((isApproved || isPendingApproval) && (isSysAdmin || isOwner)) {
    menuItems.push({
      primary: isPendingApproval
        ? 'Remove from Review'
        : 'Remove from Publication',
      secondary: isPendingApproval
        ? 'Cancel submission and set to Draft'
        : 'Remove from public listing and set to Draft',
      icon: <UnpublishedOutlined />,
      onClick: handleUnlist,
      color: 'error',
    });
  }

  if (!isSysAdmin && !isApproved) {
    menuItems.push({
      primary: 'Archive',
      secondary: 'Remove this deal from your list',
      icon: <Inventory2Outlined />,
      onClick: handleArchive,
    });
  }

  return (
    <ImageListItem
      sx={{
        p: 1,
      }}
      key={deal.id}
    >
      <Card elevation={3}>
        <CardContent
          sx={{
            cursor: 'pointer',
          }}
          onClick={handleView}
        >
          <Stack spacing={1}>
            <DealInfoBlock deal={deal} />
            {deal.owner.id !== sessionUser?.id && (
              <Typography variant={'caption'} color={'text.secondary'}>
                <i>Owned by {deal.owner.name}</i>
              </Typography>
            )}
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            px: 2,
            borderTop: '0.5px solid lightgrey',
            justifyContent: 'space-between',
          }}
        >
          <DealStatusChip deal={deal} />
          <MoreEllipsesMenu items={menuItems} />
        </CardActions>
      </Card>
    </ImageListItem>
  );
};
