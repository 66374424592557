import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Manage } from './deals/manage';
import { Role } from '../components/enums/role.enum';
import { useSessionUser } from '../components/hooks/session/useSessionUser';
import NotFoundErrorPage from './error/NotFoundErrorPage';
import { Backdrop, CircularProgress } from '@mui/material';
import Search from './deals/search';
import Watchlist from './deals/watchlist';
import Notifications from './notifications/notifications';
import Messages from './messages/messages';
import Archived from './deals/archived';
import Approvals from './approvals/approvals';
import AccountSettings from './settings/account';
import OrganizationSettings from './settings/organization';
import CreateDeal from './deals/deal/create';
import ViewDeal from './deals/deal/view';
import EditDeal from './deals/deal/edit';
import ViewOrganization from './organizations/organization/view';
import Billing from './settings/billing';

export const Main = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { sessionUser } = useSessionUser();
  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isOrgAdmin = sessionUser?.role === Role.OrgAdmin;
  const isInvestor = sessionUser?.role === Role.Investor;

  useEffect(() => {
    Sentry.setUser({
      id: sessionUser?.id,
      email: sessionUser?.email,
    });
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      const home = isSysAdmin
        ? '/approvals'
        : isOrgAdmin
          ? '/deals'
          : '/search';
      navigate(home);
    }
  }, [location]);

  //prevents flicker during redirect
  if (location.pathname === '/') {
    return (
      <Backdrop
        sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Routes>
      {isSysAdmin && <Route path="/approvals" element={<Approvals />} />}
      {(isSysAdmin || isOrgAdmin) && (
        <>
          <Route exact path="/deals" element={<Manage />} />
          <Route exact path="/deals/archived" element={<Archived />} />
          <Route exact path="/deals/create" element={<CreateDeal />} />
          <Route exact path="/deals/:id/edit" element={<EditDeal />} />
        </>
      )}
      <Route exact path="/search" element={<Search />} />
      <Route exact path="/deals/:id" element={<ViewDeal />} />
      <Route exact path="/organizations/:id" element={<ViewOrganization />} />
      {isInvestor && <Route path="/watchlist" element={<Watchlist />} />}
      {!isSysAdmin && (
        <Route path="/notifications" element={<Notifications />} />
      )}
      {!isSysAdmin && <Route path="/messages" element={<Messages />} />}
      <Route path="/settings/account" element={<AccountSettings />} />
      {(isSysAdmin || isOrgAdmin) && (
        <>
          <Route path="/subscriptions" element={<Billing />} />
          <Route
            path="/settings/organization"
            element={<OrganizationSettings />}
          />
        </>
      )}
      {!isSysAdmin && <Route path="/messages" element={<div>Messages</div>} />}
      <Route path="*" element={<NotFoundErrorPage />} />
    </Routes>
  );
};
