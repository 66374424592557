import { handleMutate } from '../util/use-handle-mutate';
import {
  DealQueryKeys,
  enrichDeal,
  setDealInDealQueryData,
  setDealQueryDataToPrevious,
  updateDealInList,
} from './deals-hook-utils';
import { useMutation, useQueryClient } from 'react-query';
import { useApi } from '../api/use-api';
import { RetryConfig } from '../api/retry-config';

const updateDealSubmittedAt = (deal) => {
  deal.approvedAt = null;
  deal.submittedAt = new Date().toISOString();
  return enrichDeal(deal);
};

const useSubmitDeal = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ deal }) =>
      request({ url: `/deals/${deal.id}/submit`, method: 'POST' }),
    {
      onMutate: async ({ deal }) => {
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, deal.id, (deal) =>
              updateDealSubmittedAt(deal),
            ),
        );
        const prevWatchlist = await handleMutate(
          queryClient,
          DealQueryKeys.watchlist(),
          (old) =>
            updateDealInList(old, deal.id, (deal) =>
              updateDealSubmittedAt(deal),
            ),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(deal.id),
          (old) => updateDealSubmittedAt({ ...old }),
        );
        return {
          prevManage,
          prevWatchlist,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(DealQueryKeys.manage(), previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [DealQueryKeys.manage(), DealQueryKeys.watchlist()],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

const updateDealApprovedAt = (deal) => {
  deal.approvedAt = new Date().toISOString();
  return enrichDeal(deal);
};

const useApproveDeal = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id }) =>
      request({
        url: `/deals/${id}/approve`,
        method: 'POST',
      }),
    {
      onMutate: async ({ id }) => {
        const prevApprovals = await handleMutate(
          queryClient,
          DealQueryKeys.approvals(),
          (old) => old.filter((deal) => deal.id !== id),
        );
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, id, (deal) => updateDealApprovedAt(deal)),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(id),
          (old) => updateDealApprovedAt({ ...old }),
        );

        return {
          prevManage,
          prevApprovals,
          prevDetail,
        };
      },
      // On failure, roll back to the previous value
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [DealQueryKeys.approvals(), DealQueryKeys.manage()],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

const updateDealToRejected = (deal) => {
  deal.approvedAt = null;
  deal.submittedAt = null;
  return enrichDeal(deal);
};

const useRejectDeal = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, message }) =>
      request({
        url: `/deals/${id}/reject`,
        method: 'POST',
        body: { message },
      }),
    {
      onMutate: async ({ id }) => {
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, id, (deal) => updateDealToRejected(deal)),
        );
        const prevWatchlist = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, id, (deal) => updateDealToRejected(deal)),
        );
        const prevApprovals = await handleMutate(
          queryClient,
          DealQueryKeys.approvals(),
          (old) => old.filter((deal) => deal.id !== id),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(id),
          (old) => updateDealToRejected({ ...old }),
        );

        return {
          prevManage,
          prevWatchlist,
          prevApprovals,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [
            DealQueryKeys.manage(),
            DealQueryKeys.approvals(),
            DealQueryKeys.watchlist(),
          ],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

const useUnlistDeal = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ deal }) =>
      request({
        url: `/deals/${deal.id}/unlist`,
        method: 'POST',
      }),
    {
      onMutate: async ({ deal }) => {
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, deal.id, (deal) =>
              updateDealToRejected(deal),
            ),
        );
        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) => old.filter((deal) => deal.id !== deal.id),
        );
        const prevApprovals = await handleMutate(
          queryClient,
          DealQueryKeys.approvals(),
          (old) => old.filter((deal) => deal.id !== deal.id),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(deal.id),
          (old) => updateDealToRejected({ ...old }),
        );

        return {
          prevManage,
          prevSearch,
          prevApprovals,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSettled: (data, variables, context) => {
        queryClient.invalidateQueries(DealQueryKeys.all).then();
      },
      ...RetryConfig(),
    },
  );
};

export { useApproveDeal, useSubmitDeal, useRejectDeal, useUnlistDeal };
