import { Typography, Unstable_Grid2 as Grid } from '@mui/material';
import React from 'react';
import Stack from '@mui/material/Stack';
import { DealBanner } from '../deal-banner';
import { DealType } from '../../enums/deal-type.enum';
import { StorageBucket } from '../../../helpers/supabase';
import { DealImages } from './deal-images';
import { neutral } from '../../theme/colors';
import Photo from '@mui/icons-material/Photo';
import { DealPublishedKeyFields } from './deal-published-key-fields';

const IMG_WIDTH = 458;
const IMG_HEIGHT = 275;

export const DealHeaderPublic = ({ deal, isSponsorPage }) => {
  const isSale = deal?.type === DealType.Sale;

  const galleryImages = deal.images?.length
    ? deal.images?.map((img) => {
        const imageSrc = `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.OFFERING_IMAGES}/${deal.id}/${encodeURIComponent(img.name)}`;
        return {
          original: imageSrc,
          thumbnail: imageSrc,
        };
      })
    : null;
  const hasImg = galleryImages?.length;
  return (
    <>
      <Grid xs={12}>
        <DealBanner deal={deal} />
      </Grid>
      <Grid xs={6}>
        {hasImg ? (
          <>
            {isSponsorPage ? (
              <img
                src={`${galleryImages[0].original}?fit=crop&auto=format`}
                alt={deal.name}
                style={{ width: '100%', height: IMG_HEIGHT, cursor: 'pointer' }}
              />
            ) : (
              <DealImages deal={deal} isSponsorPage={isSponsorPage} />
            )}
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: neutral[200],
              borderRadius: 5,
            }}
          >
            <Photo style={{ width: '100%', height: IMG_HEIGHT }} />
          </div>
        )}
      </Grid>
      <Grid xs={6}>
        <Stack alignItems="center" spacing={1}>
          <Typography
            align={'center'}
            sx={{
              typography: {
                xs: 'h4',
                md: 'h3',
              },
            }}
          >
            {deal.name}
          </Typography>
          {deal.city && deal.state && (
            <Typography variant="h5">
              {deal.city}, {deal.state}
            </Typography>
          )}
          {isSale && (
            <Typography variant={'h4'} color={'text.secondary'}>
              Price: ${deal?.purchasePrice?.toLocaleString()}
            </Typography>
          )}
        </Stack>
        <Grid container sx={{ mt: 4 }}>
          <DealPublishedKeyFields values={deal} />
        </Grid>
      </Grid>
      {deal.videoUrl && (
        <Grid xs={12}>
          <iframe
            title="video"
            src={deal.videoUrl}
            style={{
              width: '100%',
              height: '500px',
              border: 'none',
            }}
          ></iframe>
        </Grid>
      )}
    </>
  );
};
