export const DealSector = {
  AffordableHousing: 'Affordable Housing',
  AssistedLiving: 'Assisted Living',
  // ColdStorage: 'Cold Storage',
  DataCenter: 'Data Center',
  // Education: 'Education',
  // EnergyDevelopment: 'Energy Development',
  // Farmland: 'Farmland',
  // FulfillmentDistributionCenter: 'Fulfillment / Distribution Center',
  // Healthcare: 'Healthcare',
  Hospitality: 'Hospitality',
  Industrial: 'Industrial',
  // Infrastructure: 'Infrastructure',
  // MemoryCare: 'Memory Care',
  MixedUse: 'Mixed-Use',
  MultiFamilyHousing: 'Multi-Family Housing',
  Office: 'Office',
  Parking: 'Parking',
  Retail: 'Retail',
  SelfStorage: 'Self Storage',
  SeniorHousing: 'Senior Housing',
  SingleFamilyHousing: 'Single-Family Housing',
  Entertainment: 'Entertainment/Recreation',
  StudentHousing: 'Student Housing',
  Townhouses: 'Townhouses',
  VeteransHousing: 'Veterans Housing',
  Warehouse: 'Warehouse',
  WorkforceHousing: 'Workforce Housing',
};
