import {
  Box,
  Card,
  Fab,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import Approval from '@mui/icons-material/Approval';
import { Link, useNavigate } from 'react-router-dom';

export const OrganizationsTable = ({ items = [], onApproveClick }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Website</TableCell>
              <TableCell>Status</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((org) => {
              const handleApprove = (e) => {
                e.stopPropagation();
                onApproveClick(org);
              };

              const handleGoToOrg = (e) => {
                e.stopPropagation();
                navigate('/settings/organization?orgId=' + org.id);
              };

              return (
                <TableRow
                  hover
                  key={org.id}
                  onClick={handleGoToOrg}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <Stack alignItems="center" direction="row" spacing={2}>
                      <Typography variant="subtitle2">{org.name}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {org.city}, {org.state}
                  </TableCell>
                  <TableCell>
                    {org.website && (
                      <Link
                        to={org.website}
                        target={'_blank'}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {org.website}
                      </Link>
                    )}
                  </TableCell>
                  <TableCell>
                    {org.approvedAt ? 'Approved' : 'Pending Approval'}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Approve this Organization" placement="top">
                      <Fab
                        variant="extended"
                        size="small"
                        color={'success'}
                        onClick={handleApprove}
                        sx={{ p: 2 }}
                      >
                        <Approval sx={{ mr: 1 }} />
                        Approve
                      </Fab>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};
