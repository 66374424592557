import {
  Alert,
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Add from '@mui/icons-material/Add';
import { DeleteForeverOutlined, EditOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import { format } from 'date-fns';
import {
  useAddDealFunding,
  useDeleteDealFunding,
  useUpdateDealFunding,
} from '../../hooks/deals/use-deal-funding';
import DealEditFundingDialog from '../../dialogs/deal-edit-funding-dialog';
import DealDeleteFundingDialog from '../../dialogs/deal-delete-funding-dialog';
import { SectionHeader } from '../../util/section-header';
import { localDate } from '../../../helpers/dates';

export const EditFunding = ({ deal, totalRaise, setSnackbar }) => {
  const [fundingToEdit, setFundingToEdit] = useState(null);
  const [fundingToDelete, setFundingToDelete] = useState(null);

  const addFunding = useAddDealFunding();
  const updateFunding = useUpdateDealFunding();
  const deleteFunding = useDeleteDealFunding();

  const handleAddFundingClick = (e) => {
    e.stopPropagation();
    setFundingToEdit({});
  };

  const closeEditFunding = () => {
    setFundingToEdit(null);
  };

  const closeDeleteFundingConfirm = () => {
    setFundingToDelete(false);
  };

  const handleSaveFunding = (funding) => {
    const isAdd = !funding.id;
    try {
      if (isAdd) {
        addFunding.mutate({
          dealId: deal.id,
          createFundingRequest: funding,
        });
        setSnackbar({ message: 'Funding has been added' });
      } else {
        const { id, ...updated } = funding;
        updateFunding.mutate({
          dealId: deal.id,
          fundingId: id,
          updated,
        });
      }
      setSnackbar({ message: 'Funding has been updated' });
      closeEditFunding();
    } catch (e) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  const handleDeleteFunding = (funding) => {
    try {
      deleteFunding.mutate({
        dealId: deal.id,
        fundingId: funding.id,
      });
      setSnackbar({ message: 'Funding has been removed' });
      closeDeleteFundingConfirm();
    } catch (e) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <DealEditFundingDialog
        open={fundingToEdit !== null}
        deal={{ ...deal, totalRaise }}
        funding={fundingToEdit}
        onClose={closeEditFunding}
        onSubmit={handleSaveFunding}
        isLoading={addFunding.isLoading || updateFunding.isLoading}
      />
      <DealDeleteFundingDialog
        open={fundingToDelete !== null}
        funding={fundingToDelete}
        onClose={closeDeleteFundingConfirm}
        onSubmit={handleDeleteFunding}
        isLoading={deleteFunding.isLoading}
      />
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <SectionHeader
          title={'Funding'}
          subtitle={'The amount of closed funding'}
        />
        {totalRaise > 0 && deal?.funding?.length === 0 && (
          <Stack direction="row" alignItems={'center'}>
            <Tooltip title={`Add or edit the closed funding`}>
              <Button
                size={'small'}
                onClick={handleAddFundingClick}
                startIcon={<Add />}
              >
                Add Funding
              </Button>
            </Tooltip>
          </Stack>
        )}
      </Stack>
      {deal?.funding?.length > 0 && (
        <Table padding="normal" size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell>As of</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deal.funding.map((f) => {
              const handleEditFundingClick = (e) => {
                e.stopPropagation();
                setFundingToEdit(f);
              };
              const handleDeleteFundingClick = (e) => {
                e.stopPropagation();
                setFundingToDelete(f);
              };

              return (
                <TableRow key={f.id + '-' + f.dealId}>
                  <TableCell>
                    <Typography>
                      {format(localDate(f.closedAt), 'MMM d')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>${f.amount.toLocaleString()}</Typography>
                  </TableCell>
                  <TableCell>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-end"
                    >
                      <Tooltip title="Edit Total Funding" placement="top">
                        <IconButton
                          onClick={handleEditFundingClick}
                          color="primary"
                        >
                          <EditOutlined />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remove Funding" placement="top">
                        <IconButton
                          onClick={handleDeleteFundingClick}
                          color="error"
                        >
                          <DeleteForeverOutlined />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {!deal?.funding?.length > 0 && (
        <Alert severity={'warning'}>No funding has been added yet</Alert>
      )}
    </Box>
  );
};
