import { useApi } from '../api/use-api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { RetryConfig } from '../api/retry-config';

const queryKey = 'user';

const useUser = ({ id, options }) => {
  const request = useApi();
  return useQuery([queryKey], async () => request({ url: `/users/${id}` }), {
    ...RetryConfig(),
    ...options,
  });
};

const useUserPublic = ({ externalId }) => {
  const request = useApi();
  return useQuery(
    ['publicUser'],
    async () => request({ url: `/users/profile/${externalId}` }),
    {
      ...RetryConfig(),
    },
  );
};

const useUpdateUser = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, updated }) =>
      request({ url: `/users/${id}`, method: 'PATCH', body: updated }),
    {
      onMutate: async ({ id, updated }) => {
        await queryClient.cancelQueries([queryKey]);
        const previousValue = queryClient.getQueryData([queryKey]);
        if (previousValue) {
          queryClient.setQueryData([queryKey], (old) => ({
            ...old,
            ...request,
          }));
        }
        return previousValue;
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData([queryKey], previousValue);
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([queryKey]).then();
      },
      ...RetryConfig(),
    },
  );
};

const useUpdateUserDealFilters = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, updated }) =>
      request({
        url: `/users/${id}/profile/filters`,
        method: 'POST',
        body: updated,
      }),
    {
      onMutate: async ({ id, updated }) => {
        await queryClient.cancelQueries([queryKey]);
        const previousValue = queryClient.getQueryData([queryKey]);
        if (previousValue) {
          queryClient.setQueryData([queryKey], (old) => ({
            ...old,
            sectors: updated.sectors,
            states: updated.states,
          }));
        }
        return previousValue;
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData([queryKey], previousValue);
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([queryKey]).then();
      },
      ...RetryConfig(),
    },
  );
};

const useUpdateUserNotificationChannels = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, updated }) =>
      request({
        url: `/users/${id}/profile/notifications`,
        method: 'POST',
        body: updated,
      }),
    {
      onMutate: async ({ id, updated }) => {
        await queryClient.cancelQueries([queryKey]);
        const previousValue = queryClient.getQueryData([queryKey]);
        if (previousValue) {
          queryClient.setQueryData([queryKey], (old) => ({
            ...old,
            channels: updated.channels,
          }));
        }
        return previousValue;
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData([queryKey], previousValue);
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([queryKey]).then();
      },
      ...RetryConfig(),
    },
  );
};

const useUpdateUserQualification = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, updated }) =>
      request({
        url: `/users/${id}/profile/qualification`,
        method: 'POST',
        body: updated,
      }),
    {
      onMutate: async ({ id, updated }) => {
        await queryClient.cancelQueries([queryKey]);
        const previousValue = queryClient.getQueryData([queryKey]);
        if (previousValue) {
          queryClient.setQueryData([queryKey], (old) => ({
            ...old,
            qualification: { ...updated },
          }));
        }
        return previousValue;
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData([queryKey], previousValue);
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([queryKey]).then();
      },
      ...RetryConfig(),
    },
  );
};

export {
  useUser,
  useUserPublic,
  useUpdateUser,
  useUpdateUserDealFilters,
  useUpdateUserNotificationChannels,
  useUpdateUserQualification,
};
