export const Role = {
  SysAdmin: 'SysAdmin',
  OrgAdmin: 'OrgAdmin',
  OrgBasic: 'OrgBasic',
  Investor: 'Investor',
};

export const RoleLabel = {
  [Role.SysAdmin]: 'System Admin',
  [Role.OrgAdmin]: 'Admin',
  [Role.OrgBasic]: 'Standard User',
  [Role.Investor]: 'Investor',
};
