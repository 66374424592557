import { Stack, Table, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { format } from 'date-fns';

export const DealTimeline = ({ initialValue }) => {
  const milestones = initialValue ? JSON.parse(initialValue) : [];

  return (
    <Stack sx={{ mt: 1 }}>
      <Table padding="none" size={'small'}>
        <TableBody>
          {milestones
            .sort(
              (
                { month: m1 = 12, year: y1 = 9999 },
                { month: m2 = 12, year: y2 = 9999 },
              ) => {
                if (y1 === y2) {
                  return m1 > m2 ? 1 : m1 < m2 ? -1 : 0;
                }
                return y1 > y2 ? 1 : y1 < y2 ? -1 : 0;
              },
            )
            .map(({ name, month, year }, idx) => {
              const monthStr = month
                ? format(new Date(2023, parseInt(month) - 1, 1), 'MMM')
                : null;

              return (
                <TableRow key={name}>
                  <TableCell sx={{ py: 1 }}>
                    <b>{name}</b>
                  </TableCell>
                  <TableCell align={'right'}>
                    {monthStr || '[Month]'} {year || '[Year]'}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </Stack>
  );
};
