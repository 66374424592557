import {
  Box,
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React from 'react';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Unarchive from '@mui/icons-material/Unarchive';
import { DealInfoBlock } from './deal-info-block';
import { Link, useNavigate } from 'react-router-dom';
import { useSessionUser } from '../hooks/session/useSessionUser';
import { Role } from '../enums/role.enum';

export const DealsArchivedTable = ({
  items = [],
  onDeleteClick,
  onUnarchiveClick,
}) => {
  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();
  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isOrgAdmin = sessionUser?.role === Role.OrgAdmin;

  return (
    <Card>
      <Box sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Deal</TableCell>
              {isSysAdmin && <TableCell>Sponsor</TableCell>}
              {(isSysAdmin || isOrgAdmin) && <TableCell>Owner</TableCell>}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((deal) => {
              const handleDelete = (e) => {
                e.stopPropagation();
                onDeleteClick(deal);
              };

              const handleUnarchive = (e) => {
                e.stopPropagation();
                onUnarchiveClick(deal);
              };

              const goToDeal = () => {
                navigate('/deals/' + deal.id);
              };

              return (
                <TableRow
                  hover
                  key={deal.id}
                  onClick={goToDeal}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <DealInfoBlock deal={deal} />
                  </TableCell>
                  {isSysAdmin && (
                    <TableCell>
                      <Link
                        to={`/organizations/${deal.orgId}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {deal.organization.name}
                      </Link>
                    </TableCell>
                  )}
                  {(isSysAdmin || isOrgAdmin) && (
                    <TableCell>{deal?.owner?.name}</TableCell>
                  )}
                  <TableCell align="right">
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="flex-end"
                    >
                      <Tooltip
                        title="Add this deal back to your Manage Deals list"
                        placement="top"
                      >
                        <Button
                          onClick={handleUnarchive}
                          startIcon={<Unarchive />}
                        >
                          Unarchive
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title="Permanently remove this deal"
                        placement="top"
                      >
                        <Button
                          onClick={handleDelete}
                          startIcon={<DeleteForever />}
                          color="error"
                        >
                          Delete
                        </Button>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};
