import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  DealQueryKeys,
  enrichDeal,
  setDealInDealQueryData,
  setDealQueryDataToPrevious,
  updateDealInList,
} from './deals-hook-utils';
import { RetryConfig } from '../api/retry-config';
import { useApi } from '../api/use-api';
import { v4 as uuid } from 'uuid';
import { handleMutate } from '../util/use-handle-mutate';

const useDeal = ({ id, initialData, options = null }) => {
  const request = useApi();
  return useQuery(
    DealQueryKeys.detail(id),
    async () => {
      const response = await request({ url: `/deals/${id}` });
      // console.log('response: ' + JSON.stringify(response, null, 2));
      return enrichDeal(response);
    },
    {
      ...RetryConfig(),
      initialData,
      ...options,
    },
  );
};

const usePublicDeal = ({ externalId, options = null }) => {
  const request = useApi(true);
  return useQuery(
    DealQueryKeys.public(externalId),
    async () => {
      const response = await request({ url: `/public/deals/${externalId}` });
      // console.log('response: ' + JSON.stringify(response, null, 2));
      return enrichDeal(response);
    },
    {
      ...RetryConfig(),
      ...options,
    },
  );
};

const useCreateDeal = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ createDealRequest, user }) =>
      request({ url: `/deals`, method: 'POST', body: createDealRequest }),
    {
      onMutate: async ({ createDealRequest, user }) => {
        return handleMutate(queryClient, DealQueryKeys.manage(), (old) => {
          const newDeal = {
            ...createDealRequest,
            id: 'copy-' + uuid(),
            owner: { name: user.name },
            offeredAt: null,
            approvedAt: null,
            submittedAt: null,
            updatedBy: null,
            approvedBy: null,
            archivedAt: null,
            archivedBy: null,
            submittedBy: null,
          };
          return [...old, enrichDeal(newDeal)];
        });
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(DealQueryKeys.manage(), previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(queryClient, [DealQueryKeys.manage()], data);
      },
      ...RetryConfig(),
    },
  );
};

const useCopyDeal = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ deal, name }) =>
      request({
        url: `/deals/${deal.id}/copy`,
        method: 'POST',
        body: { name },
      }),
    {
      onMutate: async ({ deal, name }) => {
        return handleMutate(queryClient, DealQueryKeys.manage(), (old) => {
          const newDeal = {
            ...deal,
            id: 'copy-' + uuid(),
            name,
            externalId: null,
            offeredAt: null,
            approvedAt: null,
            submittedAt: null,
            updatedBy: null,
            approvedBy: null,
            archivedAt: null,
            archivedBy: null,
            submittedBy: null,
          };
          return [...old, newDeal];
        });
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData(DealQueryKeys.manage(), previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(queryClient, [DealQueryKeys.manage()], data);
      },
      ...RetryConfig(),
    },
  );
};

const applyUpdates = (deal, updateRequest) => {
  const updatedDeal = {
    ...deal,
    ...updateRequest,
    offeredAt: updateRequest.offeredAt?.toISOString() || undefined,
  };
  return enrichDeal(updatedDeal);
};

const useUpdateDeal = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, updated }) => {
      const cleanRequest = {
        ...updated,
        minInvestment:
          updated.minInvestment === '' ? null : updated.minInvestment,
        totalRaise: updated.totalRaise === '' ? null : updated.totalRaise,
        sqFt: updated.sqFt === '' ? null : updated.sqFt,
        units: updated.units === '' ? null : updated.units,
      };
      return request({
        url: `/deals/${id}`,
        method: 'PATCH',
        body: cleanRequest,
      });
    },
    {
      onMutate: async ({ id, updated }) => {
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, id, (deal) => applyUpdates(deal, updated)),
        );
        const prevApprovals = await handleMutate(
          queryClient,
          DealQueryKeys.approvals(),
          (old) =>
            updateDealInList(old, id, (deal) => applyUpdates(deal, request)),
        );
        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) =>
            updateDealInList(old, id, (deal) => applyUpdates(deal, request)),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(id),
          (old) => applyUpdates(old, request),
        );

        return {
          prevManage,
          prevApprovals,
          prevSearch,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, variables.id, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [
            DealQueryKeys.approvals(),
            DealQueryKeys.manage(),
            DealQueryKeys.searches(),
            DealQueryKeys.watchlist(),
          ],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

const updateDealArchivedAt = (deal, isUndo) => {
  deal.approvedAt = null;
  deal.submittedAt = null;
  deal.archivedAt = isUndo ? null : new Date().toISOString();
  return enrichDeal(deal);
};

const useArchiveDeal = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, isUndo }) =>
      isUndo
        ? request({
            url: `/deals/${dealId}/unarchive`,
            method: 'POST',
          })
        : request({
            url: `/deals/${dealId}/archive`,
            method: 'POST',
          }),
    {
      onMutate: async ({ dealId, isUndo }) => {
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              updateDealArchivedAt(deal, isUndo),
            ),
        );
        const prevApprovals = await handleMutate(
          queryClient,
          DealQueryKeys.approvals(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              updateDealArchivedAt(deal, isUndo),
            ),
        );
        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              updateDealArchivedAt(deal, isUndo),
            ),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(dealId),
          (old) => updateDealArchivedAt({ ...old }, isUndo),
        );

        return {
          prevManage,
          prevApprovals,
          prevSearch,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [
            DealQueryKeys.approvals(),
            DealQueryKeys.manage(),
            DealQueryKeys.searches(),
            DealQueryKeys.watchlist(),
          ],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

const removeDealFromList = (old, dealId) => old.filter((d) => d.id !== dealId);

const useDeleteDeal = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id }) => request({ url: `/deals/${id}`, method: 'DELETE' }),
    {
      onMutate: async ({ id }) => {
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) => removeDealFromList(old, id),
        );
        const prevApprovals = await handleMutate(
          queryClient,
          DealQueryKeys.approvals(),
          (old) => removeDealFromList(old, id),
        );
        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) => removeDealFromList(old, id),
        );
        const prevWatchlist = await handleMutate(
          queryClient,
          DealQueryKeys.watchlist(),
          (old) => removeDealFromList(old, id),
        );

        return {
          prevManage,
          prevApprovals,
          prevSearch,
          prevWatchlist,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {},
      ...RetryConfig(),
    },
  );
};

export {
  useDeal,
  useCreateDeal,
  useUpdateDeal,
  useDeleteDeal,
  useCopyDeal,
  useArchiveDeal,
  usePublicDeal,
};
