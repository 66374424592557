import React, { useState } from 'react';
import {
  Alert,
  Button,
  Container,
  Paper,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DealSector } from '../../components/enums/deal-sector.enum';
import { useSearchDeals } from '../../components/hooks/deals/use-deals';
import { Role } from '../../components/enums/role.enum';
import { DealType } from '../../components/enums/deal-type.enum';
import { neutral } from '../../components/theme/colors';
import { useNavigate } from 'react-router-dom';
import { useSessionUser } from '../../components/hooks/session/useSessionUser';
import { SortBy, SortByKey, sortDeals } from '../../helpers/deal-sort';
import { StateList } from '../../helpers/get-states';
import { useSnackbar } from '../../components/hooks/util/use-snackbar';
import { SearchResult } from '../../components/deals/search/search-result';
import { SearchFilters } from '../../components/deals/search/search-filters';

const Search = () => {
  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();
  const { render: renderSnackbar, setSnackbar } = useSnackbar();
  const isInvestor = sessionUser?.role === Role.Investor;

  const [stateFilter, setStateFilter] = useState([]);
  const [sectorFilter, setSectorFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState('All');
  const [diligenceFilter, setDiligenceFilter] = useState('All');
  const [sortBy, setSortBy] = useState(
    localStorage.getItem(SortByKey) || SortBy.Newest,
  );

  const { data: searchResults, isLoading: isLoadingSearch } = useSearchDeals();

  const availableStates = StateList.filter(
    (s) =>
      searchResults?.findIndex((sr) => {
        const dealMatch = sr.state === s.value;
        let assetMatch = false;
        if (sr.type === DealType.Fund) {
          assetMatch =
            sr.assets.findIndex((asset) => asset.state === s.value) > -1;
        }
        return dealMatch || assetMatch;
      }) > -1,
  );
  const availableSectors = Object.keys(DealSector).filter(
    (sector) =>
      searchResults?.findIndex((sr) => sr.sectors?.indexOf(sector) > -1) > -1,
  );

  const isLoading = isLoadingSearch || !availableStates || !availableSectors;

  const isStateMatch = (deal) => {
    const dealMatch = stateFilter.includes(deal.state);
    let assetMatch = false;
    if (deal.type === DealType.Fund) {
      assetMatch =
        deal.assets.findIndex((asset) => stateFilter.includes(asset.state)) >
        -1;
    }
    return dealMatch || assetMatch;
  };

  const filteredData = searchResults?.filter(
    (deal) =>
      (!stateFilter?.length || isStateMatch(deal)) &&
      (!sectorFilter?.length ||
        sectorFilter.some((sec) => deal.sectors?.indexOf(sec) > -1)) &&
      (!typeFilter || typeFilter === 'All' || typeFilter === deal.type) &&
      (!diligenceFilter || diligenceFilter === 'All' || !!deal.diligenceBy),
  );

  const sortedFilteredData = filteredData
    ? sortDeals(filteredData, sortBy)
    : null;

  return (
    <Container maxWidth="xl" sx={{ pt: 2 }}>
      {renderSnackbar()}
      {!isLoading && (
        <SearchFilters
          availableStates={availableStates}
          stateFilter={stateFilter}
          setStateFilter={setStateFilter}
          availableSectors={availableSectors}
          sectorFilter={sectorFilter}
          setSectorFilter={setSectorFilter}
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
          sortBy={sortBy}
          setSortBy={setSortBy}
          setDiligenceFilter={setDiligenceFilter}
          diligenceFilter={diligenceFilter}
        />
      )}
      {isLoading || !searchResults ? (
        <Stack alignItems="center" spacing={2}>
          <Typography variant="h6">Loading the Latest Deals</Typography>
          <CircularProgress />
        </Stack>
      ) : sortedFilteredData?.length > 0 ? (
        <Grid
          container
          spacing={4}
          sx={{
            mt: 1,
            pb: 3,
            backgroundColor: 'neutral.100',
            borderTop: '1px solid lightgrey',
          }}
        >
          {sortedFilteredData.map((deal) => {
            const handleClick = (e) => {
              e.stopPropagation();
              navigate('/deals/' + deal.id);
            };

            return (
              <Grid lg={4} md={6} sm={6} key={deal.id}>
                <Paper
                  elevation={3}
                  sx={{
                    pb: 2,
                    border: '1px solid ' + neutral[200],
                    borderCollapse: 'separate',
                    borderRadius: '3px',
                    justifyContent: 'flex-end',
                    overflowX: 'hidden',
                  }}
                >
                  <SearchResult
                    key={deal.id}
                    deal={deal}
                    onClick={handleClick}
                    setSnackbar={setSnackbar}
                  />
                  {isInvestor && (
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        pt: 1,
                        px: 4,
                      }}
                    >
                      <Grid xs={12}>
                        <Stack alignItems={'center'}>
                          <Button
                            fullWidth
                            variant="contained"
                            color={'primary'}
                            onClick={handleClick}
                          >
                            View Details
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  )}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Alert severity="info" sx={{ width: '100%' }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant={'body2'}>
              {!searchResults?.length
                ? 'There are currently no deals.'
                : 'No deals match your search criteria.'}
            </Typography>
          </Stack>
        </Alert>
      )}
    </Container>
  );
};

export default Search;
