import {
  Alert,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom';
import { useSessionUser } from '../../../components/hooks/session/useSessionUser';
import { useOrganization } from '../../../components/hooks/organizations/use-organizations';
import { StorageBucket } from '../../../helpers/supabase';
import { enrichDeals } from '../../../components/hooks/deals/deals-hook-utils';
import { DealStatus } from '../../../components/enums/deal-status.enum';
import { OrganizationDeals } from '../../../components/organizations/organization-deals';

const IMG_WIDTH = 458;
const IMG_HEIGHT = 275;

export default function ViewOrganization() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { sessionUser } = useSessionUser();

  useEffect(() => {
    if (!/\d+/.test(id)) {
      navigate(-1);
    }
  }, [id, navigate]);

  const { data: organization, isLoading } = useOrganization({
    id: id,
  });

  const hasImg = organization?.logoImage;
  const logoImgSrc =
    hasImg &&
    `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.ORGANIZATION_IMAGES}/${organization.id}/${organization.logoImage}?w=${IMG_WIDTH}&h=${IMG_HEIGHT}&fit=max&auto=format`;

  const approvedDeals = organization?.deals
    ? enrichDeals(organization?.deals).filter(
        (deal) => deal.status === DealStatus.Approved,
      )
    : [];

  return (
    <Container maxWidth="lg">
      {isLoading || !organization ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Stack spacing={3}>
          {sessionUser?.orgId === organization.id && (
            <>
              <Typography variant="h4">Sponsor Page</Typography>
              <Alert severity={'info'} elevation={6}>
                This is how Investors and other Sponsors will see your sponsor
                page.
              </Alert>
            </>
          )}

          <Stack
            sx={{
              pt: 2,
              px: 2,
              pb: 10,
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Stack direction={'row'} columnGap={5}>
                  {hasImg ? (
                    <img src={logoImgSrc} alt={organization.name} />
                  ) : (
                    <Typography variant={'h5'}>{organization.name}</Typography>
                  )}
                  <Stack>
                    <Typography variant="caption" color="text.secondary">
                      Company Bio
                    </Typography>
                    <Typography sx={{ minWidth: 350, maxWidth: 500 }}>
                      {organization.bio}
                    </Typography>
                  </Stack>
                  <Card sx={{ width: '200px' }} elevation={6}>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        Active Deals
                      </Typography>
                      <Typography variant="h4">
                        {approvedDeals?.length || 0}
                      </Typography>
                    </CardContent>
                  </Card>
                </Stack>
              </Grid>
              <OrganizationDeals deals={approvedDeals} />
            </Grid>
          </Stack>
        </Stack>
      )}
    </Container>
  );
}
