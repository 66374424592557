import React, { useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { NotificationChannels } from '../notifications/notification-channels';

export const SettingsNotificationChannels = ({ ...props }) => {
  const { channels, isLoading, onSubmit } = props;
  const [selectedChannels, setSelectedChannels] = useState(channels || []);

  const handleSubmit = () => {
    onSubmit(selectedChannels);
  };

  const handleChange = (event) => {
    const c = event.target.value;
    if (selectedChannels.includes(c)) {
      setSelectedChannels(selectedChannels.filter((channel) => channel !== c));
    } else {
      const newChannels = [...selectedChannels, c];
      setSelectedChannels(newChannels);
    }
  };

  const isChanged =
    channels.sort().join(',') !== selectedChannels.sort().join(',');

  return (
    <Card>
      <CardHeader
        subheader="Manage the automatic emails you receive"
        title="Email Notifications"
      />
      <Divider />
      <CardContent>
        <NotificationChannels
          selectedChannels={selectedChannels}
          handleChange={handleChange}
        />
      </CardContent>
      <Divider />
      {isLoading ? (
        <CardActions sx={{ justifyContent: 'flex-end', my: 1 }}>
          <CircularProgress size={20} />
          <Button disabled color={'primary'}>
            Saving...
          </Button>
        </CardActions>
      ) : (
        <CardActions sx={{ justifyContent: 'flex-end', my: 1 }}>
          <Button
            variant="contained"
            disabled={isLoading || !isChanged}
            onMouseUp={handleSubmit}
          >
            Save Changes
          </Button>
        </CardActions>
      )}
    </Card>
  );
};
