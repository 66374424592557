import React, { useState } from 'react';
import { useSessionUser } from '../hooks/session/useSessionUser';
import { useUpdateUserQualification, useUser } from '../hooks/users/use-users';
import { smartDateFormat, utcToLocalTime } from '../../helpers/dates';
import { differenceInCalendarDays } from 'date-fns';
import { Stack, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { QuestionAnswer } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { common } from '@mui/material/colors';
import { useCreateMessageThread } from '../hooks/messages/use-messages';
import { sortByCreatedAt } from '../../helpers/messages-sort';
import UserQualifyDialog from '../dialogs/user-qualifiy-dialog';
import DealCreateThreadDialog from '../dialogs/deal-create-thread-dialog';

export const ContactButton = ({
  deal,
  fullWidth = false,
  setSnackbar,
  iconOnly = false,
}) => {
  const [showCreateThread, setShowCreateThread] = useState(false);
  const [showQualifyUser, setShowQualifyUser] = useState(false);

  const { sessionUser } = useSessionUser();
  const { data: userProfile } = useUser({ id: sessionUser?.id });

  const createMessageThread = useCreateMessageThread();
  const updateUserQualification = useUpdateUserQualification();

  const lastContact =
    deal?.threads?.length > 0 ? deal?.threads.sort(sortByCreatedAt)[0] : null;
  const lastContactSentAtLocal = lastContact?.createdAt
    ? utcToLocalTime(lastContact?.createdAt)
    : null;

  const closeQualifyUser = () => {
    setShowQualifyUser(false);
  };

  const openCreateThread = (e) => {
    e.stopPropagation();

    if (!userProfile?.qualification) {
      setShowQualifyUser(true);
    } else {
      setShowCreateThread(true);
    }
  };
  const closeCreateThread = (e) => {
    e?.stopPropagation();
    setShowCreateThread(false);
  };

  const handleCreateThread = async ({ message, dealId }) => {
    try {
      createMessageThread.mutate({
        dealId,
        message,
      });
      setShowCreateThread(false);
      setSnackbar({ message: 'Your message has been sent.' });
    } catch (err) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(err) });
    }
  };

  const handleUpdateUserQualification = async (qualification) => {
    try {
      await updateUserQualification.mutateAsync({
        id: sessionUser.id,
        request: {
          ...qualification,
        },
      });
      closeQualifyUser();
      setTimeout(() => setShowCreateThread(true), 500);
    } catch (err) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(err) });
    }
  };

  return (
    <>
      <DealCreateThreadDialog
        open={showCreateThread}
        deal={deal}
        onClose={closeCreateThread}
        onSubmit={handleCreateThread}
        isLoading={createMessageThread.isLoading}
        fromUser={sessionUser}
      />
      <UserQualifyDialog
        open={showQualifyUser}
        qualification={userProfile?.qualification}
        onClose={closeQualifyUser}
        onSubmit={handleUpdateUserQualification}
        isLoading={updateUserQualification.isLoading}
      />
      <Tooltip
        placement={'top'}
        title={`${
          lastContactSentAtLocal
            ? 'You last contacted about this deal ' +
              smartDateFormat(lastContactSentAtLocal, `'on' M/d/yy`)
            : 'Get more info about this deal'
        }`}
      >
        <Stack alignItems={'center'}>
          {iconOnly ? (
            <IconButton
              onClick={openCreateThread}
              sx={{
                color: common.white,
                '&:hover': {
                  backgroundColor: 'grey.800',
                },
              }}
            >
              <QuestionAnswer />
            </IconButton>
          ) : (
            <Button
              size={'small'}
              fullWidth={fullWidth}
              variant="contained"
              // color={'primary'}
              onClick={openCreateThread}
              startIcon={<QuestionAnswer />}
              sx={{
                minWidth: 175,
              }}
            >
              Contact
            </Button>
          )}
        </Stack>
      </Tooltip>
    </>
  );
};
