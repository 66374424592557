import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DialogActionBar } from './dialog-action-bar';

const DealEditCapStructureLineItemDialog = ({
  open,
  onClose,
  onSubmit,
  lineItem,
  isDebt,
}) => {
  const isAdd = lineItem?.idx === -1;

  const initialValues = {
    name: lineItem?.name || '',
    amount: lineItem?.amount || 0,
    submit: null,
  };

  const validationShape = {
    name: Yup.string().required('Name is required'),
    amount: Yup.number()
      .required('Amount is required')
      .integer('Amount must be a positive, whole number')
      .moreThan(-1, 'Amount must be a positive, whole number'),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, helpers) => {
      try {
        onSubmit({ ...values, idx: lineItem.idx });
      } catch (err) {
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ [errObj.field]: errObj.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  useEffect(() => {
    if (open) {
      formik.setValues({
        name: lineItem?.name || '',
        amount: lineItem?.amount || 0,
      });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {isAdd ? 'Add' : 'Edit'} {isDebt ? 'Debt' : 'Equity'} Line
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant={'body1'}>
            {isDebt
              ? 'Enter the debt name and amount'
              : 'Enter the investor name and equity amount'}
          </Typography>
          <TextField
            error={!!formik.errors.name}
            helperText={formik.errors.name}
            fullWidth
            autoFocus
            label="Name"
            name="name"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name || ''}
          />
          <TextField
            error={!!formik.errors.amount}
            helperText={formik.errors.amount}
            label="Amount (USD)"
            name="amount"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={
              formik.values.amount === undefined ||
              formik.values.amount === null
                ? ''
                : formik.values.amount
            }
            type="number"
            fullWidth
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActionBar
        submitLabel={`${isAdd ? 'Add' : 'Update'} ${
          isDebt ? 'Debt' : 'Equity'
        } Line`}
        disabled={formik.isSubmitting || !formik.isValid}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default DealEditCapStructureLineItemDialog;
