import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { DialogActionBar } from './dialog-action-bar';

const DealArchiveDialog = (props) => {
  const { open, title, onClose, onSubmit, multiple, isLoading } = { ...props };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Archive Deal{multiple ? 's' : ''}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant={'h5'}>{title}</Typography>
          <Typography variant={'body1'}>
            Archiving {multiple ? 'these deals' : 'this deal'} will remove{' '}
            {multiple ? 'them' : 'it'} from your Manage Deals view. You can
            un-archive this deal to add it back.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActionBar
        isLoading={isLoading}
        loadingLabel={'Archiving...'}
        submitLabel={'Archive'}
        onCancel={onClose}
        onSubmit={handleSubmit}
        submitColor={'error'}
      />
    </Dialog>
  );
};

export default DealArchiveDialog;
