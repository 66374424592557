import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { TopNav } from './top-nav';
import { Footer } from './footer';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';

export const MainLayout = () => {
  return (
    <>
      <TopNav />
      <Box sx={{ minHeight: '80vh', backgroundColor: grey[100], pt: 2, pb: 8 }}>
        <ScrollRestoration />
        <Outlet />
      </Box>
      <Footer />
    </>
  );
};
