import { Badge } from '@mui/material';
import React from 'react';
import { DealType, DealTypeLabel } from '../enums/deal-type.enum';

export const DealTypeBadge = ({ type, children }) => {
  return (
    <Badge
      badgeContent={DealTypeLabel[type]}
      color={
        type === DealType.Asset
          ? 'info'
          : type === DealType.Sale
            ? 'info2'
            : 'secondary'
      }
      sx={{ mr: 2, minWidth: 100 }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {children}
    </Badge>
  );
};
