import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker } from '@mui/x-date-pickers';
import { EditDealImages } from './edit-images';
import { EditFunding } from './edit-funding';
import { EditCapStructure } from './edit-cap-structure';
import { EditExpReturns } from './edit-exp-returns';
import { EditTimeline } from './edit-timeline';
import { Description } from '@mui/icons-material';
import { EditDealAssets } from '../assets/edit-assets';
import { DealType } from '../../enums/deal-type.enum';
import { StateMap } from '../../../helpers/get-states';
import { DealReturnMetricKey } from '../../../helpers/exp-returns';
import { DealSector } from '../../enums/deal-sector.enum';
import { DealStrategy } from '../../enums/deal-strategy.enum';
import AddressAutocompleteTextField from '../../maps/address-autocomplete-textfield';
import MapView from '../../maps/map-view';
import { AddressText } from '../../maps/address-text';
import WysiwygEditor from '../../util/wysiwyg/wysiwyg-editor';
import { SectionHeader } from '../../util/section-header';
import { DealTypeChip } from '../chips/deal-type-chip';

const EXAMPLE_DEAL_SUMMARY = (organization, deal) => {
  const isFund = deal?.type === DealType.Fund;
  const isSale = deal?.type === DealType.Sale;
  const vars = {
    orgName: organization?.name || '[Org Name]',
    address1: deal?.address1 || '[Address]',
    city: deal?.city || '[City]',
    state: deal?.state ? StateMap[deal.state] : '[State]',
    sqFt: deal.sqFt ? deal.sqFt.toLocaleString() : '[Sq Ft]',
    acreage: deal.acreage ? deal.acreage.toLocaleString() : '[Acreage]',
    totalRaise: deal.totalRaise
      ? deal.totalRaise.toLocaleString()
      : '[Total Equity Raise]',
    irr: deal.expReturns
      ? JSON.parse(deal.expReturns)[DealReturnMetricKey.IRR]
      : '[IRR]',
    avgReturn: deal.expReturns
      ? JSON.parse(deal.expReturns)[DealReturnMetricKey.CashOnCash]
      : '[Avg. Return]',
  };
  vars.region = vars.city + ', ' + vars.state;

  if (isSale) {
    return `<p><strong>Overview:</strong> ${vars.orgName} is currently selling Opportunity Zone Property located at ${vars.address1} for the development of a ${vars.acreage} acre property ${vars.city}, ${vars.state}.&nbsp;</p><p><strong>Market:</strong> The ${vars.region}  market consists of [Region Inventory]M SF of inventory with a vacancy rate less than [Vacancy Rate]%. Rent growth over the past three years has been [X]%, [Y]%, and [Z]% respectively.&nbsp;</p><p><strong>Impact Objective:</strong> [Enter the impact objective of the project here].&nbsp;</p><p>&nbsp;</p>`;
  }

  return `<p><strong>Overview:</strong> ${
    vars.orgName
  } is currently raising capital for an Opportunity Zone ${
    isFund
      ? `fund called ${deal.name}`
      : `investment located at ${vars.address1} for the development of a ${vars.sqFt} SF new building in ${vars.city}, ${vars.state}`
  }.&nbsp;</p><p><strong>Market:</strong> The ${
    vars.region
  }  market consists of [Region Inventory]M SF of inventory with a vacancy rate less than [Vacancy Rate]%. Rent growth over the past three years has been [X]%, [Y]%, and [Z]% respectively.&nbsp;</p><p><strong>Timing/Capital:</strong> We expect to close on the Property in [Close Month] [Close Date], and we expect to require $${
    vars.totalRaise
  }M of equity Capital.</p><p><strong>Returns: </strong>Upon Stabilization, we project the investment will generate an average ${
    vars.avgReturn
  }% return during the hold period with a projected ${
    vars.irr
  }% internal rate of return upon selling in year 10.&nbsp;</p><p><strong>Impact Objective:</strong> [Enter the impact objective of the project here].&nbsp;</p><p>&nbsp;</p>`;
};

export const EditDealForm = ({
  formik,
  deal,
  isNew,
  isSaving,
  setSnackbar,
  goToAddAsset,
  goToEditAsset,
}) => {
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (isSaving) setSaved(true);
  }, [isSaving]);

  const handleRemoveSector = (sectorToRemove) => {
    formik.setFieldValue(
      'sectors',
      formik.values.sectors.filter((s) => s !== sectorToRemove),
      true,
    );
  };
  const handleOfferedAtChange = (newDate) => {
    formik.setFieldValue(
      'offeredAt',
      newDate && newDate !== '' ? newDate : null,
      true,
    );
  };
  const handleDescriptionChange = (newDesc) => {
    formik.setFieldValue('description', newDesc, true);
  };
  const handleTimelineChange = (newTimeline) => {
    formik.setFieldValue('timeline', newTimeline, true);
  };
  const handleCapStructureChange = async (newCapStructure) => {
    await formik.setFieldValue('capStructure', newCapStructure, true);
  };
  const handleExpReturnsChange = (newExpReturns) => {
    formik.setFieldValue('expReturns', newExpReturns, true);
  };

  const handleImagesChange = (newImages) => {
    formik.setFieldValue('images', newImages, true);
  };

  const handleLoadExampleSummary = () => {
    formik.setFieldValue(
      'description',
      EXAMPLE_DEAL_SUMMARY(deal.organization, formik.values),
    );
  };

  const hasError = (key) => {
    return isNew && !saved
      ? formik.touched[key] && formik.errors[key]
      : formik.errors[key];
  };

  const numberValue = (formValue) => {
    return formValue !== null && formValue !== undefined ? formValue : '';
  };

  const isFund = deal?.type === DealType.Fund;
  const isSale = deal?.type === DealType.Sale;
  const dealWord = isFund ? 'Fund' : 'Deal';
  const latlng = formik.values.latlng ? JSON.parse(formik.values.latlng) : null;

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
      style={{
        td: {
          padding: 0,
        },
      }}
    >
      <Box
        sx={{
          pb: 10,
        }}
      >
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <SectionHeader
            title={`${dealWord} Overview`}
            subtitle={`The key information about the ${dealWord.toLowerCase()}. Fields marked with (*) are required to list publicly.`}
          />
          <DealTypeChip type={deal?.type} />
        </Stack>
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid xs={6}>
            <TextField
              error={!!hasError('name')}
              helperText={hasError('name')}
              fullWidth
              label="Name"
              name="name"
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name || ''}
            />
          </Grid>
          <Grid xs={6}>
            <FormControl fullWidth>
              <InputLabel id="sector-label">
                {isSale
                  ? `Zoning`
                  : `Sector${formik.values.sectors?.length > 1 ? 's' : ''}`}{' '}
                *
              </InputLabel>
              <Select
                id="sector-select"
                label={
                  isSale
                    ? `Zoning`
                    : `Sector${formik.values.sectors?.length > 1 && 's'} *`
                }
                fullWidth
                name="sectors"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange('sectors')}
                value={formik.values.sectors || []}
                multiple
                required
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    {selected.map((value) => (
                      <Chip
                        sx={{ mr: 1, mb: 0.5 }}
                        key={value}
                        label={DealSector[value]}
                        variant="outlined"
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={(e) => handleRemoveSector(value)}
                      />
                    ))}
                  </Box>
                )}
              >
                {Object.keys(DealSector).map((sector) => (
                  <MenuItem key={sector} value={sector}>
                    <Checkbox
                      checked={formik.values.sectors?.indexOf(sector) > -1}
                    />
                    <ListItemText primary={DealSector[sector]} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{hasError('sectors')}</FormHelperText>
            </FormControl>
          </Grid>
          {isSale ? (
            <Grid xs={6} md={3}>
              <TextField
                error={!!hasError('purchasePrice')}
                helperText={hasError('purchasePrice')}
                label="Price"
                name="purchasePrice"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={numberValue(formik.values.purchasePrice)}
                type="number"
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
          ) : (
            <>
              <Grid xs={6} md={3}>
                <TextField
                  error={!!hasError('minInvestment')}
                  helperText={hasError('minInvestment')}
                  label="Min. Investment"
                  name="minInvestment"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={numberValue(formik.values.minInvestment)}
                  type="number"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={6} md={3}>
                <TextField
                  error={!!hasError('totalRaise')}
                  helperText={hasError('totalRaise')}
                  label="Total Raise"
                  name="totalRaise"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={numberValue(formik.values.totalRaise)}
                  type="number"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
          <Grid xs={6} md={3}>
            <DatePicker
              label="Initial Offer Date"
              onChange={handleOfferedAtChange}
              value={formik.values.offeredAt}
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true,
                  error: !!formik.errors.offeredAt,
                  helperText: formik.errors.offeredAt,
                  label: 'Initial Offer Date',
                  name: 'offeredAt',
                  onBlur: formik.handleBlur,
                },
                actionBar: {
                  actions: ['clear'],
                },
              }}
            />
          </Grid>
          {!isSale && (
            <Grid xs={6} md={3}>
              <TextField
                error={!!formik.errors.holdTime}
                helperText={formik.errors.holdTime}
                label="Est. Hold Period"
                name="holdTime"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.holdTime || ''}
                fullWidth
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="subtitle2" sx={{ marginLeft: 1 }}>
                        years
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {!isFund && (
            <Grid xs={6} md={3}>
              <TextField
                error={!!hasError('strategy')}
                fullWidth
                helperText={hasError('strategy')}
                label="Strategy"
                name="strategy"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.strategy || ''}
                select
                required
                SelectProps={{ native: true }}
              >
                <option value=""></option>
                {Object.keys(DealStrategy).map((strategy) => (
                  <option key={strategy} value={strategy}>
                    {DealStrategy[strategy]}
                  </option>
                ))}
              </TextField>
            </Grid>
          )}
          {!isFund && (
            <>
              <Grid xs={3}>
                <TextField
                  error={!!formik.errors.acreage}
                  fullWidth
                  helperText={formik.errors.acreage}
                  label="Acreage"
                  name="acreage"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="number"
                  value={formik.values.acreage || ''}
                />
              </Grid>
              <Grid xs={6} md={3}>
                <TextField
                  error={!!formik.errors.units}
                  helperText={formik.errors.units}
                  label={isSale ? 'Number of Structures' : 'Number of Units'}
                  name="units"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={numberValue(formik.values.units)}
                  type="number"
                  fullWidth
                />
              </Grid>
              <Grid xs={6} md={3}>
                <TextField
                  error={!!formik.errors.sqFt}
                  helperText={formik.errors.sqFt}
                  label={isSale ? 'Sq Ft of Structures' : 'Square Footage'}
                  name="sqFt"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={numberValue(formik.values.sqFt)}
                  type="number"
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid container spacing={4} sx={{ mb: 4 }}>
          <Grid xs={6}>
            <SectionHeader
              title={isFund ? 'Fund Headquarters' : 'Location'}
              subtitle={
                isFund
                  ? 'The primary headquarters of the fund'
                  : 'The address of the project'
              }
            />
            <AddressAutocompleteTextField
              formik={formik}
              fullWidth
              label="Address"
              name="address1"
            />
            {latlng && (
              <Stack sx={{ mt: 2 }}>
                <MapView
                  markers={[{ lat: latlng.lat, lng: latlng.lng }]}
                  height={150}
                />
                <AddressText
                  address1={formik.values.address1}
                  address2={formik.values.address2}
                  city={formik.values.city}
                  state={formik.values.state}
                  zip={formik.values.zip}
                />
              </Stack>
            )}
          </Grid>
          <Grid
            xs={6}
            sx={{
              '.ck-editor__editable': {
                minHeight: '200px',
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <SectionHeader
                title={`${dealWord} Summary`}
                subtitle={`An executive summary of the ${dealWord.toLowerCase()}`}
                color={hasError('description') ? 'error' : 'text'}
              />
              <Stack direction="row" alignItems={'center'}>
                <Tooltip
                  title={
                    !isSale
                      ? `This works best after finishing the ${dealWord} overview and expected returns.`
                      : `This works best after completing the Deal overview`
                  }
                >
                  <Button
                    size={'small'}
                    onClick={handleLoadExampleSummary}
                    startIcon={<Description />}
                  >
                    Generate
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>
            <WysiwygEditor
              name="description"
              title={'Description'}
              placeholder={`Summarize the ${dealWord} in a few paragraphs`}
              height={150}
              data={formik.values.description}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleDescriptionChange(data);
              }}
            />
            {formik.errors.description && (
              <Typography color="error" sx={{ mt: 1 }} variant="caption">
                {formik.errors.description}
              </Typography>
            )}
          </Grid>
          {isFund && (
            <Grid xs={6}>
              <EditDealAssets
                deal={deal}
                goToAddAsset={goToAddAsset}
                goToEditAsset={goToEditAsset}
                setSnackbar={setSnackbar}
              />
            </Grid>
          )}
          <Grid xs={6}>
            <EditDealImages
              deal={deal}
              onUploadSuccess={handleImagesChange}
              setSnackbar={setSnackbar}
            />
          </Grid>
          <Grid xs={6}>
            <SectionHeader
              title={'Video'}
              subtitle={`A link to a video about the ${dealWord.toLowerCase()}`}
            />
            <TextField
              fullWidth
              label="Video URL"
              name="videoUrl"
              required
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.videoUrl || ''}
              error={!!hasError('videoUrl')}
              helperText={hasError('videoUrl')}
            />
          </Grid>
          <Grid xs={6}>
            <SectionHeader
              title={'Diligence Report'}
              subtitle={`Attract RIAs and other sophisticated investors with a diligence report`}
            />
            <TextField
              fullWidth
              label="Select diligence provider"
              select
              name="diligenceBy"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.diligenceBy}
              error={!!hasError('diligenceBy')}
              helperText={hasError('diligenceBy')}
              SelectProps={{
                displayEmpty: true,
              }}
            >
              <MenuItem value="">Diligence Not Available</MenuItem>
              <MenuItem value="CapZone Analytics">CapZone Analytics</MenuItem>
              <MenuItem value="FactRight">FactRight</MenuItem>
              <MenuItem value="GreenLight">GreenLight</MenuItem>
              <MenuItem value="JTC">JTC</MenuItem>
              <MenuItem value="Mick Law">Mick Law</MenuItem>
              <MenuItem value="SEI">SEI</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
          </Grid>
          {!isSale && (
            <Grid xs={6}>
              <EditExpReturns
                onChange={(newValue) => handleExpReturnsChange(newValue)}
                initialValue={formik.values.expReturns}
                isFund={isFund}
              />
            </Grid>
          )}
          {!isSale && (
            <Grid xs={6}>
              <EditFunding
                deal={deal}
                totalRaise={formik.values.totalRaise || deal.totalRaise}
                setSnackbar={setSnackbar}
              />
            </Grid>
          )}
          {!isFund && !isSale && (
            <Grid xs={6}>
              <EditTimeline
                onChange={(newValue) => handleTimelineChange(newValue)}
                initialValue={formik.values.timeline}
              />
            </Grid>
          )}
          {!isFund && !isSale && (
            <Grid xs={6}>
              <EditCapStructure
                onChange={(newValue) => handleCapStructureChange(newValue)}
                initialValue={formik.values.capStructure}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </form>
  );
};
