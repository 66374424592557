import { Stack, Typography } from '@mui/material';
import React from 'react';

export const DealKpis = ({ deal }) => {
  return (
    <Stack
      direction={'row'}
      spacing={1}
      justifyContent={'flex-start'}
      sx={{ overflow: 'auto' }}
    >
      <Stack sx={{ width: '150px' }}>
        <Typography color="textSecondary" gutterBottom>
          Page Views
        </Typography>
        <Typography variant="h4">{deal.views?.length || 0}</Typography>
      </Stack>
      <Stack sx={{ width: '150px' }}>
        <Typography color="textSecondary" gutterBottom>
          Watchlisted
        </Typography>
        <Typography variant="h4">{deal.watching?.length || 0}</Typography>
      </Stack>
      <Stack sx={{ width: '150px' }}>
        <Typography color="textSecondary" gutterBottom>
          Contacts
        </Typography>
        <Typography variant="h4">{deal.threads?.length || 0}</Typography>
      </Stack>
    </Stack>
  );
};
