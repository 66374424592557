import React, { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import RocketLaunch from '@mui/icons-material/RocketLaunch';
import { DealType } from '../enums/deal-type.enum';
import DealPaywallDialog from '../dialogs/deal-paywall-dialog';

export const PublishButton = ({
  deal,
  canSubmit,
  isChanged,
  isSaved,
  onSubmitForApproval,
  fullWidth = false,
  isSysAdmin = false,
}) => {
  const [showPaywall, setShowPaywall] = useState(false);

  const isFund = deal?.type === DealType.Fund;
  const dealWord = isFund ? 'Fund' : 'Deal';
  const activeSub = deal.subscriptions?.find((s) => !s.isCancelled);

  const handleClick = async (e) => {
    e.stopPropagation();
    if (!activeSub) {
      setShowPaywall(true);
    } else {
      onSubmitForApproval();
    }
  };

  const handleClosePaywall = () => {
    setShowPaywall(false);
  };

  return (
    <>
      <DealPaywallDialog
        open={showPaywall}
        title={'Publish ' + deal.name + ' to Investors'}
        onClose={handleClosePaywall}
        deal={deal}
      />
      <Tooltip
        placement={'bottom'}
        title={
          !canSubmit
            ? 'Complete the required fields to submit'
            : isSysAdmin
              ? 'You can only Publish from a Sponsor Account'
              : isChanged && !isSaved
                ? `Save your ${dealWord} to Publish`
                : `Submit to list the ${dealWord.toLowerCase()} publicly`
        }
      >
        <span>
          <Button
            fullWidth={fullWidth}
            size={'small'}
            variant={'contained'}
            onClick={handleClick}
            startIcon={<RocketLaunch />}
            disabled={!isSaved || !canSubmit || isSysAdmin}
          >
            Publish
          </Button>
        </span>
      </Tooltip>
    </>
  );
};
