import React, { useState } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useWatchlistDeals } from '../../components/hooks/deals/use-deals';
import CancelIcon from '@mui/icons-material/Cancel';
import { DealsWatchingTable } from '../../components/deals/table-watching';
import { useNavigate } from 'react-router-dom';

const DealStatusFilter = {
  Contacted: 'Contacted',
  NotContacted: 'Not Contacted',
};

export default function Watchlist() {
  const navigate = useNavigate();
  const { data: watchlist, isLoading: isLoadingWatchlist } =
    useWatchlistDeals();

  const [statusFilter, setStatusFilter] = useState([]);

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };
  const handleRemoveStatus = (statusToRemove) => {
    setStatusFilter(statusFilter.filter((s) => s !== statusToRemove));
  };

  const handleGoToSearch = () => {
    navigate('/search');
  };

  const filteredData = watchlist?.filter(
    (deal) =>
      !statusFilter?.length ||
      (statusFilter.includes(DealStatusFilter.Contacted) &&
        deal.threads.length > 0) ||
      (statusFilter.includes(DealStatusFilter.NotContacted) &&
        !deal.threads?.length),
  );

  return (
    <Container maxWidth="xl">
      <Stack spacing={5}>
        <Stack spacing={1}>
          {isLoadingWatchlist ? (
            <Stack direction="row" justifyContent="center" spacing={1}>
              <CircularProgress size={30} sx={{ my: 2 }} />
            </Stack>
          ) : watchlist?.length > 0 ? (
            <Stack spacing={1}>
              <Typography variant="h4" sx={{ pb: 3 }}>
                Watchlist
              </Typography>
              <Grid container sx={{ pb: 2 }}>
                <Grid item lg={3}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="status-label"
                      shrink={true}
                      sx={{ backgroundColor: 'white' }}
                    >
                      Status
                    </InputLabel>
                    <Select
                      labelId="status-label"
                      label="Status"
                      value={statusFilter}
                      onChange={handleStatusFilterChange}
                      multiple
                      displayEmpty
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          {statusFilter.length === 0 ? (
                            <Chip
                              key={'All'}
                              label={`All Statuses Included`}
                              variant={'outlined'}
                            />
                          ) : (
                            statusFilter.map((value) => (
                              <Chip
                                sx={{ mr: 1 }}
                                key={value}
                                label={value}
                                variant="outlined"
                                deleteIcon={
                                  <CancelIcon
                                    onMouseDown={(event) =>
                                      event.stopPropagation()
                                    }
                                  />
                                }
                                onDelete={(e) => handleRemoveStatus(value)}
                              />
                            ))
                          )}
                        </Box>
                      )}
                    >
                      <MenuItem value={DealStatusFilter.Contacted}>
                        <Checkbox
                          checked={
                            statusFilter.indexOf(DealStatusFilter.Contacted) >
                            -1
                          }
                        />
                        <ListItemText primary={DealStatusFilter.Contacted} />
                      </MenuItem>
                      <MenuItem value={DealStatusFilter.NotContacted}>
                        <Checkbox
                          checked={
                            statusFilter.indexOf(
                              DealStatusFilter.NotContacted,
                            ) > -1
                          }
                        />
                        <ListItemText primary={DealStatusFilter.NotContacted} />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <DealsWatchingTable items={filteredData} />
            </Stack>
          ) : (
            <Stack spacing={1}>
              <Typography variant="h4" sx={{ pb: 3 }}>
                Watchlist
              </Typography>
              <Alert severity="info" sx={{ width: '100%' }}>
                <Typography>
                  No Watchlist deals yet.{' '}
                  <Typography variant="link" onClick={handleGoToSearch}>
                    Click here to add some.
                  </Typography>
                </Typography>
              </Alert>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Container>
  );
}
