import React, { useEffect, useState } from 'react';
import { Container, Stack, Typography } from '@mui/material';
import {
  useRegisterOrganizationAndUser,
  useRegisterUser,
} from '../components/hooks/registration/use-registration';
import { Role } from '../components/enums/role.enum';
import { LoadingOverlay } from '../components/util/loading-overlay';
import { RegisterInvestorForm } from '../components/registration/register-investor';
import { RegisterOrganizationForm } from '../components/registration/register-organization';
import { useSessionUser } from '../components/hooks/session/useSessionUser';
import { useApi } from '../components/hooks/api/use-api';
import { useParams } from 'react-router-dom';

function Register() {
  const apiRequest = useApi();
  const { role } = useParams();
  const { sessionUser } = useSessionUser();

  const registerUser = useRegisterUser();
  const registerOrganizationAndUser = useRegisterOrganizationAndUser();
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  //ensure the role is saved if the user bails on registration
  useEffect(() => {
    apiRequest({
      url: '/registration/role',
      method: 'PATCH',
      body: { auth0Id: sessionUser.auth0Id, role },
    });
  }, [sessionUser]);

  const refreshSession = () => {
    window.location.href = '/search';
  };

  const handleSubmit = async (registrationInfo, channels) => {
    const isOrg = role === Role.OrgAdmin;

    setIsSubmitClicked(true);

    try {
      if (isOrg) {
        const createOrgRequest = {
          ...registrationInfo,
          user: {
            ...registrationInfo.user,
            role,
            auth0Id: sessionUser.auth0Id,
            email: sessionUser.email,
          },
        };
        await registerOrganizationAndUser.mutateAsync({
          createOrgRequest,
        });
      } else {
        const createUserRequest = {
          ...registrationInfo,
          role,
          auth0Id: sessionUser.auth0Id,
          email: sessionUser.email,
          channels,
        };
        await registerUser.mutateAsync({ createUserRequest });
      }
      refreshSession();
    } catch (e) {
      console.log(e);
      refreshSession();
    }
  };

  const isSubmitting =
    registerUser.isLoading || registerOrganizationAndUser.isLoading;

  return (
    <Container maxWidth="md">
      {isSubmitClicked ? (
        <LoadingOverlay text={'Creating your new Account'} />
      ) : (
        <div>
          <Typography variant="h3">
            {role === Role.OrgAdmin
              ? 'Register for Opportunity Zone Deals'
              : 'Complete your Investor Profile'}
          </Typography>
          <Stack spacing={1} sx={{ mb: 3 }}>
            <Typography color="text.secondary" variant="body2">
              Please enter all of the required (*) information below.
            </Typography>
          </Stack>

          <Stack spacing={3}>
            {role === Role.Investor && (
              <RegisterInvestorForm
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting || isSubmitClicked}
              />
            )}
            {role === Role.OrgAdmin && (
              <RegisterOrganizationForm
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting || isSubmitClicked}
              />
            )}
          </Stack>
        </div>
      )}
    </Container>
  );
}

export default Register;
