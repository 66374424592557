import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { Role, RoleLabel } from '../../components/enums/role.enum';
import CircularProgress from '@mui/material/CircularProgress';
import { useSessionUser } from '../../components/hooks/session/useSessionUser';
import {
  useUpdateUser,
  useUpdateUserDealFilters,
  useUpdateUserNotificationChannels,
  useUpdateUserQualification,
  useUser,
} from '../../components/hooks/users/use-users';
import { useSnackbar } from '../../components/hooks/util/use-snackbar';
import { SettingsContact } from '../../components/users/settings-contact';
import { SettingsDealFilters } from '../../components/users/settings-deal-filters';
import { SettingsNotificationChannels } from '../../components/users/settings-notification-channels';
import { SettingsQualification } from '../../components/users/settings-qualification';

function AccountSettings() {
  const { sessionUser } = useSessionUser();
  const { render: renderSnackbar, setSnackbar } = useSnackbar();

  const { data: userProfile, isLoading } = useUser({
    id: sessionUser?.id,
    options: {
      enabled: !!sessionUser?.id,
    },
  });

  const updateUser = useUpdateUser();
  const updateUserDealFilters = useUpdateUserDealFilters();
  const updateUserNotifications = useUpdateUserNotificationChannels();
  const updateUserQualification = useUpdateUserQualification();

  const handleSubmitContact = async (request) => {
    try {
      await updateUser.mutateAsync({
        id: userProfile.id,
        updated: request,
      });
      setSnackbar({ message: 'Your account settings have been updated.' });
    } catch (e) {
      setSnackbar({ message: 'Error: ' + e });
    }
  };

  const handleSubmitDealFilters = async (states, sectors) => {
    try {
      await updateUserDealFilters.mutateAsync({
        id: userProfile.id,
        updated: { states, sectors },
      });
      setSnackbar({ message: 'Your deal filter settings have been updated' });
    } catch (e) {
      setSnackbar({ message: 'Error: ' + e });
    }
  };

  const handleSubmitNotifications = async (channels) => {
    try {
      await updateUserNotifications.mutateAsync({
        id: userProfile.id,
        updated: { channels },
      });
      setSnackbar({ message: 'Your notification settings have been updated' });
    } catch (e) {
      setSnackbar({ message: 'Error: ' + e });
    }
  };

  const handleUpdateUserQualification = async (qualification) => {
    try {
      await updateUserQualification.mutateAsync({
        id: sessionUser.id,
        updated: {
          ...qualification,
        },
      });
      setSnackbar({
        message: 'Your qualified investor answers have been updated',
      });
    } catch (e) {
      setSnackbar({ message: 'Error: ' + e });
    }
  };

  return (
    <Container maxWidth="xl">
      {renderSnackbar()}
      <Stack spacing={3}>
        <Typography variant="h4">Account Settings</Typography>
        {isLoading ? (
          <Stack alignItems="center" sx={{ mt: 10 }} rowGap={2}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <SettingsContact
              userProfile={userProfile}
              onSubmit={handleSubmitContact}
              isLoading={updateUser.isLoading}
            />
            {(sessionUser?.role === Role.Investor ||
              sessionUser?.role === Role.SysAdmin) && (
              <>
                <SettingsDealFilters
                  states={userProfile?.states}
                  sectors={userProfile?.sectors}
                  onSubmit={handleSubmitDealFilters}
                  isLoading={updateUserDealFilters.isLoading}
                />
                <SettingsNotificationChannels
                  channels={userProfile?.channels}
                  onSubmit={handleSubmitNotifications}
                  isLoading={updateUserNotifications.isLoading}
                />
                <SettingsQualification
                  userProfile={userProfile}
                  onSubmit={handleUpdateUserQualification}
                  isLoading={updateUserQualification.isLoading}
                />
              </>
            )}
            <Card>
              <CardHeader title="Role" />
              <Divider />
              <CardContent>
                <Chip
                  label={RoleLabel[sessionUser?.role]}
                  color="info"
                  variant="outlined"
                ></Chip>
              </CardContent>
            </Card>
          </>
        )}
      </Stack>
    </Container>
  );
}

export default AccountSettings;
