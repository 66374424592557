import * as Yup from 'yup';

const validationShape = {
  name: Yup.string().max(255).required('Name is required'),
  minInvestment: Yup.number()
    .min(0, 'Minimum Investment is not valid')
    .max(2000000000, 'Minimum Investment can not exceed $2,000,000,000')
    .integer('Minimum Investment is not valid')
    .nullable(true),
  purchasePrice: Yup.number()
    .min(0, 'Purchase Price is not valid')
    .max(2000000000, 'Purchase Price can not exceed $2,000,000,000')
    .integer('Purchase Price is not valid')
    .nullable(true),
  totalRaise: Yup.number()
    .min(0, 'Total Equity Raise is not valid')
    .max(2000000000, 'Total Equity Raise can not exceed $2,000,000,000')
    .integer('Total Equity Raise is not valid')
    .nullable(true),
  sqFt: Yup.number()
    .positive('Square Footage is not valid')
    .integer('Square Footage is not valid')
    .nullable(true),
  units: Yup.number()
    .positive('Number of units is not valid')
    .integer('Number of units is not valid')
    .nullable(true),
  offeredAt: Yup.date()
    .typeError('Initial Offer Date is invalid')
    .nullable(true),
  holdTime: Yup.string()
    .max(255, 'Est. Hold Period must not be more than 255 characters')
    .nullable(true),
  acreage: Yup.number()
    .positive('Acreage is not valid')
    .integer('Acreage is not valid')
    .nullable(true),
  videoUrl: Yup.string()
    .url('Video URL is not valid')
    .max(255, 'Website can be no more than 255 characters')
    .nullable(true),
};

export const DealSaveValidationSchema = Yup.object().shape(validationShape);
