import {
  Checkbox,
  ListItemText,
  MenuItem,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import React from 'react';
import { SearchFilterMultiSelect } from './search-filter-multi-select';
import { SortBy, SortByKey, SortByLabel } from '../../../helpers/deal-sort';
import { StateMap } from '../../../helpers/get-states';
import { DealSector } from '../../enums/deal-sector.enum';
import Box from '@mui/material/Box';

export const SearchFilters = ({ ...props }) => {
  const {
    availableStates,
    stateFilter,
    setStateFilter,
    availableSectors,
    sectorFilter,
    setSectorFilter,
    typeFilter,
    setTypeFilter,
    diligenceFilter,
    setDiligenceFilter,
    sortBy,
    setSortBy,
  } = props;

  const handleStateFilterChange = (event) => {
    setStateFilter(event.target.value);
  };

  const handleRemoveState = (state) => {
    setStateFilter(stateFilter.filter((s) => s !== state));
  };

  const handleSectorFilterChange = (event) => {
    setSectorFilter(event.target.value);
  };

  const handleRemoveSector = (sector) => {
    setSectorFilter(sectorFilter.filter((s) => s !== sector));
  };

  const handleSortByChange = (event) => {
    localStorage.setItem(SortByKey, event.target.value);
    setSortBy(event.target.value);
  };

  const handleTypeFilterChange = (event) => {
    setTypeFilter(event.target.value);
  };

  const handleDiligenceFilterChange = (event) => {
    setDiligenceFilter(event.target.value);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <SearchFilterMultiSelect
          label={'State'}
          value={stateFilter}
          onChange={handleStateFilterChange}
          onRemove={handleRemoveState}
          labelMap={StateMap}
        >
          {availableStates.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={stateFilter.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </SearchFilterMultiSelect>
      </Grid>
      <Grid item xs={3}>
        <SearchFilterMultiSelect
          label={'Sector / Zoning'}
          value={sectorFilter}
          onChange={handleSectorFilterChange}
          onRemove={handleRemoveSector}
          labelMap={DealSector}
        >
          {availableSectors.map((value) => (
            <MenuItem key={value} value={value}>
              <Checkbox checked={sectorFilter.indexOf(value) > -1} />
              <ListItemText primary={DealSector[value]} />
            </MenuItem>
          ))}
        </SearchFilterMultiSelect>
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          label="Deal Type"
          name="type"
          onChange={handleTypeFilterChange}
          value={typeFilter}
          select
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Asset">Direct Asset Investment</MenuItem>
          <MenuItem value="Fund">Fund (Multi-Asset Investment)</MenuItem>
          {/*<MenuItem value="Sale">Property for Sale to Sponsor</MenuItem>*/}
        </TextField>
        <Box sx={{ py: 1 }} />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          label="Diligence Report"
          name="diligenceFilter"
          onChange={handleDiligenceFilterChange}
          value={diligenceFilter}
          select
        >
          <MenuItem value="All">Any</MenuItem>
          <MenuItem value="DA">Diligence Available</MenuItem>
        </TextField>
        <Box sx={{ py: 1 }} />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          label="Sort By"
          name="sortBy"
          onChange={handleSortByChange}
          value={sortBy}
          select
        >
          <MenuItem value={SortBy.Newest}>
            {SortByLabel[SortBy.Newest]}
          </MenuItem>
          <MenuItem value={SortBy.Oldest}>
            {SortByLabel[SortBy.Oldest]}
          </MenuItem>
          <MenuItem value={SortBy.MinInvestmentDesc}>
            {SortByLabel[SortBy.MinInvestmentDesc]}
          </MenuItem>
          <MenuItem value={SortBy.MinInvestmentAsc}>
            {SortByLabel[SortBy.MinInvestmentAsc]}
          </MenuItem>
          {/*<MenuItem value={SortBy.PurchasePriceDesc}>*/}
          {/*  {SortByLabel[SortBy.PurchasePriceDesc]}*/}
          {/*</MenuItem>*/}
          {/*<MenuItem value={SortBy.PurchasePriceAsc}>*/}
          {/*  {SortByLabel[SortBy.PurchasePriceAsc]}*/}
          {/*</MenuItem>*/}
        </TextField>
      </Grid>
    </Grid>
  );
};
