import { useApi } from '../api/use-api';
import { useMutation, useQueryClient } from 'react-query';
import {
  DealQueryKeys,
  setDealInDealQueryData,
  setDealQueryDataToPrevious,
} from './deals-hook-utils';
import { RetryConfig } from '../api/retry-config';

const useCreateDealImages = () => {
  const request = useApi(false, true);
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, images }) =>
      request({
        url: `/deals/${id}/images`,
        method: 'POST',
        body: images,
      }),
    {
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(queryClient, [DealQueryKeys.manage()], data);
      },
      ...RetryConfig(),
    },
  );
};

const useCreateDealAssetImages = () => {
  const request = useApi(false, true);
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, assetId, images }) =>
      request({
        url: `/deals/${dealId}/assets/${assetId}/images`,
        method: 'POST',
        body: images,
      }),
    {
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(queryClient, [DealQueryKeys.manage()], data);
      },
      ...RetryConfig(),
    },
  );
};

const useDeleteDealImage = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, imageId }) =>
      request({ url: `/deals/${dealId}/images/${imageId}`, method: 'DELETE' }),
    {
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(queryClient, [DealQueryKeys.manage()], data);
      },
      ...RetryConfig(),
    },
  );
};

const useUpdateDealImage = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, imageId, isPriority }) =>
      request({
        url: `/deals/${dealId}/images/${imageId}/priority`,
        method: 'POST',
        body: { isPriority },
      }),
    {
      onMutate: async ({ dealId, imageId, isPriority }) => {
        await queryClient.cancelQueries(DealQueryKeys.detail(dealId));
        const previousValue = queryClient.getQueryData(
          DealQueryKeys.detail(dealId),
        );
        if (previousValue) {
          queryClient.setQueryData(DealQueryKeys.detail(dealId), (old) => {
            const imgIdx = old.images.findIndex((img) => img.id === imageId);
            old.images[imgIdx].isPriority = isPriority;
            old.images.sort((a, b) => {
              if (a.isPriority && !b.isPriority) return -1;
              if (!a.isPriority && b.isPriority) return 1;
              return 0;
            });
            return old;
          });
        }
        return previousValue;
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        queryClient
          .invalidateQueries(DealQueryKeys.detail(variables.dealId))
          .then();
      },
      ...RetryConfig(),
    },
  );
};

const useUpdateDealAssetImage = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, assetId, imageId, isPriority }) =>
      request({
        url: `/deals/${dealId}/images/${imageId}/priority`,
        method: 'POST',
        body: { isPriority },
      }),
    {
      onMutate: async ({ dealId, assetId, imageId, isPriority }) => {
        await queryClient.cancelQueries(DealQueryKeys.detail(dealId));
        const previousValue = queryClient.getQueryData(
          DealQueryKeys.detail(dealId),
        );
        if (previousValue) {
          queryClient.setQueryData(DealQueryKeys.detail(dealId), (old) => {
            const assetIdx = old.assets.findIndex(
              (asset) => asset.id === assetId,
            );
            const imgIdx = old.assets[assetIdx].images.findIndex(
              (img) => img.id === imageId,
            );
            old.assets[assetIdx].images[imgIdx].isPriority = isPriority;
            old.assets[assetIdx].images.sort((a, b) => {
              if (a.isPriority && !b.isPriority) return -1;
              if (!a.isPriority && b.isPriority) return 1;
              return 0;
            });
            return old;
          });
        }
        return previousValue;
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        queryClient
          .invalidateQueries(DealQueryKeys.detail(variables.dealId))
          .then();
      },
      ...RetryConfig(),
    },
  );
};

const useDeleteDealAssetImage = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, assetId, imageId }) =>
      request({
        url: `/deals/${dealId}/assets/${assetId}images/${imageId}`,
        method: 'DELETE',
      }),
    {
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(queryClient, [DealQueryKeys.manage()], data);
      },
      ...RetryConfig(),
    },
  );
};

export {
  useCreateDealImages,
  useCreateDealAssetImages,
  useDeleteDealImage,
  useDeleteDealAssetImage,
  useUpdateDealImage,
  useUpdateDealAssetImage,
};
