import { TableCell, TableRow } from '@mui/material';

export const ExpReturnRow = ({ label, value, unit, isView }) => (
  <TableRow>
    <TableCell sx={{ py: 1 }}>
      <b>
        {label}
        {isView && '*'}
      </b>
    </TableCell>
    <TableCell align={'right'}>
      {value}
      {unit}
    </TableCell>
  </TableRow>
);
