import { localDate } from './dates';
import { EMPTY_EXP_RETURNS } from './exp-returns';
import { EMPTY_CAP_STRUCTURE } from './cap-structure';

export const DealToFormValues = (deal) => {
  return {
    ...deal,
    offeredAt: deal.offeredAt ? localDate(deal.offeredAt) : null,
    description: deal.description ? deal.description : '',
    timeline: deal?.timeline ? deal.timeline : '',
    capStructure: deal?.capStructure
      ? deal.capStructure
      : JSON.stringify(EMPTY_CAP_STRUCTURE),
    expReturns: deal?.expReturns
      ? deal.expReturns
      : JSON.stringify(EMPTY_EXP_RETURNS),
    sectors: deal?.sectors?.length > 0 ? deal.sectors.split(',') : [],
    submit: null,
  };
};
