import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import React from 'react';
import { NotificationChannel } from '../enums/notification-channel.enum';

export const NotificationChannels = ({ ...props }) => {
  const { selectedChannels, handleChange } = props;

  return (
    <Stack>
      <FormControlLabel
        sx={{ pb: 2 }}
        control={
          <Checkbox
            checked={selectedChannels.includes(
              NotificationChannel.DealApproval,
            )}
            value={NotificationChannel.DealApproval}
            onChange={handleChange}
          />
        }
        label={
          <Stack sx={{ ml: 2 }}>
            <Typography variant={'body1'}>New Deals Added</Typography>
            <Typography variant={'body2'} color={'text.secondary'}>
              Receive an email anytime a new deal is added that matches your
              filters
            </Typography>
          </Stack>
        }
      />
      <FormControlLabel
        sx={{ pb: 2 }}
        control={
          <Checkbox
            checked={selectedChannels.includes(
              NotificationChannel.DealFundingAdded,
            )}
            value={NotificationChannel.DealFundingAdded}
            onChange={handleChange}
          />
        }
        label={
          <Stack sx={{ ml: 2 }}>
            <Typography variant={'body1'}>Watchlist Deals Funded</Typography>
            <Typography variant={'body2'} color={'text.secondary'}>
              Receive an email when deals in my Watchlist are 25%, 50%, 75% and
              100% funded
            </Typography>
          </Stack>
        }
      />
    </Stack>
  );
};
