import { v4 as uuid } from 'uuid';
import { useApi } from '../api/use-api';
import { useMutation, useQueryClient } from 'react-query';
import { handleMutate } from '../util/use-handle-mutate';
import {
  DealQueryKeys,
  setDealInDealQueryData,
  setDealQueryDataToPrevious,
  updateDealInList,
} from './deals-hook-utils';
import { RetryConfig } from '../api/retry-config';

const addAssetToDeal = (deal, newAsset) => {
  deal.assets.push(newAsset);
  return deal;
};

const useCreateDealAsset = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, createAssetRequest }) =>
      request({
        url: `/deals/${dealId}/assets`,
        method: 'POST',
        body: createAssetRequest,
      }),
    {
      onMutate: async ({ dealId, createAssetRequest }) => {
        const newAsset = { id: 'asset-' + uuid(), ...createAssetRequest };

        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              addAssetToDeal(deal, newAsset),
            ),
        );
        const prevApprovals = await handleMutate(
          queryClient,
          DealQueryKeys.approvals(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              addAssetToDeal(deal, newAsset),
            ),
        );
        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              addAssetToDeal(deal, newAsset),
            ),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(dealId),
          (old) => addAssetToDeal(old, newAsset),
        );

        return {
          prevManage,
          prevApprovals,
          prevSearch,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [
            DealQueryKeys.approvals(),
            DealQueryKeys.manage(),
            DealQueryKeys.searches(),
          ],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

const updateAssetInDeal = (deal, assetId, updateRequest) => {
  const assetIdx = deal.assets.findIndex((a) => a.id === assetId);
  deal.assets[assetIdx] = {
    ...deal.assets[assetIdx],
    ...updateRequest,
  };
  return deal;
};

const useUpdateDealAsset = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, assetId, updated }) => {
      const cleanRequest = {
        ...updated,
        sqFt: request.sqFt === '' ? null : request.sqFt,
        units: request.units === '' ? null : request.units,
      };
      return request({
        url: `/deals/${dealId}/assets/${assetId}`,
        method: 'PATCH',
        body: cleanRequest,
      });
    },
    {
      onMutate: async ({ dealId, assetId, updated }) => {
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              updateAssetInDeal(deal, assetId, updated),
            ),
        );
        const prevApprovals = await handleMutate(
          queryClient,
          DealQueryKeys.approvals(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              updateAssetInDeal(deal, assetId, updated),
            ),
        );
        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) =>
            updateDealInList(old, dealId, (deal) =>
              updateAssetInDeal(deal, assetId, updated),
            ),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(dealId),
          (old) => updateAssetInDeal(old, assetId, updated),
        );

        return {
          prevManage,
          prevApprovals,
          prevSearch,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [
            DealQueryKeys.approvals(),
            DealQueryKeys.manage(),
            DealQueryKeys.searches(),
          ],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

const removeAssetFromDeal = (old, dealId, assetId) => {
  const deal = old.find((d) => d.id === dealId);
  deal.assets = deal.assets.filter((a) => a.id !== assetId);
  return deal;
};

const useDeleteDealAsset = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, assetId }) =>
      request({
        url: `/deals/${dealId}/assets/${assetId}`,
        method: 'DELETE',
      }),
    {
      onMutate: async ({ dealId, assetId }) => {
        const prevManage = await handleMutate(
          queryClient,
          DealQueryKeys.manage(),
          (old) => removeAssetFromDeal(old, dealId, assetId),
        );
        const prevApprovals = await handleMutate(
          queryClient,
          DealQueryKeys.approvals(),
          (old) => removeAssetFromDeal(old, dealId, assetId),
        );
        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) => removeAssetFromDeal(old, dealId, assetId),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(dealId),
          (old) => {
            old.assets = old.assets.filter((a) => a.id !== assetId);
            return old;
          },
        );

        return {
          prevManage,
          prevApprovals,
          prevSearch,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [
            DealQueryKeys.approvals(),
            DealQueryKeys.manage(),
            DealQueryKeys.searches(),
          ],
          data,
        );
      },
      ...RetryConfig(),
    },
  );
};

export { useCreateDealAsset, useUpdateDealAsset, useDeleteDealAsset };
