import * as Yup from 'yup';
import { DealType } from '../components/enums/deal-type.enum';

const validationShape = {
  name: Yup.string().max(255).required('Name is required'),
  sectors: Yup.array().min(1, 'At least 1 sector is required'),
  strategy: Yup.string()
    .nullable(true)
    .when('type', {
      is: (type) => type === DealType.Asset,
      then: () => Yup.string().required('Strategy is required'),
    }),
  minInvestment: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => type !== DealType.Sale,
      then: () =>
        Yup.number()
          .typeError('Minimum Investment is required')
          .required('Minimum Investment is required')
          .min(1, 'Minimum Investment is not valid')
          .max(2000000000, 'Minimum Investment can not exceed $2,000,000,000')
          .integer('Minimum Investment is not valid'),
    }),
  totalRaise: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => type !== DealType.Sale,
      then: () =>
        Yup.number()
          .typeError('Total Equity Raise is required')
          .required('Total Equity Raise is required')
          .min(1, 'Total Equity Raise is not valid')
          .max(2000000000, 'Total Equity Raise can not exceed $2,000,000,000')
          .integer('Total Equity Raise is not valid'),
    }),
  purchasePrice: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => type === DealType.Sale,
      then: () =>
        Yup.number()
          .typeError('Purchase Price is required')
          .required('Purchase Price is required')
          .min(1, 'Purchase Price is not valid')
          .max(2000000000, 'Purchase Price can not exceed $2,000,000,000')
          .integer('Purchase Price is not valid'),
    }),
  acreage: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => type !== DealType.Fund,
      then: () =>
        Yup.number()
          .positive('Acreage is not valid')
          .integer('Acreage is not valid')
          .nullable(true),
    }),
  sqFt: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => type !== DealType.Fund,
      then: () =>
        Yup.number()
          .positive('Square Footage is not valid')
          .integer('Square Footage is not valid')
          .nullable(true),
    }),
  units: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => type !== DealType.Fund,
      then: () =>
        Yup.number()
          .positive('Number of units is not valid')
          .integer('Number of units is not valid')
          .nullable(true),
    }),
  offeredAt: Yup.date()
    .typeError('Initial Offer Date is invalid')
    .required('Initial Offer Date is required'),
  holdTime: Yup.string()
    .nullable()
    .when('type', {
      is: (type) => type !== DealType.Sale,
      then: () =>
        Yup.string()
          .required('Est. Hold Period is required')
          .max(255, 'Est. Hold Period must not be more than 255 characters'),
    }),
  website: Yup.string()
    .url('Website must be a valid URL')
    .max(255, 'Website can be no more than 255 characters')
    .nullable(true),
  videoUrl: Yup.string()
    .url('Video URL is not valid')
    .max(255, 'Website can be no more than 255 characters')
    .nullable(true),
  description: Yup.string().required('Executive Summary is required'),
  latlng: Yup.string()
    .nullable(true)
    .when('type', {
      is: (type) => type !== DealType.Fund,
      then: () => Yup.string().required('Address is required'),
    }),
  images: Yup.array().min(1, 'At least 1 image is required'),
  timeline: Yup.string()
    .nullable()
    .when('type', {
      is: (type) => type !== DealType.Sale,
      then: () => Yup.string().required('Timeline section is required'),
    }),
};

export const DealSubmitValidationSchema = Yup.object().shape(validationShape);
