import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { DialogActionBar } from './dialog-action-bar';

export const LeavePageConfirmDialog = ({ blocker }) => {
  const open = blocker.state === 'blocked';

  const onSubmit = () => {
    blocker.proceed();
  };

  const onClose = () => {
    blocker.reset();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        You have unsaved changes
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to leave this page?
        </DialogContentText>
      </DialogContent>
      <DialogActionBar
        submitLabel={'Yes, exit without saving'}
        cancelLabel={'Nevermind'}
        onCancel={onClose}
        onSubmit={onSubmit}
        submitColor={'error'}
      />
    </Dialog>
  );
};
