import React from 'react';
import InternalServerErrorPage from './InternalServerErrorPage';
import * as Sentry from '@sentry/react';

class OZDErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error('Caught an error:', error, errorInfo);
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <InternalServerErrorPage />;
    }

    return this.props.children;
  }
}

export default OZDErrorBoundary;
