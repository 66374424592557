export const DealType = {
  Asset: 'Asset',
  Fund: 'Fund',
  Sale: 'Sale',
};

export const DealTypeLabel = {
  [DealType.Asset]: 'Direct',
  [DealType.Fund]: 'Fund',
  [DealType.Sale]: 'Sale',
};
