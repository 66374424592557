import { useState } from 'react';
import { useFormik } from 'formik';
import { AssetSaveValidationSchema } from '../../../validation/asset-save-validation-schema';
import { useUpdateDealAsset } from '../../hooks/deals/use-deal-asset';
import {
  useCreateDealAssetImages,
  useDeleteDealAssetImage,
} from '../../hooks/deals/use-deal-images';
import { useSnackbar } from '../../hooks/util/use-snackbar';
import { EditAssetTopNav } from './edit-asset-top-nav';
import {
  Card,
  CardContent,
  Stack,
  Box,
  Unstable_Grid2 as Grid,
  TextField,
  Typography,
  Container,
} from '@mui/material';
import { SectionHeader } from '../../util/section-header';
import { DealStrategy } from '../../enums/deal-strategy.enum';
import { EditDealAssetImages } from './edit-asset-images';
import MapView from '../../maps/map-view';
import AddressAutocompleteTextField from '../../maps/address-autocomplete-textfield';
import { AddressText } from '../../maps/address-text';
import WysiwygEditor from '../../util/wysiwyg/wysiwyg-editor';

export const EditAsset = ({ deal, assetIdx, closeEditAsset }) => {
  const asset = deal.assets[assetIdx];
  const { render: renderSnackbar, setSnackbar } = useSnackbar();
  const [lastSavedValues, setLastSavedValues] = useState({
    ...asset,
    submit: null,
  });

  const updateAsset = useUpdateDealAsset();
  const createDealAssetImages = useCreateDealAssetImages();
  const deleteDealAssetImage = useDeleteDealAssetImage();

  const initialValues = {
    ...asset,
    submit: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AssetSaveValidationSchema,
    validateOnMount: true,
    onSubmit: async (values, helpers) => {
      try {
        await updateAsset.mutateAsync({
          dealId: deal.id,
          assetId: asset.id,
          updated: { ...values },
        });
        setSnackbar({ message: 'Project has been updated' });
        setLastSavedValues({ ...values });
      } catch (err) {
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: errObj.message[0] });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleDescriptionChange = (newDesc) => {
    formik.setFieldValue('description', newDesc, true);
  };

  const handleImagesChange = (newImages) => {
    formik.setFieldValue('images', newImages, true);
  };
  const handleTimelineChange = (newTimeline) => {
    formik.setFieldValue('timeline', newTimeline, true);
  };
  const handleCapStructureChange = async (newCapStructure) => {
    await formik.setFieldValue('capStructure', newCapStructure, true);
  };
  const handleExpReturnsChange = (newExpReturns) => {
    formik.setFieldValue('expReturns', newExpReturns, true);
  };

  const hasError = (key) => {
    return formik.touched[key] && formik.errors[key];
  };

  const numberValue = (formValue) => {
    return formValue !== null && formValue !== undefined ? formValue : '';
  };

  const isSaving =
    updateAsset.isLoading ||
    createDealAssetImages.isLoading ||
    deleteDealAssetImage.isLoading;
  const latlng = formik.values.latlng ? JSON.parse(formik.values.latlng) : null;

  const isChanged =
    lastSavedValues &&
    Object.entries(formik.values).reduce((acc, [key, value]) => {
      const hasChanged =
        key !== 'images' &&
        JSON.stringify(lastSavedValues[key]) !== JSON.stringify(value);
      if (hasChanged) {
        // console.log('from ' + lastSavedValues[key]);
        // console.log('to ' + value);
        // console.log('key is changed ' + key);
        acc = acc + 1;
      }
      return acc;
    }, 0) > 0;

  const handleAddImage = async ({ dealId, assetId, images }) => {
    try {
      const newDeal = await createDealAssetImages.mutateAsync({
        dealId,
        assetId,
        images,
      });
      const theAsset = newDeal.assets.find((a) => a.id === assetId);
      handleImagesChange(theAsset.images);
      setSnackbar({ message: 'Project image has been added.' });
    } catch (e) {
      setSnackbar({
        message: `Error: ${e}.  Make sure the file size and type are correct.`,
      });
    }
  };

  const handleRemoveImage = async ({ dealId, assetId, imageId }) => {
    try {
      await deleteDealAssetImage.mutateAsync({
        dealId,
        assetId,
        imageId,
      });
      const theAsset = deal.assets.find((a) => a.id === assetId);
      handleImagesChange(theAsset.images);
    } catch (e) {
      setSnackbar({
        message: `Error: ${e}.  Make sure the file size and type are correct.`,
      });
    }
  };

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Container maxWidth={'lg'}>
      <EditAssetTopNav
        formik={formik}
        isChanged={isChanged}
        isSaving={isSaving}
        onSubmit={handleSubmit}
        onBack={closeEditAsset}
      />
      <div id="editor"></div>
      {renderSnackbar()}
      <Card>
        <CardContent>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <SectionHeader
              title={`Edit Project in ${deal.name}`}
              subtitle={
                'Enter the following information about the project. Fields marked with (*) are required.'
              }
            />
          </Stack>
          <Grid container spacing={3}>
            <Grid xs={6} md={6}>
              <TextField
                error={!!hasError('name')}
                helperText={hasError('name')}
                fullWidth
                label="Name"
                name="name"
                required
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name || ''}
              />
            </Grid>
            <Grid xs={6} md={6}>
              <TextField
                error={!!hasError('strategy')}
                fullWidth
                helperText={hasError('strategy')}
                label="Strategy"
                name="strategy"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.strategy || ''}
                select
                SelectProps={{ native: true }}
              >
                <option value=""></option>
                {Object.keys(DealStrategy).map((strategy) => (
                  <option key={strategy} value={strategy}>
                    {DealStrategy[strategy]}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid xs={6} md={3}>
              <TextField
                error={!!hasError('acreage')}
                helperText={hasError('acreage')}
                fullWidth
                label="Acreage"
                name="acreage"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="number"
                value={numberValue(formik.values.acreage)}
              />
            </Grid>
            <Grid xs={6} md={3}>
              <TextField
                error={!!hasError('sqFt')}
                helperText={hasError('sqFt')}
                label="Square Footage"
                name="sqFt"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={numberValue(formik.values.sqFt)}
                type="number"
                fullWidth
              />
            </Grid>
            <Grid xs={6} md={3}>
              <TextField
                error={!!hasError('units')}
                helperText={hasError('units')}
                label="Number of Units"
                name="units"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={numberValue(formik.values.units)}
                type="number"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid xs={6}>
              <SectionHeader
                title={'Location'}
                subtitle={'The address of the project'}
              />
              <AddressAutocompleteTextField
                formik={formik}
                fullWidth
                label="Address"
                name="address1"
              />
              {latlng && (
                <Stack sx={{ mt: 2 }}>
                  <MapView
                    markers={[{ lat: latlng.lat, lng: latlng.lng }]}
                    height={150}
                  />
                  <AddressText
                    address1={formik.values.address1}
                    address2={formik.values.address2}
                    city={formik.values.city}
                    state={formik.values.state}
                    zip={formik.values.zip}
                  />
                </Stack>
              )}
            </Grid>
            <Grid
              xs={6}
              sx={{
                '.ck-editor__editable': {
                  minHeight: '200px',
                },
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <SectionHeader
                  title={'Project Description'}
                  subtitle={'An detailed description of the project'}
                  color={hasError('description') ? 'error' : 'text'}
                />
              </Stack>
              <WysiwygEditor
                name="description"
                title={'Description'}
                placeholder={'An executive summary of the project.'}
                height={150}
                data={formik.values.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleDescriptionChange(data);
                }}
              />
              {hasError('description') && (
                <Typography color="error" sx={{ mt: 1 }} variant="caption">
                  {formik.errors.description}
                </Typography>
              )}
            </Grid>
            <Grid xs={6}>
              <EditDealAssetImages
                dealId={deal.id}
                asset={asset}
                onCreateImage={handleAddImage}
                onDeleteImage={handleRemoveImage}
                isCreating={createDealAssetImages.isLoading}
                isDeleting={deleteDealAssetImage.isLoading}
                setSnackbar={setSnackbar}
              />
            </Grid>
            {/*<Grid xs={6}>*/}
            {/*  <EditTimeline*/}
            {/*    onChange={(newValue) => handleTimelineChange(newValue)}*/}
            {/*    initialValue={formik.values.timeline}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid xs={6}>*/}
            {/*  <EditExpReturns*/}
            {/*    onChange={(newValue) => handleExpReturnsChange(newValue)}*/}
            {/*    initialValue={formik.values.expReturns}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid xs={6}>*/}
            {/*  <EditCapStructure*/}
            {/*    onChange={(newValue) => handleCapStructureChange(newValue)}*/}
            {/*    initialValue={formik.values.capStructure}*/}
            {/*  />*/}
            {/*</Grid>*/}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};
