import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { DialogActionBar } from './dialog-action-bar';

const DealApproveDialog = (props) => {
  const { open, title, onClose, onSubmit, multiple, isLoading } = { ...props };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Approve Deal{multiple ? 's' : ''}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant={'h5'}>{title}</Typography>
          <Typography variant={'body1'}>
            Approving {multiple ? 'these deals' : 'this deal'} will make{' '}
            {multiple ? 'them' : 'it'} publicly available to all users on the
            platform. Are you sure you want to approve{' '}
            {multiple ? 'these deals' : 'this deal'}?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActionBar
        isLoading={isLoading}
        loadingLabel={'Approving...'}
        submitLabel={'Approve'}
        onCancel={onClose}
        onSubmit={handleSubmit}
        submitColor={'success'}
      />
    </Dialog>
  );
};

export default DealApproveDialog;
