import React, { useEffect, useState } from 'react';
import { Button, Stack, Tooltip } from '@mui/material';
import UnarchiveOutlined from '@mui/icons-material/UnarchiveOutlined';
import DeleteForever from '@mui/icons-material/DeleteForever';
import UnpublishedOutlined from '@mui/icons-material/UnpublishedOutlined';
import ArchiveOutlined from '@mui/icons-material/ArchiveOutlined';
import Approval from '@mui/icons-material/Approval';
import EditOutlined from '@mui/icons-material/EditOutlined';
import MonetizationOnOutlined from '@mui/icons-material/MonetizationOnOutlined';
import { Role } from '../../enums/role.enum';
import { DealStatus } from '../../enums/deal-status.enum';
import DealApproveDialog from '../../dialogs/deal-approve-dialog';
import DealArchiveDialog from '../../dialogs/deal-archive-dialog';
import DealUnlistDialog from '../../dialogs/deal-unlist-dialog';
import DealRejectDialog from '../../dialogs/deal-reject-dialog';
import DealDeleteDialog from '../../dialogs/deal-delete-dialog';
import {
  useApproveDeal,
  useRejectDeal,
  useUnlistDeal,
} from '../../hooks/deals/use-deal-approval';
import {
  useArchiveDeal,
  useDeleteDeal,
  useUpdateDeal,
} from '../../hooks/deals/use-deal';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import { success } from '../../theme/colors';
import Link from '@mui/icons-material/Link';
import copy from 'copy-to-clipboard';
import { useSessionUser } from '../../hooks/session/useSessionUser';
import { useNavigate } from 'react-router-dom';
import {
  useAddDealFunding,
  useUpdateDealFunding,
} from '../../hooks/deals/use-deal-funding';
import { getDealShareLink } from '../../../helpers/get-share-link';
import DealEditFundingDialog from '../../dialogs/deal-edit-funding-dialog';
import { smartDateFormat, utcToLocalTime } from '../../../helpers/dates';
import { DealStatusChip } from '../chips/deal-status-chip';
import MoreEllipsesMenu from '../../util/more-ellipses-menu';
import { DoDisturb } from '@mui/icons-material';
import { PublishButton } from '../publish-button';
import { DealPaidChip } from '../chips/deal-paid-chip';

const DealDialogs = {
  Approve: 'Approve',
  Archive: 'Archive',
  Submit: 'Submit',
  Unlist: 'Unlist',
  Reject: 'Reject',
  Delete: 'Delete',
  Funding: 'Funding',
};

export const DealActionsOrganization = ({
  deal,
  isLoading,
  setSnackbar,
  onEditClick,
  canSubmitForApproval,
  onSubmitForApproval,
}) => {
  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();

  const isSysAdmin = sessionUser?.role === Role.SysAdmin;
  const isOrgAdmin = sessionUser?.role === Role.OrgAdmin;

  const isDraft = deal?.status === DealStatus.Draft;
  const isPendingApproval = deal?.status === DealStatus.PendingReview;
  const isApproved = deal?.status === DealStatus.Approved;
  const isArchived = !isLoading && deal?.archivedAt;

  const isOwner =
    (isOrgAdmin && deal?.orgId === sessionUser?.orgId) ||
    deal?.ownerId === sessionUser?.id;

  const [showDialog, setShowDialog] = useState(false);

  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  useEffect(() => {
    if (shareLinkCopied) {
      setTimeout(() => setShareLinkCopied(false), 3000);
    }
  }, [shareLinkCopied]);

  const approveDeal = useApproveDeal();
  const unlistDeal = useUnlistDeal();
  const rejectDeal = useRejectDeal();
  const deleteDeal = useDeleteDeal();
  const archiveDeal = useArchiveDeal();
  const addFunding = useAddDealFunding();
  const updateFunding = useUpdateDealFunding();

  if (isLoading) {
    return <></>;
  }

  const closeDialogs = () => {
    setShowDialog(null);
  };

  const openApprove = (e) => {
    e.stopPropagation();
    setShowDialog(DealDialogs.Approve);
  };

  const handleApprove = () => {
    try {
      approveDeal.mutate({
        id: deal.id,
      });
      setSnackbar({
        message: 'This deal has been approved and is now publicly listed.',
      });
      closeDialogs();
    } catch (e) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  const openUnlist = (e) => {
    e.stopPropagation();
    setShowDialog(DealDialogs.Unlist);
  };

  const handleUnlist = async () => {
    try {
      unlistDeal.mutate({
        deal,
      });
      setSnackbar({
        message: isPendingApproval
          ? 'This deal has been removed from review'
          : 'This deal has been removed from publication',
      });
      closeDialogs();
    } catch (e) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  const handleArchive = async () => {
    try {
      archiveDeal.mutate({
        dealId: deal.id,
        isUndo: isArchived,
      });
      closeDialogs();
      setSnackbar({
        message: `This deal has been ${isArchived ? 'unarchived' : 'archived'}.`,
      });
    } catch (e) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  const openArchive = (e) => {
    e.stopPropagation();
    if (isArchived) {
      handleArchive(); //skip confirm if unarchiving
    } else {
      setShowDialog(DealDialogs.Archive);
    }
  };

  const openReject = (e) => {
    e.stopPropagation();
    setShowDialog(DealDialogs.Reject);
  };

  const handleReject = async (message) => {
    try {
      rejectDeal.mutate({
        id: deal.id,
        message,
      });
      closeDialogs();
      setSnackbar({
        message: `This deal has been rejected and message sent to the owner.`,
      });
    } catch (e) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  const openDelete = (e) => {
    e.stopPropagation();
    setShowDialog(DealDialogs.Delete);
  };

  const handleDelete = async () => {
    try {
      deleteDeal.mutate({ id: deal?.id });
      closeDialogs();
      navigate('/deals');
    } catch (e) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  const openAddFunding = (e) => {
    e.stopPropagation();
    setShowDialog(DealDialogs.Funding);
  };

  const handleSaveFunding = (funding) => {
    const isAdd = !funding.id;
    try {
      if (isAdd) {
        addFunding.mutate({
          dealId: deal.id,
          request: funding,
        });
        setSnackbar({ message: 'Deal funding has been added.' });
      } else {
        const { id, ...request } = funding;
        updateFunding.mutate({
          dealId: deal.id,
          fundingId: id,
          request,
        });
        setSnackbar({ message: 'Deal funding has been updated.' });
      }
      closeDialogs();
    } catch (e) {
      setSnackbar({ message: 'Error: ' + JSON.stringify(e) });
    }
  };

  const handleCopyShareLink = (e) => {
    e.stopPropagation();
    copy(getDealShareLink(deal));
    setShareLinkCopied(true);
  };

  const menuItems = [];

  if (isSysAdmin || (isOwner && !isApproved)) {
    menuItems.push({
      primary: 'Edit Details',
      secondary: 'Change the details about this deal',
      icon: <EditOutlined />,
      onClick: onEditClick,
    });
  }

  if (deal?.totalRaise && (isSysAdmin || isOwner)) {
    menuItems.push({
      primary: 'Update Funding',
      secondary: 'Update the amount of funding for this deal',
      icon: <MonetizationOnOutlined />,
      onClick: openAddFunding,
    });
  }

  if (isDraft && (isSysAdmin || isOwner)) {
    menuItems.push({
      primary: 'Archive',
      secondary: 'Remove this deal from your managed deals',
      icon: <ArchiveOutlined />,
      onClick: openArchive,
    });
  }

  if (isArchived && (isSysAdmin || isOwner)) {
    menuItems.push(
      {
        primary: 'Unarchive',
        secondary: 'Add this deal back to your managed deals',
        icon: <UnarchiveOutlined />,
        onClick: openArchive,
      },
      {
        primary: 'Delete Permanently',
        secondary: 'Irreversibly delete this deal',
        icon: <DeleteForever />,
        onClick: openDelete,
        color: 'error',
      },
    );
  }

  if ((isApproved || isPendingApproval) && (isSysAdmin || isOwner)) {
    menuItems.push({
      primary: isPendingApproval
        ? 'Remove from Review'
        : 'Remove from Publication',
      secondary: isPendingApproval
        ? 'Cancel submission and set to Draft'
        : 'Remove from public listing and set to Draft',
      icon: <UnpublishedOutlined />,
      onClick: openUnlist,
      color: 'error',
    });
  }

  const activeSub = deal.subscriptions?.find((s) => !s.isCancelled);

  return (
    <>
      <DealApproveDialog
        open={showDialog === DealDialogs.Approve}
        title={deal?.name}
        onClose={closeDialogs}
        onSubmit={handleApprove}
      />
      <DealArchiveDialog
        open={showDialog === DealDialogs.Archive}
        title={deal?.name}
        onClose={closeDialogs}
        onSubmit={handleArchive}
        isLoading={archiveDeal.isLoading}
      />
      <DealUnlistDialog
        open={showDialog === DealDialogs.Unlist}
        title={deal?.name}
        onClose={closeDialogs}
        onSubmit={handleUnlist}
        isUnlist={deal?.status === DealStatus.Approved}
        isLoading={unlistDeal.isLoading}
      />
      <DealRejectDialog
        open={showDialog === DealDialogs.Reject}
        title={deal?.name}
        onClose={closeDialogs}
        onSubmit={handleReject}
        isLoading={rejectDeal.isLoading}
      />
      <DealDeleteDialog
        open={showDialog === DealDialogs.Delete}
        title={deal?.name}
        onClose={closeDialogs}
        onSubmit={handleDelete}
        isLoading={deleteDeal.isLoading}
      />
      <DealEditFundingDialog
        open={showDialog === DealDialogs.Funding}
        deal={deal}
        funding={deal?.funding?.length > 0 ? deal.funding[0] : {}}
        onClose={closeDialogs}
        onSubmit={handleSaveFunding}
        isLoading={addFunding.isLoading}
      />
      <Stack
        spacing={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ my: 2 }}
      >
        {isPendingApproval && isSysAdmin && (
          <>
            <Tooltip
              placement={'bottom'}
              title={'Reject this deal and provide reason'}
            >
              <Button
                color={'error'}
                size={'small'}
                variant={'contained'}
                onClick={openReject}
                startIcon={<DoDisturb />}
              >
                Reject this Deal
              </Button>
            </Tooltip>
            <Tooltip
              placement={'bottom'}
              title={'Approve this deal and list publicly'}
            >
              <Button
                color={'success'}
                size={'small'}
                variant={'contained'}
                onClick={openApprove}
                startIcon={<Approval />}
              >
                Approve this Deal
              </Button>
            </Tooltip>
          </>
        )}
        {isDraft && (isSysAdmin || isOwner) && (
          <PublishButton
            deal={deal}
            canSubmit={canSubmitForApproval}
            isSaved
            isSysAdmin={isSysAdmin}
            onSubmitForApproval={onSubmitForApproval}
          />
        )}

        {isPendingApproval && (isSysAdmin || isOwner) && (
          <Tooltip
            placement={'bottom'}
            title={
              'Submitted ' + smartDateFormat(utcToLocalTime(deal.submittedAt))
            }
          >
            <span>
              <DealStatusChip deal={deal} />
            </span>
          </Tooltip>
        )}

        {isApproved && (isSysAdmin || isOwner) && (
          <>
            <Tooltip
              placement={'bottom'}
              title={
                'Approved and publicly listed as of ' +
                smartDateFormat(utcToLocalTime(deal.approvedAt), 'MMM d')
              }
            >
              <Stack
                direction={'row'}
                alignItems={'center'}
                columnGap={1}
                sx={{ cursor: 'default' }}
              >
                <CheckCircle color={'success'} />
                <Typography
                  sx={{
                    fontSize: '0.8125rem',
                    fontWeight: 600,
                    color: success['main'],
                  }}
                >
                  Approved
                </Typography>
              </Stack>
            </Tooltip>
            <Tooltip
              placement={'bottom'}
              title={
                shareLinkCopied
                  ? 'Share Link Copied to Clipboard!'
                  : 'Copy Share Link'
              }
            >
              <Stack alignItems={'center'}>
                <Button
                  size={'small'}
                  variant={'text'}
                  onClick={handleCopyShareLink}
                  startIcon={<Link />}
                >
                  Get Share Link
                </Button>
              </Stack>
            </Tooltip>
          </>
        )}

        {activeSub && <DealPaidChip />}

        {menuItems?.length > 0 && <MoreEllipsesMenu items={menuItems} />}
      </Stack>
    </>
  );
};
