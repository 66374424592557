import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { DealInvestStatus } from '../enums/deal-invest-status.enum';
import { DialogActionBar } from './dialog-action-bar';

const DealInvestStatusDialog = ({
  open,
  title,
  onClose,
  onSubmit,
  deal,
  isLoading,
}) => {
  const initialStatus = deal?.investments[0];

  const initialValues = {
    status: initialStatus?.status || 'Evaluating',
    amount: initialStatus?.amount,
    reason: initialStatus?.reason,
    submit: null,
  };

  const validationShape = {
    status: Yup.string().required('Status is required'),
    amount: Yup.number()
      .max(2000000000)
      .positive('Investment Amount is not valid')
      .integer('Investment Amount is not valid')
      .nullable(true),
    // reason: Yup.string().required('Reason is required'),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await onSubmit({ ...values, dealId: deal.id });
      } catch (err) {
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ [errObj.field]: errObj.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    formik.setFieldValue('status', newStatus, true);
    formik.setFieldValue('amount', null, true);
    formik.setFieldValue('reason', null, true);
  };

  const handleReasonChange = (e) => {
    formik.setFieldValue('reason', e.target.value, true);
  };

  const handleSubmit = () => {
    formik.submitForm();
  };

  useEffect(() => {
    const newStatus = deal?.investments[0];
    formik.setFieldValue('status', newStatus?.status || 'Evaluating', true);
    formik.setFieldValue('amount', newStatus?.amount, true);
    formik.setFieldValue('reason', newStatus?.reason, true);
  }, [deal]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Investment Status</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant={'h5'}>{title}</Typography>
          <Typography variant={'body1'}>
            Set your current investment status for this deal. This information
            is private, not shared with anyone, and helps to keep track of the
            deals you are evaluating.
          </Typography>
          <TextField
            error={!!formik.errors.status}
            fullWidth
            helperText={formik.errors.status}
            label="Status"
            name="status"
            onBlur={formik.handleBlur}
            onChange={handleStatusChange}
            value={formik.values.status || ''}
            select
            SelectProps={{ native: true }}
          >
            {Object.keys(DealInvestStatus).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </TextField>
          {formik.values.status === DealInvestStatus.Invested && (
            <TextField
              error={!!formik.errors.amount}
              helperText={formik.errors.amount}
              label="Investment Amount"
              name="amount"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.amount || ''}
              type="number"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          )}
          {formik.values.status !== DealInvestStatus.Evaluating && (
            <TextField
              multiline
              rows={5}
              error={!!(formik.touched.reason && formik.errors.reason)}
              fullWidth
              helperText={formik.touched.reason && formik.errors.reason}
              placeholder={'Enter a reason for your decision'}
              label="Reason"
              name="reason"
              onBlur={formik.handleBlur}
              onChange={handleReasonChange}
              value={formik.values.reason}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActionBar
        isLoading={isLoading}
        loadingLabel={'Updating...'}
        submitLabel={'Update'}
        onCancel={onClose}
        onSubmit={handleSubmit}
        disabled={
          formik.isSubmitting || formik.values.reason === '' || !formik.isValid
        }
      />
    </Dialog>
  );
};

export default DealInvestStatusDialog;
