import React from 'react';
import { Stack, Typography } from '@mui/material';

export default function NotFoundErrorPage() {
  return (
    <Stack
      spacing={5}
      sx={{ mt: 5 }}
      alignItems="center"
      justifyContent="center"
    >
      <img
        alt="Page Not Found"
        src={'/images/errors/error-404.png'}
        style={{
          width: 200,
          height: 200,
        }}
      />
      <Typography variant={'h6'}>Page Not Found</Typography>
    </Stack>
  );
}
