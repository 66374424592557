import { Chip, Tooltip } from '@mui/material';
import { Flag } from '@mui/icons-material';
import React from 'react';
import { Qualifications } from '../../helpers/qualifications';

export const QualificationBadges = ({ qualifications, iconsOnly = false }) => {
  const isAccreditedInvestor = qualifications?.isAccreditedInvestor;
  const isQualifiedPurchaser = qualifications?.isQualifiedPurchaser;
  const isQualifiedClient = qualifications?.isQualifiedClient;
  const isRIA = qualifications?.isRIA;
  const isNotQualified = qualifications?.isNotQualified;

  return (
    <>
      {isAccreditedInvestor && (
        <>
          {iconsOnly ? (
            <Tooltip title={'Accredited Investor'}>
              {Qualifications.AccreditedInvestor.icon}
            </Tooltip>
          ) : (
            <Chip
              icon={Qualifications.AccreditedInvestor.icon}
              label={'Accredited Investor'}
              variant="outlined"
            />
          )}
        </>
      )}
      {isQualifiedPurchaser && (
        <>
          {iconsOnly ? (
            <Tooltip title={'Qualified Purchaser'}>
              {Qualifications.QualifiedPurchaser.icon}
            </Tooltip>
          ) : (
            <Chip
              icon={Qualifications.QualifiedPurchaser.icon}
              label={'Qualified Purchaser'}
              variant="outlined"
            />
          )}
        </>
      )}
      {isQualifiedClient && (
        <>
          {iconsOnly ? (
            <Tooltip title={'Qualified Client'}>
              {Qualifications.QualifiedClient.icon}
            </Tooltip>
          ) : (
            <Chip
              icon={Qualifications.QualifiedClient.icon}
              label={'Qualified Client'}
              variant="outlined"
            />
          )}
        </>
      )}
      {isRIA && (
        <>
          {iconsOnly ? (
            <Tooltip title={'Registered Investment Advisor'}>
              Qualifications.RIA.icon
            </Tooltip>
          ) : (
            <Chip
              icon={Qualifications.RIA.icon}
              label={'R.I.A.'}
              variant="outlined"
            />
          )}
        </>
      )}
      {isNotQualified && (
        <>
          {iconsOnly ? (
            <Tooltip title={'Not a Qualified Investor'}>
              <Flag />
            </Tooltip>
          ) : (
            <Chip
              icon={<Flag />}
              label={'Not a Qualified Investor'}
              variant="outlined"
            />
          )}
        </>
      )}
    </>
  );
};
