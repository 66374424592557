import {
  Box,
  Checkbox,
  Chip,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  useNotifications,
  useViewNotifications,
} from '../../components/hooks/notifications/use-notifications';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import { NotificationChannel } from '../../components/enums/notification-channel.enum';
import { NotificationRow } from '../../components/notifications/notification-row';

const NotificationChannelMap = {
  [NotificationChannel.DealMessage]: 'New Deal Message',
  [NotificationChannel.DealApproval]: 'New Deal Added',
};

export default function Notifications() {
  const { data: notifications, isLoading } = useNotifications({});

  const [channelFilter, setChannelFilter] = useState([]);

  const markAsRead = useViewNotifications();

  const handleChannelFilterChange = (event) => {
    setChannelFilter(event.target.value);
  };
  const handleRemoveChannel = (channelToRemove) => {
    setChannelFilter(channelFilter.filter((s) => s !== channelToRemove));
  };

  const filteredData = notifications?.filter(
    (notification) =>
      !channelFilter?.length || channelFilter.includes(notification.channel),
  );

  useEffect(() => {
    if (notifications?.length) {
      const unreadNotifications = notifications.filter(
        (n) => n.views?.length === 0,
      );
      if (unreadNotifications.length) {
        markAsRead.mutate({
          notificationIds: unreadNotifications.map((n) => n.id),
        });
      }
    }
  }, [notifications]);

  return (
    <Container maxWidth="xl">
      <Typography variant="h4">Notifications</Typography>
      <Box
        sx={{
          flexGrow: 1,
          pt: 2,
        }}
      >
        {isLoading || !notifications ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {notifications?.length > 0 && (
              <Grid container sx={{ pb: 2 }} spacing={3}>
                <Grid item xs={3} sx={{ my: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel shrink={true}>Type</InputLabel>
                    <Select
                      labelId="channel-label"
                      label="Type"
                      value={channelFilter}
                      onChange={handleChannelFilterChange}
                      multiple
                      displayEmpty
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          {channelFilter.length === 0 ? (
                            <Chip
                              key={'All'}
                              label={`All Types Included`}
                              variant={'outlined'}
                            />
                          ) : (
                            channelFilter.map((value) => (
                              <Chip
                                sx={{ mr: 1 }}
                                key={value}
                                label={NotificationChannelMap[value]}
                                variant="outlined"
                                deleteIcon={
                                  <CancelIcon
                                    onMouseDown={(event) =>
                                      event.stopPropagation()
                                    }
                                  />
                                }
                                onDelete={(e) => handleRemoveChannel(value)}
                              />
                            ))
                          )}
                        </Box>
                      )}
                    >
                      <MenuItem value={NotificationChannel.DealMessage}>
                        <Checkbox
                          checked={
                            channelFilter.indexOf(
                              NotificationChannel.DealMessage,
                            ) > -1
                          }
                        />
                        <ListItemText
                          primary={
                            NotificationChannelMap[
                              NotificationChannel.DealMessage
                            ]
                          }
                        />
                      </MenuItem>
                      <MenuItem value={NotificationChannel.DealApproval}>
                        <Checkbox
                          checked={
                            channelFilter.indexOf(
                              NotificationChannel.DealApproval,
                            ) > -1
                          }
                        />
                        <ListItemText
                          primary={
                            NotificationChannelMap[
                              NotificationChannel.DealApproval
                            ]
                          }
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={9}></Grid>
                {filteredData?.length > 0 &&
                  filteredData.map((note) => <NotificationRow note={note} />)}
              </Grid>
            )}
            {!filteredData?.length && (
              <Typography variant="body1">No recent notifications</Typography>
            )}
          </>
        )}
      </Box>
    </Container>
  );
}
