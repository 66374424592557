import { handleMutate } from '../util/use-handle-mutate';
import { useApi } from '../api/use-api';
import { useMutation, useQueryClient } from 'react-query';
import {
  DealQueryKeys,
  setDealQueryDataToPrevious,
  updateDealInList,
} from './deals-hook-utils';
import { RetryConfig } from '../api/retry-config';

const useUpdateDealInvestStatus = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, status, amount, reason }) =>
      request({
        url: `/deals/${dealId}/invest-status`,
        method: 'POST',
        body: { status, amount, reason },
      }),
    {
      onMutate: async ({ dealId, status, amount, reason }) => {
        const temp = { dealId, status, amount, reason };

        const prevWatchlist = await handleMutate(
          queryClient,
          DealQueryKeys.watchlist(),
          (old) =>
            updateDealInList(old, dealId, (deal) => {
              deal.investments = [temp];
              return deal;
            }),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(dealId),
          (old) => ({
            ...old,
            investments: [temp],
          }),
        );

        return {
          prevWatchlist,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {},
      ...RetryConfig(),
    },
  );
};

export { useUpdateDealInvestStatus };
