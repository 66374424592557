import { Alert, Stack } from '@mui/material';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useApproveOrganization } from '../hooks/organizations/use-organizations';
import { OrganizationsTable } from './organizations-table';

export default function OrganizationApprovals({
  organizations,
  isLoading,
  setSnackbar,
}) {
  const approveOrganization = useApproveOrganization();

  const handleApprove = async (organization) => {
    try {
      approveOrganization.mutate({
        id: organization.id,
      });
      setSnackbar({ message: organization.name + ' has been approved!' });
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
    }
  };

  return isLoading ? (
    <Stack alignItems="center">
      <CircularProgress />
    </Stack>
  ) : (
    <>
      {organizations?.length > 0 ? (
        <OrganizationsTable
          items={organizations}
          onApproveClick={handleApprove}
        />
      ) : (
        <Alert severity="success" sx={{ width: '100%' }}>
          {`You're all caught up! There are no Organizations to approve at this time. 😎`}
        </Alert>
      )}
    </>
  );
}
