import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { DialogActionBar } from './dialog-action-bar';

const DealDeleteCapStructureLineItemDialog = ({
  open,
  title,
  idxToDelete,
  onClose,
  onSubmit,
  isDebt,
}) => {
  const handleSubmit = () => {
    onSubmit(idxToDelete);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant={'h5'}>{title}</Typography>
          <Typography variant={'body1'}>
            Delete this {isDebt ? 'debt' : 'equity'} line item from your capital
            structure?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActionBar
        loadingLabel={'Deleting...'}
        submitColor={'error'}
        submitLabel={'Delete'}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default DealDeleteCapStructureLineItemDialog;
