import {
  differenceInCalendarDays,
  differenceInHours,
  format,
  formatDistanceStrict,
} from 'date-fns';
import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Avatar,
  Box,
  Button,
  CardActions,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { MessageThreadHeader } from './message-thread-header';
import { localTime } from '../../helpers/dates';
import { useSessionUser } from '../hooks/session/useSessionUser';
import { Role } from '../enums/role.enum';

export const MessageThreadView = ({ thread, onReply, isReplySending }) => {
  const sessionUser = useSessionUser();
  const now = new Date();
  const createdAtLocal = localTime(thread.createdAt);
  const daysSinceSent = differenceInCalendarDays(now, createdAtLocal);
  const hoursSinceSent = differenceInHours(now, createdAtLocal);
  const formatCreatedAt = formatDistanceStrict(createdAtLocal, now, {
    unit: hoursSinceSent <= 1 ? 'minute' : daysSinceSent <= 1 ? 'hour' : 'day',
  });

  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  }, [thread]);

  const initialValues = {
    message: '',
    submit: null,
  };

  const validationShape = {
    message: Yup.string().required('Message is required'),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, helpers) => {
      try {
        onReply({ replyThreadId: thread.id, message: values.message });
        formik.handleReset();
        setTimeout(
          () => scrollRef.current?.scrollIntoView({ behavior: 'smooth' }),
          100,
        );
      } catch (err) {
        const errObj = JSON.parse(err.error);
        helpers.setStatus({ success: false });
        helpers.setErrors({ [errObj.field]: errObj.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const onSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <>
      <Box sx={{ px: 2, py: 2 }}>
        <MessageThreadHeader
          thread={thread}
          deal={thread.deal}
          organization={thread.organization}
          imageSize={75}
          showQualifications
        />
      </Box>
      <Divider sx={{ mb: 2, borderWidth: '2px' }} />
      <Box
        sx={{
          height: '40vh',
          overflowY: 'auto',
        }}
      >
        {thread.messages.map(({ id, fromUser, body, createdAt }, idx) => {
          const name = fromUser.id === sessionUser.id ? 'You' : fromUser.name;
          const avatar =
            fromUser.id === sessionUser.id ? sessionUser.picture : null;
          const isInvestor = fromUser.role === Role.Investor;
          const msgCreateAtLocal = localTime(createdAt);

          return (
            <div key={id}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={1}
                key={idx}
                sx={{ pl: 4, pr: 2 }}
              >
                <Stack>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Avatar src={avatar}>
                      {fromUser.name.substring(0, 2).toUpperCase()}
                    </Avatar>
                    <Typography>
                      <b>{name}</b> ·{' '}
                      <span style={{ fontSize: 12 }}>
                        {format(msgCreateAtLocal, 'MMM d h:mm a')}
                      </span>
                    </Typography>
                  </Stack>
                  <Typography
                    variant="body2"
                    color={'text.secondary'}
                    component={'span'}
                    sx={{ ml: 6 }}
                  >
                    {body.split('\n').map((str, idx) => (
                      <p key={idx}>{str}</p>
                    ))}
                  </Typography>
                </Stack>
              </Stack>
            </div>
          );
        })}
        <div ref={scrollRef} />
      </Box>
      <Divider sx={{ mb: 2, borderWidth: '2px' }} />
      <Box
        sx={{
          flex: 'auto',
          px: 2,
        }}
      >
        <TextField
          variant={'outlined'}
          multiline
          rows={4}
          fullWidth
          placeholder={'Type your reply here'}
          name="message"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.message}
        />
      </Box>
      <Divider sx={{ my: 2 }} />

      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          size={'small'}
          variant="contained"
          color={'primary'}
          disabled={!formik.isValid || isReplySending}
          onClick={onSubmit}
        >
          Send
        </Button>
      </CardActions>
    </>
  );
};
