export const SortBy = {
  Newest: 'Newest',
  Oldest: 'Oldest',
  MinInvestmentDesc: 'MinInvestmentDesc',
  MinInvestmentAsc: 'MinInvestmentAsc',
  PurchasePriceDesc: 'PurchasePriceDesc',
  PurchasePriceAsc: 'PurchasePriceAsc',
};
export const SortByLabel = {
  Oldest: 'First Added',
  Newest: 'Newly Added',
  MinInvestmentDesc: 'Min. Investment (High -> Low)',
  MinInvestmentAsc: 'Min. Investment (Low -> High)',
  PurchasePriceDesc: 'Price (High -> Low)',
  PurchasePriceAsc: 'Price (Low -> High)',
};

export const SortByKey = 'dealSortBy';

const sortByCreatedAt = (deals, isDesc) => {
  return deals.sort((d1, d2) => {
    if (d1.createdAt < d2.createdAt) return isDesc ? 1 : -1;
    if (d1.createdAt > d2.createdAt) return isDesc ? -1 : 1;
    return 0;
  });
};

const sortByMinInvestment = (deals, isAsc) => {
  return deals.sort((d1, d2) => {
    if (d1.minInvestment > d2.minInvestment) return isAsc ? 1 : -1;
    if (d1.minInvestment < d2.minInvestment) return isAsc ? -1 : 1;
    return 0;
  });
};

const sortByPurchasePrice = (deals, isAsc) => {
  return deals.sort((d1, d2) => {
    if (d1.purchasePrice > d2.purchasePrice) return isAsc ? 1 : -1;
    if (d1.purchasePrice < d2.purchasePrice) return isAsc ? -1 : 1;
    return 0;
  });
};

export const sortDeals = (deals, sortBy) => {
  switch (sortBy) {
    case SortBy.Oldest:
      return sortByCreatedAt(deals);
    case SortBy.Newest:
      return sortByCreatedAt(deals, true);
    case SortBy.MinInvestmentDesc:
      return sortByMinInvestment(deals, false);
    case SortBy.MinInvestmentAsc:
      return sortByMinInvestment(deals, true);
    case SortBy.PurchasePriceDesc:
      return sortByPurchasePrice(deals, false);
    case SortBy.PurchasePriceAsc:
      return sortByPurchasePrice(deals, true);
    default:
      return deals;
  }
};
