import { useApi } from '../api/use-api';
import { useMutation } from 'react-query';
import { RetryConfig } from '../api/retry-config';

const useRegisterUser = () => {
  const request = useApi();
  return useMutation(
    async ({ createUserRequest }) =>
      request({
        url: '/registration/user',
        method: 'POST',
        body: createUserRequest,
      }),
    {
      ...RetryConfig(),
    },
  );
};
const useRegisterOrganizationAndUser = () => {
  const request = useApi();
  return useMutation(
    async ({ createOrgRequest }) =>
      request({
        url: '/registration/organization',
        method: 'POST',
        body: createOrgRequest,
      }),
    {
      ...RetryConfig(),
    },
  );
};

const useSendVerificationEmail = () => {
  const request = useApi();
  return useMutation(
    async () =>
      request({
        url: '/registration/verify',
        method: 'POST',
      }),
    {
      ...RetryConfig(),
    },
  );
};

export {
  useRegisterUser,
  useRegisterOrganizationAndUser,
  useSendVerificationEmail,
};
