export const PlacesLibraries = ['places'];

export const geocodeByAddress = async (address) => {
  const geocoderService = new window.google.maps.Geocoder();
  return geocoderService.geocode({ address });
};

export const getPlacePredictions = async (
  address,
  sessionToken,
  addressRequired = false,
) => {
  const autocompleteService =
    new window.google.maps.places.AutocompleteService();
  return autocompleteService.getPlacePredictions({
    input: address,
    sessionToken,
    componentRestrictions: { country: ['us', 'ca'] },
    fields: ['address_components', 'geometry'],
    types: addressRequired ? ['address'] : ['geocode'],
  });
};

export const getPlaceDetails = (placeId, map, sessionToken, callback) => {
  const placesService = new window.google.maps.places.PlacesService(map);
  return placesService.getDetails({ placeId, sessionToken }, callback);
};
export const getLatLng = (result) => {
  return new Promise((resolve, reject) => {
    try {
      const latLng = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      };
      resolve(latLng);
    } catch (e) {
      reject(e);
    }
  });
};

export const geocodeByPlaceId = (placeId) => {
  const geocoder = new window.google.maps.Geocoder();
  const OK = window.google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};

export const addressFromPlace = (place) => {
  let address1 = '';
  let city = '';
  let state = '';
  let postcode = '';

  // Get each component of the address from the place details,
  // and then fill-in the corresponding field on the form.
  // place.address_components are google.maps.GeocoderAddressComponent objects
  // which are documented at http://goo.gle/3l5i5Mr

  for (const component of place.address_components) {
    const componentType = component.types[0];

    switch (componentType) {
      case 'street_number': {
        address1 = `${component.long_name} ${address1}`;
        break;
      }
      case 'route': {
        address1 += component.long_name;
        break;
      }
      case 'postal_code': {
        postcode = `${component.long_name}${postcode}`;
        break;
      }
      case 'postal_code_suffix': {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }
      case 'locality': {
        city = component.long_name;
        break;
      }
      case 'sublocality_level_1': {
        city = component.long_name;
        break;
      }
      case 'administrative_area_level_3': {
        city = component.long_name;
        break;
      }
      case 'administrative_area_level_1': {
        state = component.short_name;
        break;
      }
    }
  }

  return {
    address1,
    city,
    state,
    postcode,
    latlng: JSON.stringify(place.geometry.location),
  };
};
