import { Button, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { grey } from '@mui/material/colors';

export const EditAssetTopNav = ({
  formik,
  isChanged,
  isSaving,
  onSubmit,
  onBack,
  isLoading,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      sx={{
        py: 2,
        pr: 2,
        position: 'sticky',
        top: 0,
        zIndex: (theme) => theme.zIndex.appBar,
        backgroundColor: alpha(grey[100], 0.8),
      }}
    >
      <Stack spacing={1}>
        {!isSaving && (
          <Button
            color={isChanged ? 'error' : 'primary'}
            size={'small'}
            sx={{ mr: 2 }}
            onClick={onBack}
            startIcon={<ArrowBack />}
          >
            {isChanged ? 'Cancel & Discard Changes' : 'Back to Fund'}
          </Button>
        )}
      </Stack>
      <Button
        size={'small'}
        onClick={onSubmit}
        variant={'contained'}
        disabled={!formik.isValid || isSaving || !isChanged}
      >
        {isSaving ? 'Saving...' : isChanged ? 'Save' : 'Saved'}
      </Button>
    </Stack>
  );
};
