import { useMutation, useQuery, useQueryClient } from 'react-query';
import { RetryConfig } from '../api/retry-config';
import { useApi } from '../api/use-api';
import { sortThreadsByLatestMessage } from '../../../helpers/messages-sort';
import { handleMutate } from '../util/use-handle-mutate';
import {
  DealQueryKeys,
  setDealInDealQueryData,
  setDealQueryDataToPrevious,
  updateDealInList,
} from '../deals/deals-hook-utils';
import { v4 as uuid } from 'uuid';

const queryKey = 'threads';

const useMessageThreads = ({ options }) => {
  const request = useApi();
  return useQuery(
    [queryKey],
    async () => {
      const threads = await request({ url: '/messages' });
      return threads.sort(sortThreadsByLatestMessage);
    },
    {
      ...RetryConfig(),
      ...options,
      cacheTime: 5 * 60 * 1000, //5 minutes
    },
  );
};

const useCreateMessageThread = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ dealId, message }) =>
      request({
        url: '/messages',
        method: 'POST',
        body: { dealId, body: message },
      }),
    {
      onMutate: async ({ dealId, message }) => {
        const temp = {
          createdAt: new Date().toISOString(),
          messages: [{ body: message }],
        };

        const prevSearch = await handleMutate(
          queryClient,
          DealQueryKeys.searches(),
          (old) =>
            updateDealInList(old, dealId, (deal) => {
              deal.threads.push(temp);
              deal.investments = [{ status: 'Evaluating' }];
              return deal;
            }),
        );
        const prevWatchlist = await handleMutate(
          queryClient,
          DealQueryKeys.watchlist(),
          (old) =>
            updateDealInList(old, dealId, (deal) => {
              deal.threads.push(temp);
              deal.investments = [{ status: 'Evaluating' }];
              return deal;
            }),
        );
        const prevDetail = await handleMutate(
          queryClient,
          DealQueryKeys.detail(dealId),
          (old) => ({
            ...old,
            threads: [...old.threads, temp],
            investments: [{ status: 'Evaluating' }],
          }),
        );

        return {
          prevSearch,
          prevWatchlist,
          prevDetail,
        };
      },
      onError: (err, variables, previousValue) => {
        setDealQueryDataToPrevious(queryClient, previousValue);
      },
      onSuccess: (data, variables, context) => {
        setDealInDealQueryData(
          queryClient,
          [DealQueryKeys.searches(), DealQueryKeys.watchlist()],
          data,
        );
        queryClient.invalidateQueries(DealQueryKeys.watchlist());
      },
      ...RetryConfig(),
    },
  );
};

const useReplyToMessageThread = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ threadId, message, fromUser }) =>
      request({
        url: `/messages/${threadId}/reply`,
        method: 'POST',
        body: { body: message },
      }),
    {
      onMutate: async ({ threadId, message, fromUser }) => {
        return handleMutate(queryClient, [queryKey], (old) => {
          if (!old) return;
          const copy = [...old];
          const threadIdx = old.findIndex((t) => t.id === threadId);
          if (threadId > -1) {
            copy[threadIdx].messages.push({
              id: uuid(),
              threadId: threadId,
              fromUser,
              body: message,
              createdAt: new Date().toISOString(),
              reads: [
                {
                  readAt: new Date().toISOString(),
                },
              ],
            });
          }
          return copy.sort(sortThreadsByLatestMessage);
        });
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData([queryKey], previousValue);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([queryKey]);
      },
      ...RetryConfig(),
    },
  );
};

const useMarkMessageAsRead = () => {
  const request = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ threadId, messageId }) =>
      request({
        url: `/messages/${threadId}/read`,
        method: 'POST',
        body: { messageId },
      }),
    {
      onMutate: async ({ threadId, messageId }) => {
        return handleMutate(queryClient, [queryKey], (old) => {
          if (!old) return;
          const copy = [...old];
          const threadIdx = old.findIndex((t) => t.id === threadId);
          if (threadId > -1) {
            const msg = copy[threadIdx].messages.find(
              (m) => m.id === messageId,
            );
            msg.reads.push({
              readAt: new Date().toISOString(),
            });
          }
          return copy;
        });
      },
      onError: (err, variables, previousValue) => {
        queryClient.setQueryData([queryKey], previousValue);
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([queryKey]);
      },
      ...RetryConfig(),
    },
  );
};

export {
  useMessageThreads,
  useCreateMessageThread,
  useReplyToMessageThread,
  useMarkMessageAsRead,
};
