import React from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

export const DealFundingBar = (props) => {
  const { deal } = props;

  const fundingClosed =
    deal?.funding?.reduce((prev, curr) => prev + curr.amount, 0) || 0;
  const totalRaise = deal?.totalRaise || 0;
  const fundingAvailable = deal?.totalRaise
    ? deal?.totalRaise - fundingClosed
    : 0;
  const pctFunded =
    fundingClosed > 0
      ? Math.round((fundingClosed * 100) / deal?.totalRaise)
      : 0;

  return (
    <div>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'caption'}>
          Min. Investment: ${deal?.minInvestment?.toLocaleString()}
        </Typography>
        <Typography variant="caption">{`${pctFunded}% Funded`}</Typography>
      </Stack>
      <Tooltip
        title={`$${
          fundingAvailable >= 0 ? fundingAvailable.toLocaleString() : '0'
        } Remaining`}
      >
        <div style={{ position: 'relative', marginTop: 3, marginBottom: 3 }}>
          <LinearProgress
            variant="determinate"
            value={pctFunded}
            color={'success'}
            sx={{
              borderRadius: 1,
              py: 0.5,
              borderColor: '#93C572',
            }}
          />
        </div>
      </Tooltip>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ display: 'flex' }}
        >
          {`$${fundingClosed.toLocaleString()}`}
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
        >{`out of $${totalRaise.toLocaleString()}`}</Typography>
      </Stack>
    </div>
  );
};
