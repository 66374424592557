import {
  Box,
  Divider,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import React from 'react';
import Stack from '@mui/material/Stack';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { StorageBucket } from '../../../helpers/supabase';
import { DealImages } from './deal-images';
import { DealBanner } from '../deal-banner';
import { DealFundingBar } from '../deal-funding-bar';
import { DealType } from '../../enums/deal-type.enum';

export const DealHeader = ({ values, deal, isSponsorPage }) => {
  const isSale = deal?.type === DealType.Sale;
  const hasImg = deal?.organization?.logoImage;
  const logoImgSrc =
    hasImg &&
    `${process.env.REACT_APP_IMAGES_BASE_URL}/${StorageBucket.ORGANIZATION_IMAGES}/${deal?.organization?.id}/${deal?.organization?.logoImage}?fit=max&h=40`;

  return (
    <>
      <Grid xs={12}>
        <DealBanner deal={deal} />
      </Grid>
      <Grid xs={6} sx={{ px: 3 }}>
        <DealImages
          deal={deal}
          height={isSponsorPage ? 200 : null}
          isSponsorPage={isSponsorPage}
        />
      </Grid>
      <Grid xs={6}>
        <Stack alignItems="center" spacing={1}>
          <Typography
            align={'center'}
            sx={{
              typography: {
                xs: 'h4',
                md: 'h3',
              },
            }}
          >
            {values.name}
          </Typography>
          {deal.city && deal.state && (
            <Typography variant="h5">
              {deal.city}, {deal.state}
            </Typography>
          )}

          {!isSponsorPage && (
            <Stack direction={'row'} alignItems={'center'} columnGap={0.5}>
              <Typography variant="body1" color="text.secondary">
                Offered By:
              </Typography>
              <Link sx={{ fontSize: 14 }} to={'/organizations/' + deal.orgId}>
                {deal.organization?.name}
              </Link>
            </Stack>
          )}

          {deal.offeredAt && (
            <Stack direction={'row'} alignItems={'center'} columnGap={0.5}>
              <Typography variant="body1" color="text.secondary">
                Initial Offer Date:
              </Typography>
              {format(values.offeredAt, 'MM/dd/yyyy')}
            </Stack>
          )}

          {logoImgSrc && (
            <p style={{ marginTop: 30 }}>
              <img src={logoImgSrc} alt={deal?.organization?.name} />
            </p>
          )}
          {isSale && (
            <Box sx={{ mt: 3 }}>
              <Typography variant={'h4'} color={'text.secondary'}>
                Price: ${deal?.purchasePrice?.toLocaleString()}
              </Typography>
            </Box>
          )}
        </Stack>
        {!isSale && (
          <Box sx={{ mt: 3 }}>
            <DealFundingBar deal={values} />
          </Box>
        )}
      </Grid>
      {!isSponsorPage && (
        <Grid xs={12}>
          <Divider />
        </Grid>
      )}
      {values.videoUrl && (
        <Grid xs={12}>
          <iframe
            title="video"
            src={values.videoUrl}
            style={{ width: '100%', height: '500px', border: 'none' }}
          ></iframe>
        </Grid>
      )}
    </>
  );
};
