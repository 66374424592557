import React from 'react';
import {
  capitalize,
  Chip,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSessionUser } from '../../components/hooks/session/useSessionUser';
import { useSnackbar } from '../../components/hooks/util/use-snackbar';
import Button from '@mui/material/Button';
import {
  useCustomerPortal,
  useSubscriptions,
} from '../../components/hooks/subscriptions/use-subscriptions';
import { utcToLocalTime } from '../../helpers/dates';
import { format } from 'date-fns';
import { SubscriptionPlans } from '../../components/billing/subscription-plans';
import { useNavigate } from 'react-router-dom';

function Billing() {
  const navigate = useNavigate();
  const { sessionUser } = useSessionUser();
  const { render: renderSnackbar, setSnackbar } = useSnackbar();

  const { data: customerPortal } = useCustomerPortal({});
  const { data: subscriptions, isLoading } = useSubscriptions({
    options: {
      enabled: !!sessionUser?.id,
    },
  });

  const handleManageClick = () => {
    window.open(customerPortal.returnUrl, '_self');
  };

  const handleGoToDeal = (id) => {
    navigate(`/deals/${id}`);
  };

  console.log('subscriptions: ', subscriptions);

  const activeSubs = subscriptions
    ?.filter((s) => !s.isCancelled)
    .sort((a, b) => (a.nextBillingDate > b.nextBillingDate ? 1 : -1));
  const cancelledSubs = subscriptions?.filter((s) => s.isCancelled);

  return (
    <Container maxWidth="xl">
      {renderSnackbar()}
      <Stack spacing={3}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant="h4">Billing</Typography>
          {subscriptions?.length > 0 && (
            <Tooltip
              title={
                'Change payment method, view invoices, or cancel a subscription'
              }
            >
              <Button variant={'outlined'} onClick={handleManageClick}>
                Manage Billing
              </Button>
            </Tooltip>
          )}
        </Stack>
        {isLoading ? (
          <Stack alignItems="center" sx={{ mt: 10 }} rowGap={2}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {!activeSubs?.length ? (
              <>
                <Typography variant="h6">No active subscriptions</Typography>
                <Typography>
                  See the following subscription options below. Publish a deal
                  to subscribe.
                </Typography>
                <SubscriptionPlans />
              </>
            ) : (
              <>
                <Typography variant="h6">Active Subscriptions</Typography>
                <Table padding="normal" size={'small'}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Deal</TableCell>
                      <TableCell align={'left'} sx={{ py: 2 }}>
                        Status
                      </TableCell>
                      <TableCell align={'right'} sx={{ py: 2 }}>
                        Subscription
                      </TableCell>
                      <TableCell align={'right'} sx={{ py: 2 }}>
                        Last Renewal
                      </TableCell>
                      <TableCell align={'right'} sx={{ py: 2 }}>
                        Valid Until
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activeSubs?.map((subscription) => {
                      const isPendingCancel = subscription.pendingCancellation;

                      return (
                        <TableRow
                          key={
                            subscription.dealName + subscription.nextBillingDate
                          }
                        >
                          <TableCell>
                            <Typography
                              sx={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleGoToDeal(subscription.dealId)
                              }
                            >
                              {subscription.dealName}
                            </Typography>
                          </TableCell>
                          <TableCell align={'left'}>
                            <Chip
                              label={capitalize(
                                isPendingCancel
                                  ? 'Pending Cancel'
                                  : subscription.status,
                              )}
                              color={
                                isPendingCancel
                                  ? 'error'
                                  : subscription.status === 'active'
                                    ? 'success'
                                    : undefined
                              }
                            />
                          </TableCell>
                          <TableCell align={'right'}>
                            <pre>
                              $
                              {(
                                subscription.pricePerCycle / 100
                              ).toLocaleString()}{' '}
                              every{' '}
                              {subscription.numberOfMonths === 1
                                ? 'month'
                                : subscription.numberOfMonths + ' months'}
                            </pre>
                          </TableCell>
                          <TableCell align={'right'}>
                            <pre>
                              {format(
                                utcToLocalTime(subscription.prevBillingDate),
                                'MMM d yyyy',
                              )}
                            </pre>
                          </TableCell>
                          <TableCell align={'right'}>
                            <pre>
                              {format(
                                utcToLocalTime(subscription.nextBillingDate),
                                'MMM d yyyy',
                              )}
                            </pre>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </>
            )}
          </>
        )}
        {cancelledSubs?.length > 0 && (
          <>
            <Typography variant="h6">Cancelled Subscriptions</Typography>
            <Table padding="normal" size={'small'}>
              <TableHead>
                <TableRow>
                  <TableCell>Deal</TableCell>
                  <TableCell align={'left'} sx={{ py: 2 }}>
                    Status
                  </TableCell>
                  <TableCell align={'right'} sx={{ py: 2 }}>
                    Subscription
                  </TableCell>
                  <TableCell align={'right'} sx={{ py: 2 }}>
                    Last Renewed
                  </TableCell>
                  <TableCell align={'right'} sx={{ py: 2 }}>
                    Cancelled On
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cancelledSubs?.map((subscription) => {
                  return (
                    <TableRow
                      key={subscription.dealName + subscription.nextBillingDate}
                    >
                      <TableCell>
                        <pre>{subscription.dealName}</pre>
                      </TableCell>
                      <TableCell align={'left'}>
                        <Chip label={'Cancelled'} color={'error'} />
                      </TableCell>
                      <TableCell align={'right'}>
                        <pre>
                          ${(subscription.pricePerCycle / 100).toLocaleString()}{' '}
                          every{' '}
                          {subscription.numberOfMonths === 1
                            ? 'month'
                            : subscription.numberOfMonths + ' months'}
                        </pre>
                      </TableCell>
                      <TableCell align={'right'}>
                        <pre>
                          {format(
                            utcToLocalTime(subscription.prevBillingDate),
                            'MMM d yyyy',
                          )}
                        </pre>
                      </TableCell>
                      <TableCell align={'right'}>
                        <pre>
                          {format(
                            utcToLocalTime(subscription.nextBillingDate),
                            'MMM d yyyy',
                          )}
                        </pre>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        )}
      </Stack>
    </Container>
  );
}

export default Billing;
