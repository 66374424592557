import { Box, Chip, FormControl, InputLabel, Select } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';

export const SearchFilterMultiSelect = ({ ...props }) => {
  const { label, value, onChange, onRemove, labelMap, children } = props;

  return (
    <FormControl fullWidth>
      <InputLabel id={`${label}-label`} shrink={true}>
        {label}
      </InputLabel>
      <Select
        labelId={`${label}-label`}
        value={value}
        label={label}
        onChange={onChange}
        multiple
        displayEmpty
        renderValue={(selected) => (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {value.length > 0 && value.length < 3 ? (
              value.map((selected) => {
                return (
                  <Chip
                    sx={{ mr: 1, mb: 1 }}
                    key={selected}
                    label={labelMap[selected]}
                    variant="outlined"
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={(e) => onRemove(selected)}
                  />
                );
              })
            ) : (
              <Chip
                sx={{ mr: 1 }}
                key={'All'}
                label={`${
                  value.length > 0 ? value.length : 'All'
                } ${label}s Included`}
                variant="outlined"
              />
            )}
          </Box>
        )}
      >
        {children}
      </Select>
    </FormControl>
  );
};
